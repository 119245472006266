import React from 'react'
import { Formik, Form } from 'formik'
import { Grid, Box, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import SelectWrapper from '../../../FormUI/SelectWrapper/SelectWrapper'
import TextfieldWrapper from '../../../FormUI/Textfield/TextfieldWrapper'
import SubmitWrapper from '../../../FormUI/SubmitWrapper/SubmitWrapper'
import ButtonWrapper from '../../../FormUI/ButtonWrapper/ButtonWrapper'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import { types } from '../../../../enums/Miscellaneous'

export default function MiscellaneousForm({
    isNew,
    initialFormState,
    FORM_VALIDATION,
    handleSubmit,
    merchant_id,
    hasPermissions
}) {
    const { t } = useTranslation()

    const navigate = useNavigate()

    return (
        <Formik
            initialValues={{
                ...initialFormState
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={handleSubmit}
        >
            <Form>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={3}>
                        <SelectWrapper
                            name="type"
                            label={t('Type')}
                            options={types}
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={12} md={3}>
                        <TextfieldWrapper
                            name="amount"
                            label={t('Amount')}
                            type="text"
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <TextfieldWrapper
                            name="currency"
                            label={t('Currency')}
                            type="text"
                            disabled
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextfieldWrapper
                            name="comments"
                            label={t('Comments')}
                            type="text"
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2}>
                            {hasPermissions ? (<SubmitWrapper>{t('Save')}</SubmitWrapper>) : ''}
                            <ButtonWrapper
                                variant="text"
                                onClick={() => navigate(`/merchants/${merchant_id}/miscellaneous`)}
                                startIcon={<CloseIcon />}>{t('Cancel')}</ButtonWrapper>
                        </Stack>
                    </Grid>
                </Grid>
            </Form>
        </Formik>
    )
}
