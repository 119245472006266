import api from '../api/index'
import authHeader from './authHeader'


const login = (credentials) => {
    return api.post('/token/', credentials)
}

const logout = () => {
    const user = JSON.parse(localStorage.getItem('user'))

    if (user && user.token) {
        localStorage.removeItem('user')
    }
}

const resetPassword = (data) => {
    return api.post('/auth/password/', data, { headers: authHeader() })
}

// const totpStatus = () => {
//     return api.get('/merchant/users/me/', { headers: authHeader() })
// }

const enableTotp = () => {
    return api.post('/auth/totp/', {}, { headers: authHeader() })
}

const confirmTotp = (totp_code) => {
    return api.post('auth/totp-confirm/', { totp: totp_code }, { headers: authHeader() });
}

const removeTotp = (totp_code) => {
    return api.post('/auth/totp-remove/', { totp: totp_code }, { headers: authHeader() });
}

const profile = () => { }

const storeUser = (data) => {
    if (data.token) {
        localStorage.setItem('user', JSON.stringify(data))
    }
}

const getStoredUser = () => {
    const user = JSON.parse(localStorage.getItem('user'))

    if (user && user.token) {
        return user
    }

    return false
}

const authService = {
    login,
    logout,
    profile,
    storeUser,
    getStoredUser,
    resetPassword,
    enableTotp,
    confirmTotp,
    removeTotp
}

export default authService