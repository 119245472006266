import { useState } from 'react'
import { Alert, Button, Card, CardContent, Fab, IconButton, Stack, Typography } from '@mui/material'
import useAxiosCashouts from '../../../../hooks/useAxiosCashouts'
import FileUploadTable from './FileUploadTable'
import PageTitle from '../../../Misc/PageTitle'
import ToolsStack from '../../../Misc/ToolsStack'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import { LoadingButton } from '@mui/lab'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import useIsMounted from '../../../../hooks/useIsMounted'
import FactCheckIcon from '@mui/icons-material/FactCheck'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

const Input = styled('input')({
    display: 'none',
});

export default function FileUpload() {
    const { t } = useTranslation()

    const isMounted = useIsMounted()

    const navigate = useNavigate()

    const { groupId } = useParams()

    const axiosCashouts = useAxiosCashouts()

    const [loading, setLoading] = useState(false)

    const [file, setFile] = useState()

    const [data, setData] = useState([])

    const [isSent, setIsSent] = useState(false)

    const handleChange = (event) => {
        setFile(event.target.files[0])
    }

    const handleResetFile = () => {
        setFile(null)
        setData([])
        setIsSent(false)
    }

    const handleUploadFile = () => {
        setLoading(true)
        const formData = new FormData()

        formData.append('group_id', groupId);
        formData.append(
            "BEFile",
            file,
            file.name
        )

        axiosCashouts.post('https://adminold.paygol.com/cashouts.php', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(({ data: { data } }) => {
                if (isMounted()) {
                    setData(data)
                }
            })
            .catch(e => console.log(e))
            .finally(() => {
                setIsSent(true)
                setLoading(false)
            })
    }

    const handleProcessCashouts = () => {
        const cashouts = data.map(d => ({
            id: d.id,
            status: d.response?.status,
            error: d.response?.error
        }))

        /**
         * group_id: groupId
         * cashouts: cashouts
         */
        axiosCashouts.post('/cashouts/upload', {
            cashouts,
            group_id: groupId
        })
            .then(() => {
                toast.success(t('All Cashouts has been processed'))
                navigate('/cashouts/active')
            })
            .catch(() => toast.error(t('Something went wrong')))
    }

    return (
        <Card>
            <CardContent>
                {(!!file && data.length > 0) && (<Fab
                    variant="extended"
                    color="success"
                    onClick={handleProcessCashouts}
                    sx={{
                        position: 'fixed',
                        bottom: 26,
                        right: 26
                    }}>
                    <FactCheckIcon sx={{ mr: 1 }} />
                    Procesar
                </Fab>)}

                <PageTitle title={t('Upload Banco Estado response')} />

                <ToolsStack justifyContent="space-between">
                    <Stack direction="row" spacing={3}>
                        {!!file && (<LoadingButton
                            variant="contained"
                            loading={loading}
                            onClick={handleUploadFile}
                            loadingPosition="start"
                            startIcon={<UploadFileIcon />}
                            size="small"
                            disabled={loading}
                        >
                            {t('Process')}
                        </LoadingButton>)}

                        <label htmlFor="contained-button-file">
                            <Input
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                onChange={handleChange}
                                type="file"
                                id="contained-button-file"
                            />
                            {!file && (<Button variant="contained" component="span">
                                Seleccionar archivo
                            </Button>)}
                        </label>
                    </Stack>

                    <Button
                        variant="text"
                        onClick={() => navigate('/cashouts/active')}
                        startIcon={<ArrowBackIcon />}>{t('Go back')}</Button>
                </ToolsStack>

                {!!file && (
                    <div>
                        <Typography sx={{
                            color: 'blue',
                            my: '1rem'
                        }}>
                            {file.name} ({Math.round(file.size / 1000)}kb)
                            <IconButton
                                variant="contained"
                                color="error"
                                onClick={handleResetFile}
                            >
                                <HighlightOffIcon />
                            </IconButton>
                        </Typography>
                    </div>
                )}

                {(!!file && !data.length && isSent) && (<Alert icon={false} severity="info" variant="outlined">
                    No se encontraron transacciones en el archivo subido
                </Alert>)}

                {(!!file && data.length > 0) && (<FileUploadTable data={data} loading={loading} />)}
            </CardContent>
        </Card>
    )
}
