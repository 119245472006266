const ST_DISABLED = 0
const ST_REQUESTED = 1
const ST_PRODUCTION = 2
const ST_LOCKED = 3
const ST_UNREQUESTED = 4

const status = {
    0: 'Disabled',
    1: 'Requested',
    2: 'Production',
    3: 'Locked',
    4: 'Default (Unrequested)',
}

export {
    status,
    ST_DISABLED,
    ST_REQUESTED,
    ST_PRODUCTION,
    ST_LOCKED,
    ST_UNREQUESTED
}