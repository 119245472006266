import { useState, useEffect, useCallback } from 'react'
import { Grid } from '@mui/material'

import DescriptionIcon from '@mui/icons-material/Description'
import { Card, CardContent } from '@mui/material'
import { useTranslation } from 'react-i18next'
import AppFilters from '../../AppFilters'
import Filters from './Filters'
import TransactionsTable from './TransactionsTable'
import PageTitle from '../../Misc/PageTitle'
import { toast } from 'react-toastify'
import LoadingButton from '@mui/lab/LoadingButton'
import useIsMounted from '../../../hooks/useIsMounted'
import { getCache, removeNullValues, storeCache } from '../../../services/utils'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'

import { objectFilter } from 'utils/jsUtils'



export default function TransactionsList() {
    const { t } = useTranslation()

    const api = useAxiosPrivate()

    const isMounted = useIsMounted()

    const [params, setParams] = useState(getCache('filters.transactions', {
        status: 2
    }))

    const [data, setData] = useState([])

    const [loading, setLoading] = useState(true)

    const [fetchingData, setFetchingData] = useState(false)

    const [meta, setMeta] = useState({
        total_pages: 1,
        current_page: 1
    })

    const [searched, setSearched] = useState(false)

    const handleUpdateIntermediary = (event, id) => {
        const { value } = event.target

        api.put(`/internal/transactions/${id}/intermediary/`, { intermediary: value })
            .then(() => {
                if (isMounted()) {
                    setData(data.map(d => {
                        if (d.id === id) {
                            d.transaction_extras = [{
                                code: event.target.value
                            }]
                        }

                        return d
                    }))

                    toast.success(t('Intermediary Bank has been updated'))
                }
            })
            .catch(() => isMounted() && toast.error(t('Something went wrong')))
    }

    const handleVoucherDownload = (row) => {
        if (fetchingData) {
            return
        }

        setFetchingData(true)

        return api.get(`/internal/transactions/${row.id}/pdf-receipt/`, { responseType: 'blob' })
            .then((response) => {
                if (isMounted()) {
                    let anchor = document.createElement('a')

                    anchor.href = window.URL.createObjectURL(response.data)

                    anchor.download = `${row.transaction_id}.pdf`

                    anchor.click()

                    anchor.remove()
                }
            })
            .catch(() => isMounted() && toast.error(t('Something went wrong')))
            .finally(() => isMounted() && setFetchingData(false))
    }

    const handleOnSearch = values => {
        const replacements = {
            transaction_id__in: { replacement: 'transaction_id__icontains', condition: value => !value.includes(',') },
            method_or_provider__icontains: { replacement: 'method_or_provider__in', condition: value => value.includes(',') },
        }
        function replaceKeys(obj, replacements) {
            return Object.fromEntries(
                Object.entries(obj).map(([key, value]) => {
                    const replacement = replacements[key]
                    if (replacement && replacement.condition(value)) {
                        return [replacement.replacement, value]
                    }
                    return [key, value]
                })
            )
        }
        values = {
            ...values,
            payment_method__api_code: String(values.payment_method__api_code).replace('all', ''),
            status: String(values.status).replace('0', ''),
            payment_method__method_provider: String(values.payment_method__method_provider).replace('all', ''),
            country: String(values.country).replace('all', ''),
        }

        setLoading(true)

        storeCache('filters.transactions', values, 1)

        setParams(replaceKeys(values, replacements))

        setSearched(true)
    }

    const handleOnPageChange = (e, page) => {
        setLoading(true)

        setMeta({ ...meta, current_page: page })
        setParams({ ...params, page: page })
    }

    const retrieveData = useCallback(() => {
        setData([])

        api.get('/internal/transactions/', { params: removeNullValues(params) })
            .then(({ data: { data, meta } }) => {
                if (isMounted()) {
                    setData(data.map(d => {
                        d.transaction_extras = [
                            {
                                code: ''
                            }
                        ]

                        return d
                    }))
                    setMeta(meta)
                }
            })
            .catch(() => isMounted() && toast.error(t('Something went wrong')))
            .finally(() => isMounted() && setLoading(false))
    }, [params, t, isMounted, api])

    const handleExportCsv = () => {
        if (!searched) {
            // todo: forbid also after the person pressed the reset filters button
            toast.error(t('Please set your filters and click on search first'))
            return
        }


        const paramsKeys = new Set(Object.keys(objectFilter(({ value }) => value !== '', params)))
        if (paramsKeys.size === 1 && paramsKeys.has('status')) {
            toast.error(t('Export is not allowed when using only the status filter'))
            return
        }

        setFetchingData(true)

        return api.get(`/internal/transactions/export/`, { params: removeNullValues(params), responseType: "blob" })
            .then(response => {
                if (isMounted()) {
                    toast.success(`${t("Transactions exported")}: ${meta.total}\n${t('The resport will be sent to your email on about')} ${Math.ceil(meta.total / 600)} ${t('minutes')}`)
                }
            })
            .catch(() => isMounted() && toast.error(t('Something went wrong')))
            .finally(() => isMounted() && setFetchingData(false))
    }

    useEffect(() => {
        const loadData = () => {
            retrieveData()
        }

        loadData()
    }, [params, retrieveData])

    return (
        <>
            <Card>
                <CardContent>
                    <PageTitle title={t('Transactions')} />

                    <Grid container spacing={2} mt={2} mb={2}>
                        <Grid item xs={12}>
                            <LoadingButton
                                loading={fetchingData}
                                onClick={() => handleExportCsv()}
                                loadingPosition="start"
                                startIcon={<DescriptionIcon />}
                                size="small"
                                className="base__btn-action"
                            >
                                {t('Export')}
                            </LoadingButton>
                        </Grid>
                    </Grid>

                    <AppFilters Filters={<Filters handleOnSearch={handleOnSearch} />} open={true} />

                    <TransactionsTable
                        data={data}
                        pages={meta.total_pages}
                        page={meta.current_page}
                        loading={loading}
                        handleOnPageChange={handleOnPageChange}
                        handleUpdateIntermediary={handleUpdateIntermediary}
                        handleVoucherDownload={handleVoucherDownload}
                    />
                </CardContent>
            </Card>
        </>
    )
}
