import { useEffect, useState } from "react"
import { Box, Grid, Stack } from "@mui/material"
import { Form, Formik } from "formik"
import { useTranslation } from 'react-i18next'

import ButtonWrapper from "../../../FormUI/ButtonWrapper/ButtonWrapper"
import SubmitWrapper from "../../../FormUI/SubmitWrapper/SubmitWrapper"
import SelectApiWrapper from "../../../FormUI/SelectWrapper/SelectApiWrapper"

import TextfieldWrapper from "../../../FormUI/Textfield/TextfieldWrapper"
import SearchIcon from '@mui/icons-material/Search'
import UndoIcon from '@mui/icons-material/Undo'
import useIsMounted from '../../../../hooks/useIsMounted';
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate"

export default function Filters({
    handleOnSearch
}) {
    const { t } = useTranslation()

    const isMounted = useIsMounted()

    const api = useAxiosPrivate()

    const [methods, setMethods] = useState([])

    const initialFormState = {
        payment_method: '',
        provider: ''
    }

    useEffect(() => {
        const getMethods = () => {
            api.get('/internal/payment-methods/grouped/')
                .then(({ data: { data } }) => {
                    if (isMounted()) {
                        const payment_methods = data.map(m => {
                            return {
                                id: m.api_code,
                                name: m.payment_methods[0].name
                            }
                        })

                        setMethods(payment_methods)
                    }
                })
        }

        getMethods()
    }, [api, isMounted])

    return (
        <Formik
            initialValues={{
                ...initialFormState
            }}
            onSubmit={handleOnSearch}
        >
            {({ handleReset, handleSubmit }) => (<Form>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <TextfieldWrapper
                            name="merchant"
                            label={t('Merchant')}
                            type="text"
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <SelectApiWrapper
                            name="payment_method"
                            label={t('Payment method')}
                            options={methods}
                            size="small"
                            translate={false}
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            <SubmitWrapper startIcon={<SearchIcon />}>{t('Search')}</SubmitWrapper>
                            <ButtonWrapper
                                onClick={() => {
                                    handleReset()
                                    handleSubmit()
                                }}
                                variant="text"
                                startIcon={<UndoIcon />}>{t('Reset')}</ButtonWrapper>
                        </Stack>
                    </Grid>
                </Grid>
            </Form>)}
        </Formik>
    )
}
