import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useTranslation } from 'react-i18next'
import {
    Chip,
    Typography
} from '@mui/material'
import CurrencyValue from '../../../TableUI/CurrencyValue'
import TableActions from '../../../TableUI/TableActions'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { STATUS_IN_PROCESS } from '../../../../enums/Cashouts'


const styles = () => ({
    root: {
        width: '100%',
        overflowX: "auto",
    }, table: {
        minWidth: 650,
    }
})

export default function ActiveCashoutsTable({
    data,
    working,
    handleConfirm,
    handleCancel,
    handleFailedForm,
    hasPermissions
}) {
    const { t } = useTranslation()

    return (
        <>
            <Typography
                variant='h5'
                style={{
                    textAlign: 'center',
                    marginTop: '1rem',
                    marginBottom: '1rem'
                }}>{t('Group ID')}: {data.group_id}</Typography>

            <TableContainer className={styles.root}>
                <Table className={styles.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('Nº')}</TableCell>
                            <TableCell>{t('Cashout ID')}</TableCell>
                            <TableCell>{t('Bank')}</TableCell>
                            <TableCell>{t('Account')}</TableCell>
                            <TableCell>{t('Beneficiary')}</TableCell>
                            <TableCell align="right">{t('Amount')}</TableCell>
                            <TableCell align="center">{t('Status')}</TableCell>
                            {hasPermissions && (<TableCell>&nbsp;</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.data ? data.data.map((row, idx) => (
                            <TableRow
                                key={idx}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <Typography noWrap>{idx + 1}</Typography>
                                </TableCell>
                                <TableCell>{row.str_payout_id}</TableCell>
                                <TableCell>{row.bank?.name}</TableCell>
                                <TableCell>{row.account_number}</TableCell>
                                <TableCell>{row.full_name}</TableCell>
                                <TableCell align="right">
                                    <CurrencyValue amount={row.amount} currency={row.currency} />
                                </TableCell>
                                <TableCell align="center">
                                    {row.status === 'created' && (<Chip label={row.status.toUpperCase()} color="primary" variant="outlined" />)}
                                    {row.status === 'paid' && (<Chip label={row.status.toUpperCase()} color="success" variant="outlined" />)}
                                    {row.status === 'in-process' && (<Chip label={row.status.toUpperCase()} color="info" variant="outlined" />)}
                                    {row.status === 'failed' && (<Chip label={row.status.toUpperCase()} color="error" variant="outlined" />)}
                                </TableCell>
                                {hasPermissions && (<TableCell>
                                    <TableActions actions={[
                                        {
                                            id: `confirm-${row.id}`,
                                            handleClick: () => (handleConfirm({
                                                transaction_id: row.id
                                            })),
                                            icon: <CheckCircleOutlineIcon />,
                                            title: t('Confirm Cashout')
                                        },
                                        {
                                            id: `cancel-${row.id}`,
                                            handleClick: () => (handleCancel({
                                                transaction_id: row.id
                                            })),
                                            icon: <RestartAltIcon />,
                                            title: t('Cancel Cashout')
                                        },
                                        {
                                            id: `failed-${row.id}`,
                                            handleClick: () => (handleFailedForm(row)),
                                            icon: <HighlightOffIcon />,
                                            title: t('Failed Cashout')
                                        }
                                    ]}
                                        disabled={row.status !== STATUS_IN_PROCESS} />
                                </TableCell>)}
                            </TableRow>
                        )) : <TableRow>
                            {
                                <TableCell sx={{ textAlign: 'center' }} colSpan={100}>{t('No records found')}</TableCell>
                            }
                        </TableRow>}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}
