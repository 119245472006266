import { useEffect, useRef, useState } from "react"
import { Box, Grid, Stack } from "@mui/material"
import { Form, Formik } from "formik"
import { useTranslation } from 'react-i18next'

import ButtonWrapper from "../../FormUI/ButtonWrapper/ButtonWrapper"
import SubmitWrapper from "../../FormUI/SubmitWrapper/SubmitWrapper"

import SelectWrapper from "../../FormUI/SelectWrapper/SelectWrapper"
import SelectApiWrapper from "../../FormUI/SelectWrapper/SelectApiWrapper"
import TextfieldWrapper from "../../FormUI/Textfield/TextfieldWrapper"
import SearchIcon from '@mui/icons-material/Search'
import UndoIcon from '@mui/icons-material/Undo'
import { status } from './../../../enums/Transactions'
import axios from "axios"
import DateTimePickerWrapper from '../../FormUI/DateTimePickerWrapper/DateTimePickerWrapper';
import useIsMounted from '../../../hooks/useIsMounted';
import { clearCache, getCache, removeNullValues } from "../../../services/utils"
import useAxiosPrivate from "../../../hooks/useAxiosPrivate"

export default function Filters({
    handleOnSearch
}) {
    const ref = useRef()

    const { t } = useTranslation()

    const api = useAxiosPrivate()

    const isMounted = useIsMounted()

    const [firstLoad, setFirstLoad] = useState(true)
    const [methods, setMethods] = useState([])
    const [providers, setProviders] = useState([])
    const [countries, setCountries] = useState([])

    const initialFormState = {
        transaction_id__in: '',
        merchant__username__icontains: '',
        customer__email__icontains: '',
        customer__first_name__icontains: '',
        customer__last_name__icontains: '',
        method_or_provider__icontains: '',
        status: 2,
        payment_method__api_code: '',
        payment_method__method_provider: '',
        country: '',
        status_date__date__gte: '',
        status_date__date__lte: '',
        system_price__gte: '',
        sub_merchant_id__icontains: ''
    }

    const resetFields = () => {
        clearCache('filters.transactions')
    }

    useEffect(() => {
        const retrieveData = () => {
            axios.all([
                api.get('/internal/info/api-codes/'),
                api.get('/internal/info/providers/'),
                api.get('/internal/info/countries/')
            ])
                .then(axios.spread((...responses) => ({
                    methods: responses[0].data.data,
                    providers: responses[1].data.data,
                    countries: responses[2].data.data
                })))
                .then(({
                    methods,
                    providers,
                    countries
                }) => {
                    if (isMounted()) {
                        const payment_methods = methods.map(m => {
                            return {
                                id: m.api_code,
                                name: m.name
                            }
                        })
                            .sort((a, b) => (a.name > b.name) ? 1 : (a.name < b.name ? -1 : 0))

                        const country_list = countries.sort((a, b) => (a.name_es > b.name_es) ? 1 : ((b.name_es > a.name_es) ? -1 : 0))

                        setMethods(payment_methods)
                        setCountries(country_list.map(c => ({ id: c.code, name: c.name })))
                        setProviders(providers)

                        if (firstLoad) {
                            const cache = removeNullValues(getCache('filters.transactions'))

                            Object.keys(cache).map(k => (
                                ref.current.setFieldValue([k], cache[k])
                            ))
                            setFirstLoad(false)
                        }
                    }
                })
        }

        retrieveData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMounted])

    return (
        <Formik
            initialValues={{
                ...initialFormState
            }}
            onSubmit={handleOnSearch}
            innerRef={ref}
        >
            {({ handleReset, handleSubmit }) => (<Form>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextfieldWrapper
                            name="transaction_id__in"
                            label={t('Transaction ID')}
                            type="text"
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <TextfieldWrapper
                            name="merchant__username__icontains"
                            label={t('Merchant')}
                            type="text"
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <TextfieldWrapper
                            name="customer__first_name__icontains"
                            label={t('First name')}
                            type="text"
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <TextfieldWrapper
                            name="customer__last_name__icontains"
                            label={t('Last name')}
                            type="text"
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <TextfieldWrapper
                            name="customer__email__icontains"
                            label={t('Buyer email')}
                            type="text"
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <TextfieldWrapper
                            name="method_or_provider__icontains"
                            label={t('Provider ID')}
                            type="text"
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <SelectWrapper
                            name="status"
                            label={t('Status')}
                            options={{
                                ...{ '0': 'All' }, ...status
                            }}
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <SelectApiWrapper
                            name="payment_method__api_code"
                            label={t('Payment method')}
                            options={[
                                { id: 'all', name: t('All') }, ...methods
                            ]}
                            size="small"
                            translate={false}
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <SelectApiWrapper
                            name="payment_method__method_provider"
                            label={t('Provider')}
                            options={[
                                { id: 'all', name: t('All') }, ...providers
                            ]}
                            size="small"
                            translate={false}
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <TextfieldWrapper
                            name="sub_merchant_id__icontains"
                            label={t('Sub Merchant ID')}
                            type="text"
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <SelectApiWrapper
                            name="country"
                            label={t('Country')}
                            options={[
                                { id: 'all', name: t('All') }, ...countries
                            ]}
                            size="small"
                            translate={false}
                        />
                    </Grid>

                    <Grid item xs={12} md={1}>
                        <TextfieldWrapper
                            name="system_price__gte"
                            label={t('Price greater than')}
                            type="text"
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <DateTimePickerWrapper
                            name="status_date__date__gte"
                            label={t('From date')}
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <DateTimePickerWrapper
                            name="status_date__date__lte"
                            label={t('To date')}
                            size="small"
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            <SubmitWrapper startIcon={<SearchIcon />}>{t('Search')}</SubmitWrapper>
                            <ButtonWrapper
                                onClick={() => {
                                    resetFields()
                                    handleReset()
                                    handleSubmit()
                                }}
                                variant="text"
                                startIcon={<UndoIcon />}>{t('Reset')}</ButtonWrapper>
                        </Stack>
                    </Grid>
                </Grid>
            </Form>)}
        </Formik >
    )
}
