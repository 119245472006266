const TRANSACTIONS__VIEW = 1
const TRANSACTIONS__COMPLETE = 2
const TRANSACTIONS__CHARGEBACK = 3
const TRANSACTIONS__REFUND = 4
const TRANSACTIONS__HOLD = 5

const MERCHANTS__VIEW = 6
const MERCHANTS__CREATE = 7
const MERCHANTS__UPDATE = 8

const ACCOUNT_CONFIG__VIEW = 9
const ACCOUNT_CONFIG__UPDATE = 10
const MERCHANT_PAYMENT_METHODS__VIEW = 11
const MERCHANT_PAYMENT_METHODS__UPDATE = 12
const BANK_ACCOUNTS__VIEW = 13
const BANK_ACCOUNTS__CREATE = 14
const BANK_ACCOUNTS__UPDATE = 15
const BANK_ACCOUNTS__DELETE = 16
const COUNTRY_SPREADS__VIEW = 17
const COUNTRY_SPREADS__CREATE = 18
const COUNTRY_SPREADS__UPDATE = 19
const COUNTRY_SPREADS__DELETE = 20
const MISCELLANEOUS__VIEW = 21
const MISCELLANEOUS__CREATE = 22
const MISCELLANEOUS__UPDATE = 23
const MISCELLANEOUS__DELETE = 24
const MERCHANT_CONTACTS__VIEW = 25
const MERCHANT_CONTACTS__CREATE = 26
const MERCHANT_CONTACTS__UPDATE = 27
const MERCHANT_CONTACTS__DELETE = 28
const MERCHANTS__VIEW_BALANCE = 29

const WITHDRAWALS__VIEW = 30
const WITHDRAWALS__COMPLETE = 31
const WITHDRAWALS__DELETE = 32

const PAYMENT_METHODS__VIEW = 33
const PAYMENT_METHODS__CREATE = 34
const PAYMENT_METHODS__UPDATE = 35
const PAYMENT_METHODS__FEES = 36

const METHOD_PROVIDERS__VIEW = 37
const METHOD_PROVIDERS__CREATE = 38
const METHOD_PROVIDERS__UPDATE = 39

const CASHOUTS__VIEW = 40
const CASHOUTS__PROCESS = 41
const CASHOUTS__DELETE = 42

const USERS__VIEW = 43
const USERS__CREATE = 44
const USERS__UPDATE = 45

const COUNTRIES__VIEW = 46
const COUNTRIES__CREATE = 47
const COUNTRIES__UPDATE = 48
const PERMISSIONS__VIEW = 49
const USERS__PERMISSIONS = 50

const ADDRESSES__VIEW = 51
const ADDRESSES__CREATE = 52
const ADDRESSES__UPDATE = 53
const ADDRESSES__DELETE = 54

export {
    TRANSACTIONS__VIEW,
    TRANSACTIONS__COMPLETE,
    TRANSACTIONS__CHARGEBACK,
    TRANSACTIONS__REFUND,
    TRANSACTIONS__HOLD,
    MERCHANTS__VIEW,
    MERCHANTS__CREATE,
    MERCHANTS__UPDATE,
    ACCOUNT_CONFIG__VIEW,
    ACCOUNT_CONFIG__UPDATE,
    MERCHANT_PAYMENT_METHODS__VIEW,
    MERCHANT_PAYMENT_METHODS__UPDATE,
    BANK_ACCOUNTS__VIEW,
    BANK_ACCOUNTS__CREATE,
    BANK_ACCOUNTS__UPDATE,
    BANK_ACCOUNTS__DELETE,
    COUNTRY_SPREADS__VIEW,
    COUNTRY_SPREADS__CREATE,
    COUNTRY_SPREADS__UPDATE,
    COUNTRY_SPREADS__DELETE,
    MISCELLANEOUS__VIEW,
    MISCELLANEOUS__CREATE,
    MISCELLANEOUS__UPDATE,
    MISCELLANEOUS__DELETE,
    MERCHANT_CONTACTS__VIEW,
    MERCHANT_CONTACTS__CREATE,
    MERCHANT_CONTACTS__UPDATE,
    MERCHANT_CONTACTS__DELETE,
    MERCHANTS__VIEW_BALANCE,
    WITHDRAWALS__VIEW,
    WITHDRAWALS__COMPLETE,
    WITHDRAWALS__DELETE,
    PAYMENT_METHODS__VIEW,
    PAYMENT_METHODS__CREATE,
    PAYMENT_METHODS__UPDATE,
    PAYMENT_METHODS__FEES,
    METHOD_PROVIDERS__VIEW,
    METHOD_PROVIDERS__CREATE,
    METHOD_PROVIDERS__UPDATE,
    CASHOUTS__VIEW,
    CASHOUTS__PROCESS,
    CASHOUTS__DELETE,
    USERS__VIEW,
    USERS__CREATE,
    USERS__UPDATE,
    COUNTRIES__VIEW,
    COUNTRIES__CREATE,
    COUNTRIES__UPDATE,
    PERMISSIONS__VIEW,
    USERS__PERMISSIONS,
    ADDRESSES__VIEW,
    ADDRESSES__CREATE,
    ADDRESSES__UPDATE,
    ADDRESSES__DELETE
}