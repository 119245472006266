import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useTranslation } from 'react-i18next'
import Pagination from '../../Pagination/Pagination'
import TableBackdrop from '../../TableUI/TableBackdrop'
import TableActions from '../../TableUI/TableActions'
import { Typography } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import EditIcon from '@mui/icons-material/Edit'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import { useNavigate } from 'react-router-dom'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import { PAYMENT_METHODS__FEES, PAYMENT_METHODS__UPDATE, PAYMENT_METHODS__VIEW } from '../../../enums/Caps'
import useHasPermissions from '../../../hooks/useHasPermissions'


export default function MethodsTable({
    data,
    pages = 1,
    page = 1,
    loading = false,
    handleOnPageChange
}) {
    const { t } = useTranslation()

    const hasPermissions = useHasPermissions()

    const navigate = useNavigate()

    return (
        <>
            <Pagination
                handleOnPageChange={handleOnPageChange}
                page={page}
                pages={pages}
            />

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('Method')}</TableCell>
                            <TableCell>{t('Provider')}</TableCell>
                            <TableCell>{t('Country')}</TableCell>
                            <TableCell align="center">{t('Status')}</TableCell>
                            <TableCell align="center">{t('Default')}</TableCell>
                            <TableCell align="right">&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading && data.length ? data.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.name}
                                    <Typography variant="subtitle2">
                                        {row.api_code}
                                    </Typography>
                                </TableCell>
                                <TableCell>{row.provider}</TableCell>
                                <TableCell sx={{
                                    maxWidth: '200px',
                                    wordWrap: 'break-word'
                                }}>{row.countries}</TableCell>
                                <TableCell align="center">{t(row.status_)}</TableCell>
                                <TableCell align="center">{(row.is_default ? <CheckIcon /> : '')}</TableCell>
                                <TableCell align="right">
                                    <TableActions actions={[
                                        {
                                            id: `edit-${row.id}`,
                                            handleClick: () => { navigate(`${row.id}/edit`) },
                                            icon: <EditIcon />,
                                            title: hasPermissions(PAYMENT_METHODS__UPDATE) ? t('Edit') : t('View')
                                        },
                                        {
                                            id: `fees-${row.id}`,
                                            handleClick: () => { navigate(`${row.id}/fees`) },
                                            icon: <MonetizationOnIcon />,
                                            title: t('Fees'),
                                            show: hasPermissions([PAYMENT_METHODS__VIEW, PAYMENT_METHODS__FEES])
                                        },
                                        {
                                            id: `submethods-${row.id}`,
                                            handleClick: () => { navigate(`${row.id}/submethods`) },
                                            icon: <CreditCardIcon />,
                                            title: t('Submethods'),
                                            show: true,
                                        }
                                    ]} />
                                </TableCell>
                            </TableRow>
                        )) : <TableRow>
                            {
                                loading ? <TableBackdrop open={loading} /> : <TableCell sx={{ textAlign: 'center' }} colSpan={100}>{t('No records found')}</TableCell>
                            }
                        </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <Pagination
                handleOnPageChange={handleOnPageChange}
                page={page}
                pages={pages}
            />
        </>
    )
}
