import { Grid, Card, CardContent, CardHeader, Avatar, Typography, CircularProgress, Alert } from '@mui/material'
import PageTitle from '../../Misc/PageTitle'
import { useTranslation } from 'react-i18next'
import { blue, green, red, yellow } from '@mui/material/colors'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import PaymentIcon from '@mui/icons-material/Payment'
import PaidIcon from '@mui/icons-material/Paid'
import { useEffect, useState } from 'react'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import useIsMounted from '../../../hooks/useIsMounted'
import { toast } from 'react-toastify'
import { TRANSACTIONS__VIEW } from '../../../enums/Caps'
import useHasPermissions from '../../../hooks/useHasPermissions';


export default function Dashboard() {
    const { t } = useTranslation()

    const hasPermissions = useHasPermissions()

    const axiosPrivate = useAxiosPrivate()

    const isMounted = useIsMounted()

    const [loading, setLoading] = useState(true)

    const [data, setData] = useState({})

    useEffect(() => {
        const getData = () => {
            axiosPrivate.get('/internal/dashboard/')
                .then(({ data: { data } }) => isMounted() && setData(data))
                .catch(() => isMounted() && toast.error(t('Something went wrong')))
                .finally(() => isMounted() && setLoading(false))
        }

        if (hasPermissions(TRANSACTIONS__VIEW)) {
            getData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [axiosPrivate, isMounted, t])

    return (
        <Card>
            <CardContent>
                <PageTitle title={t('Dashboard')} />

                <Grid container spacing={2}>
                    {!hasPermissions(TRANSACTIONS__VIEW) ? <Grid item xs={12}>
                        <Alert severity="warning">No cuentas con suficientes permisos para visualizar este contenido</Alert>
                    </Grid> : ''}

                    {hasPermissions(TRANSACTIONS__VIEW) ? (<Grid item xs={12} md={6} lg={4} xl={3} p={2}>
                        <Card>
                            <CardHeader
                                avatar={
                                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                                        <PersonOutlineIcon />
                                    </Avatar>
                                }
                                title={
                                    loading ? <CircularProgress color="inherit" /> : <Typography gutterBottom variant="h4" component="div" sx={{ fontWeight: '600' }}>
                                        {data?.active_merchants}
                                    </Typography>

                                }
                                subheader="Merchants activos"
                            />
                        </Card>
                    </Grid>) : ''}

                    {hasPermissions(TRANSACTIONS__VIEW) ? (<Grid item xs={12} md={6} lg={4} xl={3} p={2}>
                        <Card>
                            <CardHeader
                                avatar={
                                    <Avatar sx={{ bgcolor: blue[500] }} aria-label="recipe">
                                        <PaymentIcon />
                                    </Avatar>
                                }
                                title={
                                    loading ? <CircularProgress color="inherit" /> : <Typography gutterBottom variant="h4" component="div" sx={{ fontWeight: '600' }}>
                                        {data?.transactions_last_24_hours}
                                    </Typography>
                                }
                                subheader="Pagos en las últimas 24 horas"
                            />
                        </Card>
                    </Grid>) : ''}

                    {hasPermissions(TRANSACTIONS__VIEW) ? (<Grid item xs={12} md={6} lg={4} xl={3} p={2}>
                        <Card>
                            <CardHeader
                                avatar={
                                    <Avatar sx={{ bgcolor: green[500] }} aria-label="recipe">
                                        <PaidIcon />
                                    </Avatar>
                                }
                                title={
                                    loading ? <CircularProgress color="inherit" /> : <Typography gutterBottom variant="h4" component="div" sx={{ fontWeight: '600' }}>
                                        {new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(data.sold_this_month)}
                                    </Typography>
                                }
                                subheader="Procesados en este mes"
                            />
                        </Card>
                    </Grid>) : ''}

                    {hasPermissions(TRANSACTIONS__VIEW) ? (<Grid item xs={12} md={6} lg={4} xl={3} p={2}>
                        <Card>
                            <CardHeader
                                avatar={
                                    <Avatar sx={{ bgcolor: yellow[900] }} aria-label="recipe">
                                        <PaidIcon />
                                    </Avatar>
                                }
                                title={
                                    loading ? <CircularProgress color="inherit" /> : <Typography gutterBottom variant="h4" component="div" sx={{ fontWeight: '600' }}>
                                        {new Intl.NumberFormat('es-ES', { maximumFractionDigits: 2 }).format(data.conversion_rate_this_month)}%
                                    </Typography>
                                }
                                subheader="Tasa de conversión"
                            />
                        </Card>
                    </Grid>) : ''}
                </Grid>

            </CardContent>
        </Card>
    )
}

