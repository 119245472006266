import { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { useTranslation } from 'react-i18next';

import { Outlet } from "react-router-dom";

import Navbar from '../Header/Navbar';
import Sidebar from '../Sidebar/Sidebar';

import { store } from '../../store/store';


const drawerWidth = 250;

export default function Page() {
    const [mobileOpen, setMobileOpen] = useState(false);

    const { i18n } = useTranslation();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    useEffect(() => {
        i18n.changeLanguage('es');
    }, [i18n]);

    return (
        <Provider store={store}>
            <CssBaseline>
                <Box sx={{ display: 'flex' }}>
                    <Sidebar
                        handleDrawerToggle={handleDrawerToggle}
                        mobileOpen={mobileOpen}
                        drawerWidth={drawerWidth}
                    />

                    <Navbar
                        handleDrawerToggle={handleDrawerToggle}
                        mobileOpen={mobileOpen}
                        drawerWidth={drawerWidth}
                    />

                    <main className="main-container">
                        <div className="top-brick" />
                        <Outlet />
                    </main>
                </Box>
            </CssBaseline>
        </Provider>
    )
}
