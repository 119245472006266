import { useEffect, useState } from 'react'
import { Card, CardContent, Grid, Hidden, Stack } from '@mui/material'
import FormSkeletonWrapper from '../../../SkeletonUI/FormSkeletonWrapper/FormSkeletonWrapper'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import AddressesForm from './AddressesForm'
import { parseErrors } from '../../../../services/utils'
import { toast } from 'react-toastify'
import PageTitle from '../../../Misc/PageTitle'
import MerchantSubmenu from '../MerchantSubmenu'
import MerchantAlias from '../MerchantAlias'
import MerchantMenu from '../MerchantMenu'
import useIsMounted from '../../../../hooks/useIsMounted'
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'
import { ADDRESSES__CREATE } from '../../../../enums/Caps'
import useHasPermissions from '../../../../hooks/useHasPermissions'


export default function AddressCreate() {
    const { t } = useTranslation()

    const hasPermissions = useHasPermissions()

    const navigate = useNavigate()

    const isMounted = useIsMounted()

    const api = useAxiosPrivate()

    const { userId } = useParams()

    const handleSubmit = (values, { setFieldError }) => {
        api.post(`/internal/merchants/${userId}/addresses/`, values)
            .then(() => {
                if (isMounted()) {
                    toast.success(t('Address has been created'))
                    navigate(`/merchants/${userId}/addresses`)
                }
            })
            .catch(({ response }) => {
                if (isMounted()) {
                    const errors = parseErrors(response)

                    if (typeof errors === 'object') {
                        for (let i in errors) {
                            setFieldError(errors[i].field, t(errors[i].message), false)
                        }
                    } else if (typeof errors === 'string') {
                        toast.error(t(errors))
                    } else {
                        toast.error(t('Something went wrong'))
                    }
                }
            })
    }

    const handleCancel = () => {
        navigate(`/merchants/${userId}/addresses`)
    }

    const initialFormState = {
        address_type: '',
        name: '',
        country: '',
        locality: '',
        zip_code: '',
        address: '',
        address_line2: '',
        subregion: '',
        phone_number: ''
    }

    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setIsLoading(false), 1000)
    }, [])

    return (
        <>
            <Card>
                <CardContent>
                    <Stack direction="row" justifyContent="space-between" spacing={2}>
                        <PageTitle title={t('Add new merchant address')} />

                        <Hidden xlUp={true}>
                            {userId ? <MerchantMenu id={userId} /> : ''}
                        </Hidden>
                    </Stack>

                    <MerchantAlias />

                    <Grid container spacing={2}>
                        <Grid item xs={12} xl={2}>
                            <MerchantSubmenu current='addresses' id={userId} />
                        </Grid>
                        <Grid item xs={12} xl={10}>
                            {
                                isLoading ? <FormSkeletonWrapper /> :
                                    <AddressesForm
                                        isNew={true}
                                        handleSubmit={handleSubmit}
                                        handleCancel={handleCancel}
                                        initialFormState={initialFormState}
                                        merchant_id={userId}
                                        hasPermissions={hasPermissions(ADDRESSES__CREATE)}
                                    />
                            }
                        </Grid>
                    </Grid>

                </CardContent>
            </Card>
        </>
    )
}
