import { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import PermissionsTable from './PermissionsTable'
import { useTranslation } from 'react-i18next'
import useIsMounted from '../../../../hooks/useIsMounted'
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'
import { toast } from 'react-toastify'
import VpnKeyIcon from '@mui/icons-material/VpnKey'


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: '90%',
        md: 600
    },
    height: '80%',
    overflow: 'scroll',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
}

export default function PermissionsModal({
    user,
    user_permissions = []
}) {
    const { t } = useTranslation()

    const isMounted = useIsMounted()

    const api = useAxiosPrivate()

    const [open, setOpen] = useState(false)

    const handleOpen = () => setOpen(true)

    const handleClose = () => setOpen(false)

    const [permissions, setPermissions] = useState([])

    const [selected, setSelected] = useState(user_permissions)

    const [loading, setLoading] = useState(true)

    const handleSubmit = () => {
        api.put(`/internal/users/${user.id}/permissions/`, {
            permissions: selected.map(p => ({
                permission: p
            }))
        })
            .then(() => {
                toast.success(t('User permissions has been updated'))
                setOpen(false)
            })
    }

    useEffect(() => {
        const retrievePermissions = () => {
            api.get('/internal/permissions/')
                .then(({ data: { data } }) => {
                    if (isMounted()) {
                        setPermissions(data)
                    }
                })
                .catch(() => isMounted() && toast.error(t('Something went wrong')))
                .finally(() => isMounted() && setLoading(false))
        }

        retrievePermissions()
    }, [api, isMounted, t])

    return (
        <div>
            <Button
                variant="outlined"
                color="success"
                onClick={handleOpen}
                startIcon={<VpnKeyIcon />}
            >{t('Permissions')}</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {t('User permissions')}
                    </Typography>

                    <PermissionsTable
                        data={permissions}
                        loading={loading}
                        selected={selected}
                        setSelected={setSelected}
                    />

                    <Button
                        onClick={handleSubmit}
                        variant="outlined"
                        color="success"
                        sx={{
                            width: '100%',
                            my: 2
                        }}
                    >{t('Update permissions')}</Button>

                    <Button
                        variant="text"
                        onClick={handleClose}
                        sx={{
                            width: '100%',
                        }}
                    >{t('Cancel')}</Button>
                </Box>
            </Modal>
        </div>
    )
}