import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useTranslation } from 'react-i18next'
import EditIcon from '@mui/icons-material/Edit'
import TableBackdrop from 'components/TableUI/TableBackdrop'
import Pagination from 'components/Pagination/Pagination'
import { Stack, Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { COUNTRY_SPREADS__UPDATE } from 'enums/Caps'
import useHasPermissions from 'hooks/useHasPermissions'
import IconButton from '@mui/material/IconButton'
import { useState } from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import TextField from '@mui/material/TextField'
import useAxiosPrivate from 'hooks/useAxiosPrivate'
import { toast } from 'react-toastify'


function Per({ children, permission }) {
    const hasPermissions = useHasPermissions()
    const hasPermission = hasPermissions(permission)
    return hasPermission ? children : null
}


function percentOrDash(value) {
    return (value !== null) ? `${value}%` : '-'
}


function EditButton({ handleClick }) {
    return <IconButton
        aria-label="edit"
        onClick={handleClick}
    >
        <EditIcon />
    </IconButton>
}


function SpreadRow({ data }) {
    const [editing, setEditing] = useState(false)
    const [spread, setSpread] = useState(data.spread)
    const [savedSpread, setSavedSpread] = useState(data.spread)
    const [waitingResponse, setWaitingResponse] = useState(false)
    const api = useAxiosPrivate()
    const { t } = useTranslation()
    return <TableRow
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        style={{ opacity: waitingResponse ? 0.5 : 1 }}
    >
        <TableCell component="th" scope="row">
            <Typography noWrap>{data.country}</Typography>
        </TableCell>

        <TableCell>
            {editing ?
                <Stack direction="row" alignItems="center">
                    <TextField
                        id="spread"
                        type="number"
                        value={spread ?? ''}
                        onChange={(e) => setSpread(e.target.value === '' ? null : e.target.value)}
                        size="small"
                        sx={{ width: 90 }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <IconButton onClick={() => { setSpread(null) }}><DeleteIcon /></IconButton>
                </Stack> :
                percentOrDash(spread)
            }
        </TableCell>
        <TableCell align='right'>
            {editing ?
                <>
                    <IconButton onClick={() => { setSpread(savedSpread); setEditing(false) }}>
                        <CancelIcon style={{ fill: "red" }} />
                    </IconButton>
                    <IconButton onClick={() => {
                        api.put(`/internal/spreads/${data.country}/`, { spread: spread })
                            .then(({ data: { data } }) => {
                                setSavedSpread(data.spread)
                                setSpread(data.spread)
                            }).catch((error) => {
                                setSpread(savedSpread)
                                toast.error(t("Error updating spread"))
                            }).finally(() => {
                                setEditing(false)
                                setWaitingResponse(false)
                            })
                        setWaitingResponse(true)
                    }}>
                        <CheckCircleIcon style={{ fill: "green" }} />
                    </IconButton>
                </> :
                <Per permission={COUNTRY_SPREADS__UPDATE}><EditButton handleClick={() => setEditing(true)} /></Per>
            }
        </TableCell>
    </TableRow >
}


export default function SpreadsTable({
    data,
    pages = 1,
    page = 1,
    loading = false,
    handleOnPageChange,
}) {
    const { t } = useTranslation()

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('Country')}</TableCell>
                            <TableCell>{t('Spread')}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.length ? data.map((row) => (
                            <SpreadRow key={row.country} data={row} />
                        )) : <TableRow>
                            {
                                loading ? <TableBackdrop open={loading} /> : <TableCell sx={{ textAlign: 'center' }} colSpan={100}>{t('No records found')}</TableCell>
                            }
                        </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <Pagination
                handleOnPageChange={handleOnPageChange}
                page={page}
                pages={pages}
            />
        </>
    )
}
