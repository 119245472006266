import { useEffect, useState, useRef } from "react"
import { Box, Grid, Stack } from "@mui/material"
import { Form, Formik } from "formik"
import { useTranslation } from 'react-i18next'

import ButtonWrapper from "../../FormUI/ButtonWrapper/ButtonWrapper"
import SubmitWrapper from "../../FormUI/SubmitWrapper/SubmitWrapper"

import SearchIcon from '@mui/icons-material/Search'
import UndoIcon from '@mui/icons-material/Undo'
import SelectWrapper from '../../FormUI/SelectWrapper/SelectWrapper'
import { status, ST_PRODUCTION } from "../../../enums/PaymentMethods"
import axios from "axios"
import useIsMounted from "../../../hooks/useIsMounted"
import SelectApiWrapper from "../../FormUI/SelectWrapper/SelectApiWrapper"
import { clearCache, getCache, removeNullValues } from "../../../services/utils"
import useAxiosPrivate from "../../../hooks/useAxiosPrivate"

export default function Filters({
    handleOnSearch
}) {
    const { t } = useTranslation()

    const isMounted = useIsMounted()

    const api = useAxiosPrivate()

    const ref = useRef()

    const [firstLoad, setFirstLoad] = useState(true)
    const [methods, setMethods] = useState([])
    const [providers, setProviders] = useState([])
    const [countries, setCountries] = useState([])

    const initialFormState = {
        api_code: '',
        countries__contains: '',
        method_provider: '',
        status: ST_PRODUCTION
    }

    const resetFields = () => {
        clearCache('filters.methods')
    }

    useEffect(() => {
        const retrieveData = () => {
            axios.all([
                api.get('/internal/payment-methods/grouped/'),
                api.get('/internal/payment-methods/providers/'),
                api.get('/internal/countries/')
            ])
                .then(axios.spread((...responses) => (isMounted() && {
                    methods: responses[0].data.data,
                    providers: responses[1].data.data
                        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0)),
                    countries: responses[2].data.data
                })))
                .then(({
                    methods,
                    providers,
                    countries
                }) => {
                    if (isMounted()) {
                        const payment_methods = methods.map(m => {
                            return {
                                id: m.api_code,
                                name: m.payment_methods[0].name
                            }
                        })
                            .sort((a, b) => (a.name > b.name) ? 1 : (a.name < b.name ? -1 : 0))

                        const country_list = countries.sort((a, b) => (a.name_es > b.name_es) ? 1 : ((b.name_es > a.name_es) ? -1 : 0))

                        setMethods(payment_methods)
                        setCountries(country_list.map(c => ({ id: c.code, name: c.name })))
                        setProviders(providers)

                        if (firstLoad) {
                            const cache = removeNullValues(getCache('filters.methods'))

                            Object.keys(cache).map(k => (
                                ref.current.setFieldValue([k], cache[k])
                            ))
                            setFirstLoad(false)
                        }
                    }
                })
        }

        retrieveData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMounted, api])

    return (
        <Formik
            initialValues={{
                ...initialFormState
            }}
            onSubmit={handleOnSearch}
            innerRef={ref}
        >
            {({ handleReset, handleSubmit }) => (<Form>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <SelectApiWrapper
                            name="api_code"
                            label={t('Payment method')}
                            options={[
                                { id: 'all', name: t('All') }, ...methods
                            ]}
                            size="small"
                            translate={false}
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <SelectApiWrapper
                            name="method_provider"
                            label={t('Provider')}
                            options={[
                                { id: 'all', name: t('All') }, ...providers
                            ]}
                            size="small"
                            translate={false}
                        />
                    </Grid>

                    <Grid item xs={6} md={2}>
                        <SelectWrapper
                            name="status"
                            label={t('Status')}
                            options={
                                { 'all': 'All', ...status }
                            }
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <SelectApiWrapper
                            name="countries__contains"
                            label={t('Country')}
                            options={[
                                { id: 'all', name: t('All') }, ...countries
                            ]}
                            size="small"
                            translate={false}
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            <SubmitWrapper startIcon={<SearchIcon />}>{t('Search')}</SubmitWrapper>
                            <ButtonWrapper
                                onClick={() => {
                                    resetFields()
                                    handleReset()
                                    handleSubmit()
                                }}
                                variant="text"
                                startIcon={<UndoIcon />}>{t('Reset')}</ButtonWrapper>
                        </Stack>
                    </Grid>
                </Grid>
            </Form>)}
        </Formik>
    )
}
