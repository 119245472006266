import { Typography } from "@mui/material"
import Link from '@mui/material/Link'


export default function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://paygol.com/" target="_blank" rel='noopener'>
                Paygol SpA.
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    )
}
