import { Box, Grid, Stack } from "@mui/material"
import { Form, Formik } from "formik"
import { useTranslation } from 'react-i18next'
import SearchIcon from '@mui/icons-material/Search'
import UndoIcon from '@mui/icons-material/Undo'
import TextfieldWrapper from '../../../FormUI/Textfield/TextfieldWrapper'
import ButtonWrapper from '../../../FormUI/ButtonWrapper/ButtonWrapper';
import SubmitWrapper from '../../../FormUI/SubmitWrapper/SubmitWrapper';
import SelectWrapper from '../../../FormUI/SelectWrapper/SelectWrapper';
import { country_regions } from "../../../../enums/Countries"


export default function Filters({
    handleOnSearch
}) {
    const { t } = useTranslation()

    const initialFormState = {
        name_es__icontains: '',
        code: '',
        region: 'Americas'
    }

    return (
        <Formik
            initialValues={{
                ...initialFormState
            }}
            onSubmit={handleOnSearch}
        >
            {({ handleReset, handleSubmit }) => (<Form>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <TextfieldWrapper
                            name="code"
                            label={t('Code')}
                            type="text"
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <TextfieldWrapper
                            name="name__icontains"
                            label={t('Name')}
                            type="text"
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <SelectWrapper
                            name="region"
                            label={t('Region')}
                            size="small"
                            options={country_regions}
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            <SubmitWrapper startIcon={<SearchIcon />}>{t('Search')}</SubmitWrapper>
                            <ButtonWrapper
                                onClick={() => {
                                    handleReset()
                                    handleSubmit()
                                }}
                                variant="text"
                                startIcon={<UndoIcon />}>{t('Reset')}</ButtonWrapper>
                        </Stack>
                    </Grid>
                </Grid>
            </Form>)}
        </Formik>
    )
}
