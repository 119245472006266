import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useTranslation } from 'react-i18next'
import TableBackdrop from '../../../TableUI/TableBackdrop'
import Pagination from '../../../Pagination/Pagination'
import { tableHeadBuild, MakeRowDataCellsComponent } from '../../../Common/Table'
import useHasPermissions from '../../../../hooks/useHasPermissions'
import { useNavigate } from 'react-router-dom'
import { ADDRESSES__DELETE, ADDRESSES__UPDATE } from '../../../../enums/Caps'
import EditIcon from '@mui/icons-material/Edit'
import TableActions from '../../../TableUI/TableActions'
import DeleteIcon from '@mui/icons-material/Delete'
import PreviewIcon from '@mui/icons-material/Preview'


const AddressDataCells = MakeRowDataCellsComponent(
    ['address_type_', 'name', 'country', 'locality', 'zip_code', 'address', 'address_line_2', 'subregion', 'phone_number']
)


export default function AddressesTable({
    data,
    pages = 1,
    page = 1,
    loading = false,
    handleOnPageChange,
    handleRemove,
}) {
    const { t } = useTranslation()

    const hasPermissions = useHasPermissions()

    const navigate = useNavigate()

    const handleNavigate = (view) => {
        navigate(view)
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    {tableHeadBuild(
                        ['Type', 'Name', 'Country', 'Locality', 'Zip Code', 'Address', 'Address Line 2', 'Subregion', 'Phone Number', ''].map(t)
                    )}
                    <TableBody>
                        {data.length ? data.map((row, idx) => (
                            <TableRow
                                key={idx}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <AddressDataCells entry={row} />
                                <TableCell align="center">
                                    <TableActions actions={[
                                        {
                                            id: `edit-${row.id}`,
                                            handleClick: () => { handleNavigate(`${row.id}/edit`) },
                                            icon: hasPermissions(ADDRESSES__UPDATE) ? <EditIcon /> : <PreviewIcon />,
                                            title: hasPermissions(ADDRESSES__UPDATE) ? t('Edit') : t('View')
                                        },
                                        {
                                            id: `remove-${row.id}`,
                                            handleClick: () => { return handleRemove(row.id) },
                                            icon: <DeleteIcon />,
                                            title: t('Remove'),
                                            show: hasPermissions(ADDRESSES__DELETE)
                                        }
                                    ]} />
                                </TableCell>
                            </TableRow>
                        )) : <TableRow>
                            {
                                loading ? <TableBackdrop open={loading} /> : <TableCell sx={{ textAlign: 'center' }} colSpan={100}>{t('No records found')}</TableCell>
                            }
                        </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <Pagination
                handleOnPageChange={handleOnPageChange}
                page={page}
                pages={pages}
            />
        </>
    )
}
