export function hasChildren(item) {
    const { items: children } = item;

    if (children === undefined) {
        return false;
    }

    if (children.constructor !== Array) {
        return false;
    }

    if (children.length === 0) {
        return false;
    }

    return true;
}

export function replaceNullValues(obj, ignoreKeys = new Set()) {
    return JSON.parse(JSON.stringify(obj, (k, v) => (v === null && !ignoreKeys.has(k) ? '' : v)))
}

export function replaceEmptyValues(obj) {
    return JSON.parse(JSON.stringify(obj, (k, v) => (v === '' ? null : v)))
}

export function removeNullValues(obj, ignoreKeys = new Set()) {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => (v !== '' && v !== null) || ignoreKeys.has(_)))
}

export function parseErrors(response) {
    const { error } = response.data

    if (response.status === 400) {
        const { errors } = error

        const tmp = Object.keys(errors).map(k => {
            return {
                field: k,
                message: errors[k][0]
            }
        })

        return tmp
    }

    if (response.status === 401) {
        const { error } = response.data
        return error.message
    }
}

export function generateRandomPassword(length = 8) {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyz!.@#$%&ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    let password = ''

    for (let i = 0; i <= length; i++) {
        let random = Math.floor(Math.random() * chars.length)
        password += chars.substring(random, random + 1)
    }

    return password
}

export function getFormatedDate(date) {
    let day = date.getDate()
    day = day < 10 ? `0${day}` : day
    let month = date.getMonth() + 1
    month = month < 10 ? `0${month}` : month
    const year = date.getFullYear()

    return `${year}-${month}-${day}`
}

export const copyTextToClipboard = async (text) => {
    if (!text) {
        return false
    }

    if ('clipboard' in navigator) {
        return await navigator
            .clipboard
            .writeText(text)
    } else {
        return document.execCommand('copy', true, text)
    }
}

export const storeCache = (name, data, hours = 1) => {
    if (localStorage.getItem(name)) {
        localStorage.removeItem(name)
    }

    const expire_at = (new Date()).getTime() + hours * 60 * 60 * 1000

    localStorage.setItem(name, JSON.stringify({
        data: data,
        timestamp: expire_at
    }))
}

export const getCache = (name, fallback = {}) => {
    if (!localStorage.getItem(name)) {
        return fallback
    }

    try {
        const data = JSON.parse(localStorage.getItem(name))

        if (!data.timestamp || data.timestamp < (new Date().getTime())) {
            localStorage.removeItem(name)
            return fallback
        }

        return data.data
    } catch (e) {
        localStorage.removeItem(name)
        return fallback
    }
}

export const clearCache = (name) => {
    if (localStorage.getItem(name)) {
        localStorage.removeItem(name)
    }
}

export const isGreaterThan = (number, limit = 0) => {
    if (number === null || number === 'undefined' || number === '' || number === false || isNaN(number)) {
        return false
    }

    return number >= limit
}

export const addDays = (days) => {
    return new Date((new Date()).getTime() + days * 60 * 60 * 24 * 1000)
}

export const substractDays = (days) => {
    return new Date((new Date()).getTime() - days * 60 * 60 * 24 * 1000)
}
