import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useTranslation } from 'react-i18next'
import Pagination from '../../Pagination/Pagination'
import TableActions from '../../TableUI/TableActions'

import ReadMoreIcon from '@mui/icons-material/ReadMore'

import { Link, useNavigate } from 'react-router-dom'
import TableBackdrop from '../../TableUI/TableBackdrop'
import DatetimeValue from '../../TableUI/DatetimeValue'
import CurrencyValue from '../../TableUI/CurrencyValue'
import PillBadge from '../../TableUI/PillBadge'
import CheckIcon from '@mui/icons-material/Check'
import { FormControl, InputLabel, MenuItem, Select, Tooltip, Typography } from '@mui/material'
import { copyTextToClipboard } from '../../../services/utils'
import { styled } from '@mui/material/styles'
import ReceiptIcon from '@mui/icons-material/Receipt'
import { agents } from '../../../enums/IntermediaryBanks'

const StickyTableCell = styled(TableCell)({
    position: 'sticky',
    left: 0,
    top: 'auto',
    background: '#fff'
})

export default function TransactionsTable({
    data,
    pages = 1,
    page = 1,
    loading = false,
    handleOnPageChange,
    handleUpdateIntermediary,
    handleVoucherDownload
}) {
    const { t } = useTranslation()

    const navigate = useNavigate()

    const handleNavigate = (view) => {
        navigate(view)
    }

    // merchant_id, api_code, payment_method_id, sub_merchant_id, sub_merchant_url, status, status_date, metadata, product_name, vat, spread, percent_fee, fixed_fee, fixed_fee_currency, country_price, country_currency, account_price, account_currency, provider_price, provider_currency, system_price, system_currency, , ip_address, provider_id, provider_metadata, withdrawal_id, email_invoice_id, mode, merchant_amount, mdr, mdr_vat, digital_vat, users_payment_id
    return (
        <>
            <Pagination
                handleOnPageChange={handleOnPageChange}
                page={page}
                pages={pages}
            />

            <Paper sx={{
                width: {
                    xs: 'calc(100vw - 100px)',
                    sm: 'calc(100vw - 330px)'
                }, overflow: 'hidden'
            }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <StickyTableCell align="center">&nbsp;</StickyTableCell>
                                <TableCell align="center">{t('Status')}</TableCell>
                                <TableCell>{t('Transaction ID')}</TableCell>
                                <TableCell>{t('Merchant')}</TableCell>
                                <TableCell align="center">{t('Created at')}</TableCell>
                                <TableCell align="center">{t('Status date')}</TableCell>
                                <TableCell align="center">{t('Paid date')}</TableCell>
                                <TableCell>{t('Country')}</TableCell>
                                <TableCell align="right">{t('Price')}</TableCell>
                                <TableCell align="right">{t('Price (EUR)')}</TableCell>
                                <TableCell align="right">{t('Price provider')}</TableCell>
                                <TableCell align="right">{t('To pay')}</TableCell>
                                <TableCell align="center">{t('Method ID')}</TableCell>
                                <TableCell>{t('Method')}</TableCell>
                                <TableCell align="center">{t('Paid to user?')}</TableCell>
                                <TableCell align="center">&nbsp;</TableCell>
                                <TableCell align="center">&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.length ? data.map((row) => (
                                <TableRow
                                    key={row.transaction_id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <StickyTableCell align="right">
                                        <TableActions actions={[
                                            {
                                                id: `details-${row.id}`,
                                                handleClick: () => { handleNavigate(`details/${row.id}`) },
                                                icon: <ReadMoreIcon />,
                                                title: t('Details')
                                            },
                                            {
                                                id: `voucher-${row.id}`,
                                                handleClick: () => { return handleVoucherDownload(row) },
                                                icon: <ReceiptIcon />,
                                                title: t('Voucher')
                                            }
                                        ]} />
                                    </StickyTableCell>
                                    <TableCell align="center">
                                        <PillBadge text={row.status_} action={
                                            row.status === 1 ? 'primary' : (row.status === 2 ? 'success' : 'info')
                                        } />
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip
                                            title={t('Click to copy')}>
                                            <Typography
                                                noWrap
                                                style={{
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    width: '11rem',
                                                    cursor: 'copy'
                                                }}
                                                onClick={() => copyTextToClipboard(row.transaction_id)}
                                            >{row.transaction_id}</Typography>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        <Link to={`/merchants?merchant=${row.merchant.username}`} className="Link">
                                            {row.merchant.username}
                                        </Link>
                                    </TableCell>
                                    <TableCell align="center">
                                        <DatetimeValue date={row.created_at} />
                                    </TableCell>
                                    <TableCell align="center">
                                        <DatetimeValue date={row.status_date} />
                                    </TableCell>
                                    <TableCell align="center">
                                        {row.paid_at && (<DatetimeValue date={row.paid_at} />)}
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography>
                                            <img
                                                srcSet={`https://flagcdn.com/w40/${String(row.country).toLowerCase()}.png 2x`}
                                                alt={row.countr}
                                                src={`https://flagcdn.com/w20/${String(row.country).toLowerCase()}.png`}
                                            />
                                        </Typography>
                                        <Typography>
                                            {row.country}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <CurrencyValue amount={row.price} currency={row.currency} />
                                    </TableCell>
                                    <TableCell align="right">
                                        <CurrencyValue amount={row.system_price} currency={row.system_currency} />
                                    </TableCell>
                                    <TableCell align="right">
                                        <CurrencyValue amount={row.provider_price} currency={row.provider_currency} />
                                    </TableCell>
                                    <TableCell align="right">
                                        <CurrencyValue amount={row.merchant_amount} currency={row.account_currency} />
                                    </TableCell>
                                    <TableCell align={'center'}>
                                        <Tooltip
                                            title={t('Click to copy')}>
                                            <Typography
                                                noWrap
                                                style={{
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    width: '11rem',
                                                    cursor: 'copy'
                                                }}
                                                onClick={() => copyTextToClipboard(row.method_id ? row.method_id : (row.provider_id ? row.provider_id : ''))}
                                            >{row.method_id ? row.method_id : (row.provider_id ? row.provider_id : '--')}</Typography>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{row.payment_method.name}</Typography>
                                        <Typography>{row.payment_method?.method_provider?.name}</Typography>
                                    </TableCell>
                                    <TableCell align="center">{row.is_paid_to_user ? <CheckIcon /> : ''}</TableCell>
                                    <TableCell align="right">
                                        <TableActions actions={[
                                            {
                                                id: `details-${row.id}`,
                                                handleClick: () => { handleNavigate(`details/${row.id}`) },
                                                icon: <ReadMoreIcon />,
                                                title: t('Details')
                                            },
                                            {
                                                id: `voucher-${row.id}`,
                                                handleClick: () => { return handleVoucherDownload(row) },
                                                icon: <ReceiptIcon />,
                                                title: t('Voucher')
                                            }
                                        ]} />
                                    </TableCell>
                                    <TableCell>
                                        {row.payment_method.id === 74 ? (<FormControl fullWidth>
                                            <InputLabel>Intermediario</InputLabel>
                                            <Select
                                                value={row.transaction_extras[0].code}
                                                label="Intermediario"
                                                onChange={(e) => handleUpdateIntermediary(e, row.id)}
                                            >
                                                <MenuItem>---</MenuItem>
                                                {agents.map(a => (
                                                    <MenuItem key={a.code} value={a.code}>{a.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>) : ''}
                                    </TableCell>
                                </TableRow>
                            )) : <TableRow>
                                {
                                    loading ? <TableBackdrop open={loading} /> : <TableCell sx={{ textAlign: 'center' }} colSpan={100}>{t('No records found')}</TableCell>
                                }
                            </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            <Pagination
                handleOnPageChange={handleOnPageChange}
                page={page}
                pages={pages}
            />
        </>
    )
}
