const ST_DEFAULT = 99
const ST_DISABLED = 0
const ST_PRODUCTION = 1

const status = {
    0: 'Disabled',
    1: 'Production'
}

const currencies = {
    'CLP': 'CLP',
    'USD': 'USD',
    'EUR': 'EUR',
}

export {
    status,
    currencies,
    ST_DEFAULT,
    ST_DISABLED,
    ST_PRODUCTION
}