const country_regions = {
    'Africa': 'Africa',
    'Americas': 'Americas',
    'Asia': 'Asia',
    'Europe': 'Europe',
    'Oceania': 'Oceania'
}

const subregions = {
    'Northern Africa': '(Africa) Northern Africa',
    'Southern Africa': '(Africa) Southern Africa',
    'Western Africa': '(Africa) Western Africa',
    'Eastern Africa': '(Africa) Eastern Africa',
    'Middle Africa': '(Africa) Middle Africa',
    'South America': '(Americas) South America',
    'Caribbean': '(Americas) Caribbean',
    'North America': '(Americas) North America',
    'Central America': '(Americas) Central America',
    'Western Asia': '(Asia) Western Asia',
    'South-Eastern Asia': '(Asia) South-Eastern Asia',
    'Eastern Asia': '(Asia) Eastern Asia',
    'Central Asia': '(Asia) Central Asia',
    'Southern Asia': '(Asia) Southern Asia',
    'Central Europe': '(Europe) Central Europe',
    'Northern Europe': '(Europe) Northern Europe',
    'Western Europe': '(Europe) Western Europe',
    'Eastern Europe': '(Europe) Eastern Europe',
    'Southern Europe': '(Europe) Southern Europe',
    'Southeast Europe': '(Europe) Southeast Europe',
    'Polynesia': '(Oceania) Polynesia',
    'Melanesia': '(Oceania) Melanesia',
    'Australia and New Zealand': '(Oceania) Australia and New Zealand',
    'Micronesia': '(Oceania) Micronesia'
}

const nested_subregions = {
    'Africa': {
        'Northern Africa': 'Northern Africa',
        'Southern Africa': 'Southern Africa',
        'Western Africa': 'Western Africa',
        'Eastern Africa': 'Eastern Africa',
        'Middle Africa': 'Middle Africa',
    },
    'Americas': {
        'South America': 'South America',
        'Caribbean': 'Caribbean',
        'North America': 'North America',
        'Central America': 'Central America'
    },
    'Asia': {
        'Western Asia': 'Western Asia',
        'South-Eastern Asia': 'South-Eastern Asia',
        'Eastern Asia': 'Eastern Asia',
        'Central Asia': 'Central Asia',
        'Southern Asia': 'Southern Asia'
    },
    'Europe': {
        'Central Europe': 'Central Europe',
        'Northern Europe': 'Northern Europe',
        'Western Europe': 'Western Europe',
        'Eastern Europe': 'Eastern Europe',
        'Southern Europe': 'Southern Europe',
        'Southeast Europe': 'Southeast Europe'
    },
    'Oceania': {
        'Polynesia': 'Polynesia',
        'Melanesia': 'Melanesia',
        'Australia and New Zealand': 'Australia and New Zealand',
        'Micronesia': 'Micronesia'
    }
}

export {
    country_regions,
    subregions,
    nested_subregions
}
