import { useState, useEffect, useCallback } from 'react'
import { Typography, Grid } from '@mui/material'
import { Link } from 'react-router-dom'

import { Card, CardContent } from '@mui/material'
import { useTranslation } from 'react-i18next'
import MethodProvidersTable from './MethodProvidersTable'
import { toast } from 'react-toastify'
import AddIcon from '@mui/icons-material/Add'
import AppFilters from '../../AppFilters'
import Filters from './Filters'
import ButtonWrapper from '../../FormUI/ButtonWrapper/ButtonWrapper'
import useIsMounted from '../../../hooks/useIsMounted'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { METHOD_PROVIDERS__CREATE } from '../../../enums/Caps'
import useHasPermissions from '../../../hooks/useHasPermissions'


export default function MethodProvidersList() {
    const { t } = useTranslation()

    const hasPermissions = useHasPermissions()

    const isMounted = useIsMounted()

    const api = useAxiosPrivate()

    const [loading, setLoading] = useState(true)

    const [params, setParams] = useState({})

    const [meta, setMeta] = useState({
        total_pages: 1,
        current_page: 1
    })

    const [data, setData] = useState([])

    const handleOnSearch = values => {
        setLoading(true)

        setParams(values)
    }

    const handleOnPageChange = (e, page) => {
        setLoading(true)
        setMeta({ ...meta, current_page: page })
        setParams({ ...params, page: page })
    }

    const retrieveData = useCallback(() => {
        api.get('/internal/payment-methods/providers/', { params: params })
            .then((response) => {
                if (isMounted()) {
                    const { data, meta } = response.data;

                    setData(data)

                    if (meta) {
                        setMeta(meta)
                    }
                }
            })
            .catch(() => isMounted() && toast.error(t('Something went wrong')))
            .finally(() => isMounted() && setLoading(false))
    }, [t, params, isMounted, api])

    useEffect(() => {
        const init = () => {
            retrieveData()
        }

        init()
    }, [params, retrieveData])

    return (
        <>
            <Card>
                <CardContent>
                    <Typography variant="h4" mt={2} mb={4}>{t('Method providers')}</Typography>

                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            {hasPermissions(METHOD_PROVIDERS__CREATE) ? (<ButtonWrapper
                                variant="contained"
                                component={Link}
                                to="create"
                                size="small"
                                className="base__btn-action"
                                startIcon={<AddIcon />}>{t('New')}</ButtonWrapper>) : ''}
                        </Grid>
                    </Grid>

                    <AppFilters Filters={<Filters handleOnSearch={handleOnSearch} />} />

                    <MethodProvidersTable
                        data={data}
                        setData={setData}
                        pages={meta.total_pages}
                        page={meta.current_page}
                        loading={loading}
                        handleOnPageChange={handleOnPageChange}
                    />
                </CardContent>
            </Card>
        </>
    )
}
