import React from 'react';
import { useField, useFormikContext } from 'formik';

export default function GenericWrapper({
    name,
    WrappedComponent,
    ...otherProps
}) {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(name);

    const handleChange = event => {
        const { value } = event.target;
        setFieldValue(name, value);
    };

    const configTextfiel = {
        ...field,
        ...otherProps,
        //fullWidth: true,
        //variant: 'outlined',
        onChange: handleChange
    };

    if (meta && meta.touched && meta.error) {
        configTextfiel.error = true;
        configTextfiel.helperText = meta.error;
    }

    return (
        <WrappedComponent {...configTextfiel} />
    )
}
