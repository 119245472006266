import React, { useState, useEffect } from 'react'
import { Modal, Typography, Box, Grid, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CurrencyValue from '../../TableUI/CurrencyValue'
import ButtonWrapper from '../../FormUI/ButtonWrapper/ButtonWrapper'
import CloseIcon from '@mui/icons-material/Close'



const style = {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
}

export default function WithdrawalModal({
    withdrawal,
    open,
    handleConfirm,
    handleClose,
    confirming
}) {
    const { t } = useTranslation()

    const [data, setData] = useState([])

    useEffect(() => {
        setData([
            {
                title: t('Total sales'),
                amount: withdrawal.total_payments,
                currency: withdrawal.currency
            },
            {
                title: t('Settlement fee'),
                amount: withdrawal.settlement_cost * -1,
                currency: withdrawal.currency
            },
            {
                title: t('Chargebacks'),
                amount: withdrawal.chargebacks_fee * -1,
                currency: withdrawal.currency
            },
            {
                title: t('Refunds'),
                amount: withdrawal.refunds_fees * -1,
                currency: withdrawal.currency
            },
            {
                title: t('Cashouts'),
                amount: withdrawal.cashouts * -1,
                currency: withdrawal.currency
            },
            {
                title: t('Other charges'),
                amount: withdrawal.other_charges * -1,
                currency: withdrawal.currency
            },
            {
                title: t('Other credits'),
                amount: withdrawal.other_credits,
                currency: withdrawal.currency
            },
        ])
    }, [withdrawal, t])

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography variant="h6" component="h2">
                    {t('Confirm pending payout')}
                </Typography>

                <Grid container spacing={1} mt={4}>
                    {data.map((d, idx) => (
                        d.amount !== 0 ? <React.Fragment key={idx}>
                            <Grid item sm={12} md={4} textAlign='right'>
                                {d.title}:
                            </Grid>
                            <Grid item sm={12} md={4} textAlign='right'>
                                <CurrencyValue
                                    amount={d.amount}
                                    currency={d.currency}
                                />
                            </Grid>

                            <Box width="100%" />
                        </ React.Fragment> : ''
                    ))}

                    <Box width="100%" sx={{
                        border: '1px solid #D5D5D5',
                        margin: '1rem auto'
                    }} />

                    <Grid item sm={12} md={4} textAlign='right'>
                        {t('Total to pay')}:
                    </Grid>
                    <Grid item sm={12} md={4} textAlign='right'>
                        <CurrencyValue
                            amount={withdrawal.withdrawable_amount}
                            currency={withdrawal.currency}
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={12} mt={2}>
                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            <ButtonWrapper
                                onClick={() => handleConfirm(withdrawal.id)}
                                disabled={confirming}
                            >{t('Confirm payout')}</ButtonWrapper>

                            <ButtonWrapper
                                variant="text"
                                onClick={handleClose}
                                startIcon={<CloseIcon />}>{t('Cancel')}</ButtonWrapper>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}
