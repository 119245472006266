import { useEffect, useCallback } from 'react'
import CountryForm from './CountryForm'
import { Card, CardContent } from '@mui/material'
import { useState } from 'react'
import FormSkeletonWrapper from '../../../SkeletonUI/FormSkeletonWrapper/FormSkeletonWrapper'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import PageTitle from '../../../Misc/PageTitle'
import { replaceNullValues, parseErrors } from '../../../../services/utils'
import { toast } from 'react-toastify'
import useIsMounted from '../../../../hooks/useIsMounted';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'


export default function CountryEdit() {
    const { t } = useTranslation()

    const navigate = useNavigate()

    const isMounted = useIsMounted()

    const api = useAxiosPrivate()

    const { countryId } = useParams()

    const [loading, setLoading] = useState(true)

    const [data, setData] = useState()

    const handleCancel = () => {
        navigate('/settings/countries')
    }

    const handleSubmit = (values, { setFieldError }) => {
        values.vat = values.vat / 100

        api.put(`/internal/countries/${countryId}/`, values)
            .then(() => {
                if (isMounted()) {
                    toast.success(t('Country has been updated'))
                    navigate('/settings/countries')
                }
            })
            .catch(({ response }) => {
                if (isMounted()) {
                    const errors = parseErrors(response)

                    if (typeof errors === 'object') {
                        for (let i in errors) {
                            setFieldError(errors[i].field, t(errors[i].message), false)
                        }
                    } else if (typeof errors === 'string') {
                        toast.error(t(errors))
                    } else {
                        toast.error(t('Something went wrong'))
                    }
                }
            })
    }

    const retrieveData = useCallback(() => {
        api.get(`/internal/countries/${countryId}/`)
            .then(({ data: { data } }) => {
                if (isMounted()) {
                    data.vat = data.vat * 100
                    setData(replaceNullValues(data))
                }
            })
            .catch(() => isMounted() && toast.error(t('something went wrong!')))
            .finally(() => isMounted() && setLoading(false))
    }, [countryId, t, api, isMounted])

    const FORM_VALIDATION = Yup.object().shape({
        name: Yup.string()
            .required(t('This field is required')),
        code: Yup.string()
            .required(t('This field is required')),
        currency: Yup.string()
            .required(t('This field is required')),
        region: Yup.string()
            .required(t('This field is required')),
        subregion: Yup.string()
            .required(t('This field is required')),
        vat: Yup.number()
            .when('apply_vat', {
                is: true,
                then: Yup.number()
                    .min(1, t('This field must be greater than {{min}}', { min: 0 }))
                    .required("Must enter email address")
            }),
        digital_vat: Yup.number()
            .when('apply_digital_vat', {
                is: true,
                then: Yup.number()
                    .min(1, t('This field must be greater than {{min}}', { min: 0 }))
                    .required("Must enter email address")
            })
    })

    useEffect(() => {
        const init = () => retrieveData()

        init()
    }, [retrieveData])

    return (
        <>
            <Card>
                <CardContent>
                    <PageTitle title={t('Update country information')} />

                    {
                        loading ? <FormSkeletonWrapper /> :
                            <CountryForm
                                isNew={false}
                                handleSubmit={handleSubmit}
                                handleCancel={handleCancel}
                                initialFormState={data}
                                FORM_VALIDATION={FORM_VALIDATION}
                            />
                    }
                </CardContent>
            </Card>
        </>
    )
}
