import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useTranslation } from 'react-i18next'
import TableActions from '../../../TableUI/TableActions'
import EditIcon from '@mui/icons-material/Edit'
import { useNavigate } from 'react-router-dom'
import Pagination from '../../../Pagination/Pagination'
import TableBackdrop from '../../../TableUI/TableBackdrop'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'


export default function MethodsTable({
    data,
    pages = 1,
    page = 1,
    loading = false,
    handleOnPageChange
}) {
    const { t } = useTranslation()

    const navigate = useNavigate()

    return (
        <>
            <Pagination
                handleOnPageChange={handleOnPageChange}
                page={page}
                pages={pages}
            />

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('Name')}</TableCell>
                            <TableCell>{t('Code')}</TableCell>
                            <TableCell>{t('Currency')}</TableCell>
                            <TableCell>{t('Region')}</TableCell>
                            <TableCell>{t('Subregion')}</TableCell>
                            <TableCell align="center">{t('Apply VAT')}</TableCell>
                            <TableCell align="center">{t('VAT')}</TableCell>
                            <TableCell align="center">{t('Apply digital VAT')}</TableCell>
                            <TableCell align="center">{t('Digital VAT')}</TableCell>
                            <TableCell align="right">&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading && data.length ? data.map((row) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">{row.name}</TableCell>
                                <TableCell>{row.code}</TableCell>
                                <TableCell>{row.currency}</TableCell>
                                <TableCell>{row.region}</TableCell>
                                <TableCell>{row.subregion}</TableCell>
                                <TableCell align="center">
                                    {(row.apply_vat ? <CheckIcon /> : <ClearIcon />)}
                                </TableCell>
                                <TableCell align="center">{row.vat}</TableCell>
                                <TableCell align="center">{(row.apply_digital_vat ? <CheckIcon /> : <ClearIcon />)}</TableCell>
                                <TableCell align="center">{row.digital_vat}</TableCell>
                                <TableCell align="right">
                                    <TableActions actions={[
                                        {
                                            id: `edit-${row.id}`,
                                            handleClick: () => { navigate(`${row.id}/edit`) },
                                            icon: <EditIcon />,
                                            title: t('Edit')
                                        }
                                    ]} />
                                </TableCell>
                            </TableRow>
                        )) : <TableRow>
                            {
                                loading ? <TableBackdrop open={loading} /> : <TableCell sx={{ textAlign: 'center' }} colSpan={100}>{t('No records found')}</TableCell>
                            }
                        </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <Pagination
                handleOnPageChange={handleOnPageChange}
                page={page}
                pages={pages}
            />
        </>
    )
}
