import { Box, Grid, Stack } from "@mui/material"
import { Form, Formik } from "formik"
import { useTranslation } from 'react-i18next'

import ButtonWrapper from "../../FormUI/ButtonWrapper/ButtonWrapper"
import SubmitWrapper from "../../FormUI/SubmitWrapper/SubmitWrapper"

import TextfieldWrapper from "../../FormUI/Textfield/TextfieldWrapper"
import SearchIcon from '@mui/icons-material/Search'
import UndoIcon from '@mui/icons-material/Undo'
import DateTimePickerWrapper from "../../FormUI/DateTimePickerWrapper/DateTimePickerWrapper"
import { getFormatedDate } from "../../../services/utils"

export default function Filters({
    handleOnSearch
}) {
    const { t } = useTranslation()

    const today = new Date()

    const initialFormState = {
        invoice_code__icontains: '',
        merchant__username__icontains: '',
        paid_at__date__gte: '',
        paid_at__date__lte: ''
    }

    return (
        <Formik
            initialValues={{
                ...initialFormState
            }}
            onSubmit={handleOnSearch}
        >
            {({ handleReset, handleSubmit }) => (<Form>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={2}>
                        <TextfieldWrapper
                            name="merchant__username__icontains"
                            label={t('Merchant')}
                            type="text"
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextfieldWrapper
                            name="invoice_code__icontains"
                            label={t('Invoice Nº')}
                            type="text"
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <DateTimePickerWrapper
                            name="paid_at__date__gte"
                            label={t('From date')}
                            size="small"
                            inputProps={{
                                max: getFormatedDate(today)
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <DateTimePickerWrapper
                            name="paid_at__date__lte"
                            label={t('To date')}
                            size="small"
                            inputProps={{
                                max: getFormatedDate(today)
                            }}
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            <SubmitWrapper startIcon={<SearchIcon />}>{t('Search')}</SubmitWrapper>
                            <ButtonWrapper
                                onClick={() => {
                                    handleReset()
                                    handleSubmit()
                                }}
                                variant="text"
                                startIcon={<UndoIcon />}>{t('Reset')}</ButtonWrapper>
                        </Stack>
                    </Grid>
                </Grid>
            </Form>)}
        </Formik>
    )
}
