import { useState, useEffect, useCallback } from 'react'

import { Backdrop, Badge, Card, CardContent, Chip, CircularProgress, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import PageTitle from '../../../Misc/PageTitle'
import { toast } from 'react-toastify'
import useIsMounted from '../../../../hooks/useIsMounted'
import { STATUS_CREATED, STATUS_FAILED, STATUS_IN_PROCESS, STATUS_PAID } from '../../../../enums/Cashouts'
import ToolsStack from '../../../Misc/ToolsStack'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import useAxiosCashouts from '../../../../hooks/useAxiosCashouts'
import GroupedCashoutsTable from './GroupedCashoutsTable'
import ActiveCashoutsTable from './ActiveCashoutsTable'
import CashoutFailed from '../CashoutFailed'
import { CASHOUTS__PROCESS } from '../../../../enums/Caps'
import useHasPermissions from '../../../../hooks/useHasPermissions'


export default function Active() {
  const { t } = useTranslation()

  const hasPermissions = useHasPermissions()

  const isMounted = useIsMounted()

  const axiosCashouts = useAxiosCashouts()

  const navigate = useNavigate()

  const params = {
    status: STATUS_IN_PROCESS,
    limit: 200
  }

  const [data, setData] = useState([])

  const [selected, setSelected] = useState()

  const [currentData, setCurrentData] = useState()

  const [stats, setStats] = useState({
    pending: 0,
    running: 0
  })

  const [working, setWorking] = useState(false)

  const [loading, setLoading] = useState(true)

  const handleConfirm = (cashout) => {
    setWorking(true)

    return axiosCashouts.post('/cashouts/confirm', cashout)
      .then(() => {
        const newData = data.map(d => {
          d.data.map(c => {
            if (
              (cashout.group_id && c.group_id === cashout.group_id) ||
              (cashout.transaction_id && c.id === cashout.transaction_id)
            ) {
              c.status = STATUS_PAID
            }
            return d
          })

          return d
        })

        setData(newData)
        toast.success(t('Cashouts has been confirmed'))
      })
      .catch(e => toast.error(t('Something went wrong')))
      .finally(() => setWorking(false))
  }

  const handleCancel = (cashout) => {
    setWorking(true)

    return axiosCashouts.post('/cashouts/cancel', cashout)
      .then(() => {
        const newData = data.filter(d => cashout.group_id ? d.group_id !== cashout.group_id : true)
          .map(d => {
            d.data.map(c => {
              if (c.id === cashout.transaction_id) {
                c.status = STATUS_CREATED
              }

              return c
            })

            return d
          })

        setData(newData)
        toast.success(t('Cashouts has been canceled'))
      })
      .catch(e => toast.error(t('Something went wrong')))
      .finally(() => setWorking(false))
  }

  const handleFailedForm = (cashout) => {
    setSelected(cashout)

    return new Promise((resolve) => resolve())
  }

  const handleCloseForm = () => {
    setSelected(null)
  }

  const handleFailed = (cashout) => {
    setWorking(true)

    return axiosCashouts.post('/cashouts/fail', cashout)
      .then(() => {
        const newData = data.map(d => {
          d.data.map(c => {
            if (c.id === cashout.transaction_id) {
              c.status = STATUS_FAILED
            }

            return c
          })

          return d
        })

        setData(newData)
        toast.success(t('Cashouts has been marked as failed'))
      })
      .catch(e => toast.error(t('Something went wrong')))
      .finally(() => {
        setSelected(null)
        setWorking(false)
      })
  }

  const handleDownload = (group_id) => {
    let anchor = document.createElement('a')

    anchor.href = new window.URL(`https://payouts.paygol.com/file/${group_id}`)
    anchor.target = '_blank'
    anchor.rel = 'noopener'

    anchor.click()

    // Return a promise to close menu 
    return new Promise((resolve, reject) => resolve())
  }

  const retrieveData = useCallback(() => {
    setData([])

    axios.all([
      axiosCashouts.get('/cashouts', { params: params }),
      axiosCashouts.get('/cashouts/stats')
    ])
      .then(axios.spread((...responses) => ({
        data: responses[0].data,
        stats: responses[1].data,
      })))
      .then(({ data: { data, current_page, last_page }, stats }) => {
        if (isMounted()) {
          const groups = data.map(d => d.group_id)
          const newData = groups
            .filter((d, idx) => groups.indexOf(d) === idx)
            .map(g => (
              {
                group_id: g,
                data: data.filter(d => d.group_id === g),
                updated_at: data.filter(d => d.group_id === g)[0].updated_at,
                total: data.filter(d => d.group_id === g).reduce((a, b) => a + parseInt(b.amount), 0),
                currency: data.filter(d => d.group_id === g)[0].currency
              }
            ))

          setData(newData)
          setStats(stats)
        }
      })
      .catch(() => {
        toast.error(t('Something went wrong'))
      })
      .finally(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, isMounted, axiosCashouts])

  useEffect(() => {
    const loadData = () => {
      retrieveData()
    }

    loadData()
  }, [retrieveData])

  return (
    <Card>
      <CardContent>
        <PageTitle title={t('Active Cashouts')} />

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={working}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <ToolsStack justifyContent="end">
          <Stack direction="row" spacing={3}>
            {currentData && (<Chip
              label={currentData.group_id}
              variant="outlined"
              color="info"
              onDelete={() => setCurrentData(null)}
            />)}

            <Badge badgeContent={stats.running} color="success">
              <Chip
                label="In process"
                variant="outlined"
                color="success"
              />
            </Badge>

            <Badge badgeContent={stats.pending} color="info">
              <Chip
                label="Pending"
                variant="outlined"
                color="info"
                onClick={() => navigate('/cashouts/pending')}
              />
            </Badge>
          </Stack>
        </ToolsStack>

        {!currentData && (<GroupedCashoutsTable
          data={data}
          loading={loading}
          working={working}
          isPending={true}
          setCurrentData={setCurrentData}
          handleConfirm={handleConfirm}
          handleCancel={handleCancel}
          handleDownload={handleDownload}
          hasPermissions={hasPermissions(CASHOUTS__PROCESS)}
        />)}

        {currentData && (<ActiveCashoutsTable
          data={currentData}
          working={working}
          handleConfirm={handleConfirm}
          handleCancel={handleCancel}
          handleFailedForm={handleFailedForm}
          hasPermissions={hasPermissions(CASHOUTS__PROCESS)}
        />)}

        {selected && (<CashoutFailed
          open={!!selected}
          cashout={selected}
          handleClose={handleCloseForm}
          handleConfirm={handleFailed}
          confirming={working}
          hasPermissions={hasPermissions(CASHOUTS__PROCESS)}
        />)}
      </CardContent>
    </Card>
  )
}
