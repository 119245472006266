import { Box, Grid, Stack } from "@mui/material"
import { Form, Formik } from "formik"
import { useTranslation } from 'react-i18next'

import ButtonWrapper from "../../FormUI/ButtonWrapper/ButtonWrapper"
import SubmitWrapper from "../../FormUI/SubmitWrapper/SubmitWrapper"

import SelectWrapper from "../../FormUI/SelectWrapper/SelectWrapper"
import TextfieldWrapper from "../../FormUI/Textfield/TextfieldWrapper"
import SearchIcon from '@mui/icons-material/Search'
import UndoIcon from '@mui/icons-material/Undo'
import { payouts_methods, status } from "../../../enums/Merchants"

export default function Filters({
    handleOnSearch,
    loading = false
}) {
    const { t } = useTranslation();

    const initialFormState = {
        merchant__username__icontains: '',
        merchant__status: '1',
        merchant__account_config__payout_method: ''
    }

    return (
        <Formik
            initialValues={{
                ...initialFormState
            }}
            onSubmit={handleOnSearch}
        >
            {({ handleReset, handleSubmit }) => (<Form>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={2}>
                        <TextfieldWrapper
                            name="merchant__username__icontains"
                            label={t('Merchant')}
                            type="text"
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <SelectWrapper
                            name="merchant__status"
                            label={t('Status')}
                            options={{
                                ...status, 'all': 'all'
                            }}
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <SelectWrapper
                            name="merchant__account_config__payout_method"
                            label={t('Payout method')}
                            options={payouts_methods}
                            size="small"
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            <SubmitWrapper startIcon={<SearchIcon />} disabled={loading}>{t('Search')}</SubmitWrapper>
                            <ButtonWrapper
                                onClick={() => {
                                    handleReset();
                                    handleSubmit();
                                }}
                                variant="text"
                                disabled={loading}
                                startIcon={<UndoIcon />}>{t('Reset')}</ButtonWrapper>
                        </Stack>
                    </Grid>
                </Grid>
            </Form>)}
        </Formik >
    )
}
