import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useTranslation } from 'react-i18next'
import DeleteIcon from '@mui/icons-material/Delete'
import TableActions from '../../../TableUI/TableActions'
import TableBackdrop from '../../../TableUI/TableBackdrop'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import IconButton from '@mui/material/IconButton'
import DatetimeValue from '../../../TableUI/DatetimeValue'
import { PAYMENT_METHODS__FEES } from '../../../../enums/Caps'
import useHasPermissions from '../../../../hooks/useHasPermissions'


export default function MethodFeesTable({
    data,
    loading = false,
    handleDelete
}) {
    const { t } = useTranslation()

    const hasPermissions = useHasPermissions()

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('Fee fixed')}</TableCell>
                            <TableCell>{t('Currency')}</TableCell>
                            <TableCell>{t('Fee percent')}</TableCell>
                            <TableCell align="center">{t('Waiting time')}</TableCell>
                            <TableCell align="center">{t('Created at')}</TableCell>
                            {hasPermissions(PAYMENT_METHODS__FEES) && (<TableCell align="right">&nbsp;</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading && data.length ? data.map((row, idx) => (
                            <TableRow
                                key={idx}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{row.fee_fixed}</TableCell>
                                <TableCell>{row.fee_fixed_currency}</TableCell>
                                <TableCell>{row.fee_percent}</TableCell>
                                <TableCell align="center">{row.waiting_time}</TableCell>
                                <TableCell align="center">
                                    <DatetimeValue date={row.created_at} />
                                </TableCell>
                                {hasPermissions(PAYMENT_METHODS__FEES) && (<TableCell align="right">
                                    {idx === 0 ? <TableActions actions={[
                                        {
                                            id: `delete-${row.id}`,
                                            handleClick: () => { handleDelete(row.id) },
                                            icon: <DeleteIcon />,
                                            title: t('Delete')
                                        }
                                    ]} /> : <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls='long-menu'
                                        aria-expanded={false}
                                        aria-haspopup="true"
                                        disabled
                                    >
                                        <MoreVertIcon />
                                    </IconButton>}
                                </TableCell>)}
                            </TableRow>
                        )) : <TableRow>
                            {
                                loading ? <TableBackdrop open={loading} /> : <TableCell sx={{ textAlign: 'center' }} colSpan={100}>{t('No records found')}</TableCell>
                            }
                        </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}
