import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useTranslation } from 'react-i18next'

import TableBackdrop from '../../../TableUI/TableBackdrop'
import { Chip, Typography } from '@mui/material'
import CurrencyValue from '../../../TableUI/CurrencyValue'


const styles = () => ({
    root: {
        width: '100%',
        overflowX: "auto",
    }, table: {
        minWidth: 650,
    }
})

export default function FileUploadTable({
    data,
    loading = false
}) {
    const { t } = useTranslation()

    return (
        <>
            <TableContainer className={styles.root}>
                <Table className={styles.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('Nº')}</TableCell>
                            <TableCell>{t('ID')}</TableCell>
                            <TableCell>{t('Full name')}</TableCell>
                            <TableCell>{t('Document number')}</TableCell>
                            <TableCell align="right">{t('Amount')}</TableCell>
                            <TableCell align="right">{t('Amount (Bank)')}</TableCell>
                            <TableCell align="center">{t('Status')}</TableCell>
                            <TableCell>&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading && data.length ? data.map((row, idx) => (
                            <TableRow
                                key={idx}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <Typography>{idx + 1}</Typography>
                                </TableCell>
                                <TableCell>{row.str_id}</TableCell>
                                <TableCell>{row.beneficiary?.full_name}</TableCell>
                                <TableCell>{row.beneficiary?.document_number}</TableCell>
                                <TableCell align="right">
                                    {String(row.currency).length === 3 && (<CurrencyValue amount={row.amount_local} currency={row.currency} />)}
                                </TableCell>
                                <TableCell align="right">
                                    {String(row.currency).length === 3 && (<CurrencyValue amount={row.response?.amount} currency={row.currency} />)}
                                </TableCell>
                                <TableCell align="center">
                                    {row.response?.status === 'Pagado' && (<Chip label={row.response?.status.toUpperCase()} color="success" variant="outlined" />)}
                                    {row.response?.status === 'Rechazado' && (<Chip label={row.response?.status.toUpperCase()} color="error" variant="outlined" />)}
                                </TableCell>
                                <TableCell>
                                    {row.response?.status === 'Rechazado' && (
                                        <Typography>{row.response?.error}</Typography>
                                    )}

                                    {!row?.id && (
                                        <Typography>NO SE HA ENCONTRADO REGISTRO</Typography>
                                    )}
                                </TableCell>
                            </TableRow>
                        )) : <TableRow>
                            {
                                loading ? <TableBackdrop open={loading} /> : <TableCell sx={{ textAlign: 'center' }} colSpan={100}>{t('No records found')}</TableCell>
                            }
                        </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}
