import { GenericList } from 'components/Common/GenericList'

import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import useAxiosPrivate from 'hooks/useAxiosPrivate'


export default function MethodsList() {
    const { t } = useTranslation()

    const { methodId, submethodId } = useParams()

    const api = useAxiosPrivate()

    const [methodData, setMethodData] = useState(null)
    useEffect(() => {
        api.get(`/internal/payment-methods/${methodId}/`).then((response) => {
            setMethodData(response.data.data)
        }
        )
    }, [methodId, api])

    const [subMethodData, setSubMethodData] = useState(null)
    useEffect(() => {
        api.get(`/internal/payment-methods/${methodId}/submethods/${submethodId}/`).then((response) => {
            setSubMethodData(response.data.data)
        }
        )
    }, [submethodId, methodId, api])

    return <GenericList
        url={`/internal/payment-methods/${methodId}/submethods/${submethodId}/fees/`}
        title={
            (methodData === null ? '' : `${methodData.name} (${methodData.method_provider.name}) / `)
            + (subMethodData === null ? '' : `${subMethodData.name} / `)
            + t("Fees")}
        editableFields={new Set(["name"])}
        hiddenFields={new Set(["id"])}
    />
}
