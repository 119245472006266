import { Navigate, Outlet } from "react-router-dom"
import useAuth from "../hooks/useAuth"
import useHasPermissions from "../hooks/useHasPermissions"

export default function RestrictedRouter({
    allowCaps
}) {
    const { auth } = useAuth()

    const hasPermissions = useHasPermissions()

    return (
        hasPermissions(allowCaps) ? <Outlet /> : auth?.user
            ? <Navigate to="/" />
            : <Navigate to="/auth" />
    )
}
