import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export default function CashoutDelete({
    open = false,
    handleClose,
    handleConfirm,
    confirming,
    cashout
}) {
    const { t } = useTranslation()

    const [confirmText, setConfirmText] = useState()

    const [randomText, setRandomText] = useState('elimjjjjinar')
    // const randomText = 'eliminar'

    const handleChange = (event) => {
        setConfirmText(event.target.value)
    }

    const handleRemove = () => {
        if (confirmText !== randomText) {
            toast.info(t('Please complete the required fields'))
            return
        }

        setConfirmText(null)
        handleConfirm()
    }

    useEffect(() => {
        const word = cashout.str_payout_id.substring((cashout.str_payout_id.length - 4), cashout.str_payout_id.length)
        setRandomText(word)
    }, [cashout])

    return (
        <Dialog
            open={open}
            onClose={() => handleClose('delete')}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                {t('Delete Cashout')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText component='div'>
                    <Trans i18nKey='deletionLegendCashouts'>
                        By confirming the deletion, this Cashout will be released and the merchant will not be notified
                    </Trans>

                    <Typography component='div' mt={2}>Cashout ID: {cashout.str_payout_id}</Typography>
                    <Typography component='div'>Merchant ID: {cashout.merchant_payout_id}</Typography>
                </DialogContentText>

                <TextField
                    name='confirmText'
                    onChange={handleChange}
                    fullWidth
                    sx={{
                        mt: 2,
                        mb: 1
                    }}
                />

                <Typography variant='subtitle2'>
                    <Trans
                        i18nKey="confirmDeletion"
                        defaults='To confirm the deletion, type <strong>{{word}}</strong> in the text input field'
                        values={{
                            word: randomText
                        }}
                    />
                </Typography>

            </DialogContent>
            <DialogActions sx={{
                p: 3
            }}>
                <Button autoFocus onClick={() => handleClose('delete')}>{t('Cancel')}</Button>
                <Button
                    variant="contained"
                    color="error"
                    onClick={handleRemove}
                    disabled={confirming}
                >{t('Delete Cashout')}</Button>
            </DialogActions>
        </Dialog>
    )
}
