import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useTranslation } from 'react-i18next'
import TableBackdrop from '../../../TableUI/TableBackdrop'
import useHasPermissions from '../../../../hooks/useHasPermissions'
import { Checkbox, FormControlLabel, FormGroup, Tooltip } from '@mui/material'
import { USERS__CREATE, USERS__UPDATE, USERS__PERMISSIONS } from '../../../../enums/Caps'

export default function PermissionsTable({
    data,
    loading,
    selected,
    setSelected
}) {
    const { t } = useTranslation()

    const hasPermissions = useHasPermissions()

    const handleChange = (e) => {
        const { checked, value } = e.target

        if (checked) {
            setSelected([
                ...selected, value
            ])
        } else {
            setSelected(selected.filter(c => c !== value))
        }
    }

    const checkAll = (e) => {
        const { checked } = e.target

        if (checked) {
            setSelected(data.map(r => r.permission))
        } else {
            setSelected([])
        }
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                    <TableHead>
                        <TableRow sx={{
                            background: '#F8F9F9'
                        }}>
                            <TableCell>
                                <FormGroup>
                                    <Tooltip title={t('Select all permissions')}>
                                        <FormControlLabel
                                            control={<Checkbox
                                                onChange={checkAll}
                                                indeterminate={selected.length > 0 && selected.length !== data.length}
                                                checked={selected.length > 0 && selected.length === data.length}
                                                disabled={!hasPermissions(USERS__PERMISSIONS)}
                                            />}
                                        />
                                    </Tooltip>
                                </FormGroup>
                            </TableCell>
                            <TableCell>{t('Description')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.length ? data.map((row) => (
                            <TableRow
                                key={row.permission}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                {hasPermissions([USERS__CREATE, USERS__UPDATE]) && (<TableCell align="center">
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox
                                                value={row.permission}
                                                checked={selected.includes(row.permission)}
                                                onChange={handleChange}
                                                disabled={!hasPermissions(USERS__PERMISSIONS)}
                                            />}
                                        />
                                    </FormGroup>
                                </TableCell>)}
                                <TableCell component="th" scope="row">
                                    {row.description}
                                </TableCell>
                            </TableRow>
                        )) : <TableRow>
                            {
                                loading ? <TableBackdrop open={loading} /> : <TableCell sx={{ textAlign: 'center' }} colSpan={100}>{t('No records found')}</TableCell>
                            }
                        </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}
