import { Box, Grid, Stack } from "@mui/material"
import { Form, Formik } from "formik"
import { useTranslation } from 'react-i18next'

import ButtonWrapper from "../../../FormUI/ButtonWrapper/ButtonWrapper"
import SubmitWrapper from "../../../FormUI/SubmitWrapper/SubmitWrapper"

import SearchIcon from '@mui/icons-material/Search'
import UndoIcon from '@mui/icons-material/Undo'
import TextfieldWrapper from '../../../FormUI/Textfield/TextfieldWrapper';

export default function Filters({
    handleOnSearch
}) {
    const { t } = useTranslation()

    const initialFormState = {
        first_name__icontains: '',
        last_name__icontains: '',
        email__icontains: ''
    }

    return (
        <Formik
            initialValues={{
                ...initialFormState
            }}
            onSubmit={handleOnSearch}
        >
            {({ handleReset, handleSubmit }) => (<Form>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <TextfieldWrapper
                            name="first_name__icontains"
                            label={t('First name')}
                            type="text"
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextfieldWrapper
                            name="last_name__icontains"
                            label={t('Last name')}
                            type="text"
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextfieldWrapper
                            name="email__icontains"
                            label={t('Email')}
                            type="text"
                            size="small"
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            <SubmitWrapper startIcon={<SearchIcon />}>{t('Search')}</SubmitWrapper>
                            <ButtonWrapper
                                onClick={() => {
                                    handleReset()
                                    handleSubmit()
                                }}
                                variant="text"
                                startIcon={<UndoIcon />}>{t('Reset')}</ButtonWrapper>
                        </Stack>
                    </Grid>
                </Grid>
            </Form>)}
        </Formik>
    )
}
