import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useTranslation } from 'react-i18next'
import Pagination from '../../Pagination/Pagination'
import TableBackdrop from '../../TableUI/TableBackdrop'
import CurrencyValue from '../../TableUI/CurrencyValue'
import { payouts_methods } from '../../../enums/Merchants'

const styles = () => ({
    root: {
        width: '100%',
        overflowX: "auto",
    }, table: {
        minWidth: 650,
    }
})

export default function ToPayPayoutsTable({
    data,
    pages = 1,
    page = 1,
    loading = false,
    handleOnPageChange,
}) {
    const { t } = useTranslation()

    return (
        <>
            <TableContainer className={styles.root}>
                <Table className={styles.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align='center'>#</TableCell>
                            <TableCell>{t('Merchant')}</TableCell>
                            <TableCell>{t('Payout method')}</TableCell>
                            <TableCell align="right">{t('Credits')}</TableCell>
                            <TableCell align="right">{t('Fees')}</TableCell>
                            <TableCell align="right">{t('Total')}</TableCell>
                            <TableCell align="right">{t('Withdrawable')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading && data.length ? data.map((row, idx) => (
                            <TableRow
                                key={idx}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row" align="center">{idx + 1}</TableCell>
                                <TableCell>{row.merchant__username}</TableCell>
                                <TableCell>
                                    {payouts_methods[row.merchant__account_config__payout_method]}
                                </TableCell>
                                <TableCell align="right">
                                    {row.credits && (<CurrencyValue amount={(row.credits)} currency={row.account_currency} />)}
                                </TableCell>
                                <TableCell align="right">
                                    {row.fees && (<CurrencyValue amount={row.fees} currency={row.account_currency} />)}
                                </TableCell>
                                <TableCell align="right">
                                    <CurrencyValue amount={row.total || 0} currency={row.account_currency} />
                                </TableCell>
                                <TableCell align="right">
                                    <CurrencyValue amount={row.available || 0} currency={row.account_currency} />
                                </TableCell>
                            </TableRow>
                        )) : <TableRow>
                            {
                                loading ? <TableBackdrop open={loading} /> : <TableCell sx={{ textAlign: 'center' }} colSpan={100}>{t('No records found')}</TableCell>
                            }
                        </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <Pagination
                handleOnPageChange={handleOnPageChange}
                page={page}
                pages={pages}
            />
        </>
    )
}
