import { Typography } from '@mui/material'

export default function DatetimeValue({
    date,
    text = ''
}) {
    return (
        <Typography noWrap>
            {text} {date && new Intl.DateTimeFormat('es-CL', { dateStyle: 'medium', timeStyle: 'short' }).format(new Date(date))}
        </Typography>
    )
}
