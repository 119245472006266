import { useState, useEffect, useCallback } from 'react'
import { Grid, Hidden } from '@mui/material'
import { Link, useNavigate, useParams } from 'react-router-dom'

import AddIcon from '@mui/icons-material/Add'
import { Card, CardContent } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Filters from './Filters'
import AppFilters from '../../../AppFilters'
import BankAccountsTable from './BankAccountsTable'
import PageTitle from '../../../Misc/PageTitle'
import { toast } from 'react-toastify'
import ButtonWrapper from '../../../FormUI/ButtonWrapper/ButtonWrapper'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Stack } from '@mui/material';
import MerchantMenu from '../MerchantMenu'
import MerchantAlias from '../MerchantAlias'
import MerchantSubmenu from '../MerchantSubmenu'
import useIsMounted from '../../../../hooks/useIsMounted';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'
import { BANK_ACCOUNTS__CREATE } from '../../../../enums/Caps'
import useHasPermissions from '../../../../hooks/useHasPermissions'


export default function BankAccountsList() {
    const { t } = useTranslation()

    const hasPermissions = useHasPermissions()

    const navigate = useNavigate()

    const isMounted = useIsMounted()

    const api = useAxiosPrivate()

    const { userId } = useParams()

    const [params, setParams] = useState({})

    const [loading, setLoading] = useState(true)

    const [data, setData] = useState([])

    const [meta, setMeta] = useState({
        total_pages: 1,
        current_page: 1
    })

    const handleOnSearch = values => {
        setParams(values)
    }

    const handleOnPageChange = (e, page) => {
        setMeta({ ...meta, current_page: page })
        setParams({ ...params, page: page })
    }

    const retrieveData = useCallback(() => {
        setData([])

        api.get(`/internal/merchants/${userId}/bank-accounts/`, { params: params })
            .then(({ data: { meta, data } }) => {
                if (isMounted()) {
                    setMeta(meta)
                    setData(data)
                }
            })
            .catch(() => isMounted() && toast.error(t('Something went wrong')))
            .finally(() => isMounted() && setLoading(false))
    }, [params, userId, t, isMounted, api])

    useEffect(() => {
        const init = () => {
            setLoading(true)
            retrieveData()
        }

        init()
    }, [params, retrieveData])

    return (
        <>

            <Card>
                <CardContent>
                    <Stack direction="row" justifyContent="space-between" spacing={2}>
                        <PageTitle title={t('Merchant bank accounts')} />

                        <Hidden xlUp={true}>
                            {userId ? <MerchantMenu id={userId} /> : ''}
                        </Hidden>
                    </Stack>

                    <MerchantAlias />

                    <Grid container spacing={2} mt={2} mb={2}>
                        <Grid item xs={6}>
                            {hasPermissions(BANK_ACCOUNTS__CREATE) && (<ButtonWrapper
                                variant="contained"
                                component={Link}
                                to="create"
                                size="small"
                                startIcon={<AddIcon />}>{t('New')}</ButtonWrapper>)}
                        </Grid>

                        <Grid item xs={6} sx={{
                            textAlign: 'right'
                        }}>
                            <ButtonWrapper
                                variant="text"
                                size="small"
                                onClick={() => navigate('/merchants')}
                                startIcon={<ArrowBackIcon />}>{t('Go back')}</ButtonWrapper>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12} xl={2}>
                            <MerchantSubmenu current='bank-accounts' id={userId} />
                        </Grid>
                        <Grid item xs={12} xl={10}>
                            <AppFilters Filters={<Filters handleOnSearch={handleOnSearch} />} />

                            <BankAccountsTable
                                data={data}
                                pages={meta.total_pages}
                                page={meta.current_page}
                                loading={loading}
                                handleOnPageChange={handleOnPageChange}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}
