import { useEffect, useState } from 'react'
import { Typography } from '@mui/material'

export default function MerchantAlias() {
    const [stored_merchant, setStoredMerchant] = useState('')

    useEffect(() => {
        if (localStorage.getItem('merchant')) {
            setStoredMerchant(JSON.parse(localStorage.getItem('merchant')))
        }
    }, [])

    return (
        <Typography
            variant='h4'
            sx={{
                textAlign: 'center',
                m: 4
            }}>{stored_merchant?.username}</Typography>
    )
}
