import { Formik, Form } from 'formik'
import { Grid, Box, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import TextfieldWrapper from '../../../FormUI/Textfield/TextfieldWrapper'
import SubmitWrapper from '../../../FormUI/SubmitWrapper/SubmitWrapper'
import ButtonWrapper from '../../../FormUI/ButtonWrapper/ButtonWrapper'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import SelectWrapper from '../../../FormUI/SelectWrapper/SelectWrapper'
import { CONTACT_COMMERCIAL, CONTACT_OPERATIONAL, CONTACT_CUSTOMER_SERVICE } from './../../../../enums/Merchants'


export default function ContactsForm({
    isNew,
    initialFormState,
    FORM_VALIDATION,
    handleSubmit,
    merchant_id,
    hasPermissions
}) {
    const { t } = useTranslation()

    const navigate = useNavigate()

    return (
        <Formik
            initialValues={{
                ...initialFormState
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={handleSubmit}
        >
            <Form>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={5}>
                        <SelectWrapper
                            name="title"
                            label={t('Title')}
                            options={{
                                [CONTACT_COMMERCIAL]: CONTACT_COMMERCIAL,
                                [CONTACT_OPERATIONAL]: CONTACT_OPERATIONAL,
                                [CONTACT_CUSTOMER_SERVICE]: CONTACT_CUSTOMER_SERVICE,
                            }}
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={12} md={6}>
                        <TextfieldWrapper
                            name="first_name"
                            label={t('First name')}
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextfieldWrapper
                            name="last_name"
                            label={t('Last name')}
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Grid item xs={12} md={8}>
                        <TextfieldWrapper
                            name="email"
                            label={t('Email')}
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextfieldWrapper
                            name="phone"
                            label={t('Phone number')}
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2}>
                            {hasPermissions ? (<SubmitWrapper>{t('Save')}</SubmitWrapper>) : ''}
                            <ButtonWrapper
                                variant="text"
                                onClick={() => navigate(`/merchants/${merchant_id}/contacts`)}
                                startIcon={<CloseIcon />}>{t('Cancel')}</ButtonWrapper>
                        </Stack>
                    </Grid>
                </Grid>
            </Form>
        </Formik>
    )
}
