import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    TextField,
    Typography
} from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { failed_options } from '../../../enums/Cashouts'

export default function CashoutFailed({
    open = false,
    handleClose,
    handleConfirm,
    confirming,
    cashout
}) {
    const { t } = useTranslation()

    const [fields, setFields] = useState({
        details: '',
        error_code: 0
    })

    const [errors, setErrors] = useState({
        details: '',
        error_code: ''
    })

    const handleChange = (event) => {
        const { name, value } = event.target

        setFields({
            error_code: name === 'error_code' ? value : fields.error_code,
            details: name === 'error_code' ? failed_options.filter(o => o.id === value)[0].text : value
        })
    }

    const handleSubmit = () => {
        setErrors({
            details: '',
            error_code: ''
        })

        if (!fields.details) {
            setErrors({
                ...errors,
                details: t('This field is required')
            })

            return false
        }

        handleConfirm({...fields, transaction_id: cashout.id})
    }

    return (
        <Dialog
            open={open}
            onClose={() => handleClose('failed')}
            aria-labelledby="draggable-dialog-title"
            fullWidth={true}
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                {t('Failed Cashout')}
            </DialogTitle>
            <DialogContent>

                <TextField
                    value={cashout.str_payout_id}
                    fullWidth
                    disabled
                    sx={{
                        my: 1
                    }}
                />

                <TextField
                    value={cashout.merchant_payout_id}
                    fullWidth
                    disabled
                    sx={{
                        my: 1
                    }}
                />

                <TextField
                    name='error_code'
                    value={fields.error_code}
                    select={true}
                    fullWidth={true}
                    variant='outlined'
                    onChange={handleChange}
                    sx={{
                        my: 1
                    }}
                >
                    {[{ id: 0, text: 'Seleccionar' }, ...failed_options].map(o => (
                        <MenuItem key={o.id} value={o.id}>
                            {o.text}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    name='details'
                    value={fields.details}
                    onChange={handleChange}
                    fullWidth
                    multiline
                    rows={4}
                    sx={{
                        my: 1
                    }}
                />
                {errors.details && (<Typography
                    variant='caption'
                    sx={{
                        color: 'red',
                        ml: '.5rem'
                    }}
                >{errors.details}</Typography>)}
            </DialogContent>
            <DialogActions sx={{
                p: 3
            }}>
                <Button autoFocus onClick={handleClose}>{t('Cancel')}</Button>
                <Button
                    variant="contained"
                    color="error"
                    disabled={confirming}
                    onClick={handleSubmit}
                >{t('Reject Cashout')}</Button>
            </DialogActions>
        </Dialog >
    )
}
