import { useState, useEffect, useCallback } from 'react'
import { Button } from '@mui/material'
import { Link } from 'react-router-dom'

import DescriptionIcon from '@mui/icons-material/Description'
import AddIcon from '@mui/icons-material/Add'
import { Card, CardContent } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Filters from './Filters'
import AppFilters from '../../AppFilters'
import UsersTable from './UsersTable'

import { toast } from 'react-toastify'
import PageTitle from '../../Misc/PageTitle'
import LoadingButton from '@mui/lab/LoadingButton'
import useIsMounted from '../../../hooks/useIsMounted';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { USERS__CREATE } from '../../../enums/Caps'
import useHasPermissions from '../../../hooks/useHasPermissions'


export default function UsersList() {
    const { t } = useTranslation()

    const hasPermissions = useHasPermissions()

    const isMounted = useIsMounted()

    const api = useAxiosPrivate()

    const [params, setParams] = useState({
        status: 1
    })

    const [loading, setLoading] = useState(true)

    const [fetchingData, setFetchingData] = useState(false)

    const [data, setData] = useState([])

    const [meta, setMeta] = useState({
        total_pages: 1,
        current_page: 1
    })

    const handleOnSearch = values => {
        values = {
            ...values,
            status: String(values.status).replace('all', '')
        }

        setLoading(true)

        setParams(values)
    }

    const handleOnPageChange = (e, page) => {
        setLoading(true)

        setMeta({ ...meta, current_page: page })
        setParams({ ...params, page: page })
    }

    const retrieveData = useCallback(() => {
        setData([])

        api.get('/internal/users/', { params: params })
            .then(response => {
                if (isMounted()) {
                    const { meta, data } = response.data;

                    setMeta(meta)

                    setData(data)
                }
            })
            .catch(() => isMounted() && toast.error(t('Something went wrong')))
            .finally(() => isMounted() && setLoading(false))
    }, [params, t, api, isMounted])

    const handleResetPassword = (userId) => {
        return api.post(`/internal/users/${userId}/reset-password/`)
            .then(() => isMounted() && toast.success(t('Password has been reset')))
            .catch(() => isMounted() && toast.error(t('Something went wrong')))
    }

    const handleExportCsv = () => {
        setFetchingData(true)

        return api.get(`/internal/users/export/`, { params: params })
            .then(response => {
                if (isMounted()) {
                    const headers = response.headers

                    const regexp = new RegExp(/filename=(.*)/)
                    const match = headers.hasOwnProperty('content-disposition') ? headers['content-disposition'].match(regexp) : ''
                    const filename = match.length > 1 ? match[1] : 'transactions.csv'

                    const binaryData = []

                    binaryData.push(response.data)

                    let anchor = document.createElement('a')

                    anchor.href = window.URL.createObjectURL(new Blob(binaryData, { type: "text/csv" }))

                    anchor.download = filename

                    anchor.click()

                    anchor.remove()
                }
            })
            .catch(() => isMounted() && toast.error(t('Something went wrong')))
            .finally(() => isMounted() && setFetchingData(false))
    }

    useEffect(() => {
        const loadData = () => {
            retrieveData()
        }

        loadData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])

    return (
        <>
            <Card>
                <CardContent>
                    <PageTitle title={t('Users')} />

                    <div className="tools">
                        {hasPermissions(USERS__CREATE) ? (<Button
                            component={Link}
                            to="create"
                            size="small"
                            variant="contained"
                            startIcon={<AddIcon />}
                            className="base__btn-action"
                        >
                            {t('New')}
                        </Button>) : ''}

                        <LoadingButton
                            loading={fetchingData}
                            onClick={() => handleExportCsv()}
                            loadingPosition="start"
                            startIcon={<DescriptionIcon />}
                            size="small"
                            className="base__btn-action"
                        >
                            {t('Export')}
                        </LoadingButton>
                    </div>

                    <AppFilters Filters={<Filters handleOnSearch={handleOnSearch} />} />

                    <UsersTable
                        data={data}
                        pages={meta.total_pages}
                        page={meta.current_page}
                        loading={loading}
                        handleOnPageChange={handleOnPageChange}
                        handleResetPassword={handleResetPassword}
                    />
                </CardContent>
            </Card>
        </>
    )
}
