import { useEffect, useCallback } from 'react'
import MethodProviderForm from './MethodProviderForm'
import { Card, CardContent } from '@mui/material'
import { useState } from 'react'
import FormSkeletonWrapper from '../../SkeletonUI/FormSkeletonWrapper/FormSkeletonWrapper'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import PageTitle from '../../Misc/PageTitle'
import { replaceNullValues, parseErrors } from '../../../services/utils'
import { toast } from 'react-toastify'
import useIsMounted from '../../../hooks/useIsMounted'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import useHasPermissions from '../../../hooks/useHasPermissions'
import { METHOD_PROVIDERS__UPDATE } from '../../../enums/Caps'


export default function MethodProviderEdit() {
    const { t } = useTranslation()

    const hasPermissions = useHasPermissions()

    const isMounted = useIsMounted()

    const api = useAxiosPrivate()

    const navigate = useNavigate()

    const { providerId } = useParams()

    const [loading, setLoading] = useState(true)

    const [provider, setProvider] = useState([])

    const handleCancel = () => {
        navigate('/method-providers')
    }

    const handleSubmit = (values, { setFieldError }) => {
        api.put(`/internal/payment-methods/providers/${providerId}/`, values)
            .then(() => isMounted() && navigate('/method-providers'))
            .catch(({ response }) => {
                if (isMounted()) {
                    const errors = parseErrors(response)

                    if (typeof errors === 'object') {
                        for (let i in errors) {
                            setFieldError(errors[i].field, t(errors[i].message), false)
                        }
                    } else if (typeof errors === 'string') {
                        toast.error(t(errors))
                    } else {
                        toast.error(t('Something went wrong'))
                    }
                }
            })
    }

    const retrieveData = useCallback(() => {
        api.get(`/internal/payment-methods/providers/${providerId}/`)
            .then(({ data: { data } }) => isMounted() && setProvider(replaceNullValues(data)))
            .catch(() => isMounted() && toast.error(t('Something went wrong')))
            .finally(() => isMounted() && setLoading(false))
    }, [t, providerId, isMounted, api])

    const FORM_VALIDATION = Yup.object().shape({
        name: Yup.string()
            .required(t('This field is required')),
        code: Yup.string()
            .required(t('This field is required')),
        api_url: Yup.string(),
        api_key: Yup.string(),
        secret_key: Yup.string(),
        provider_currency: Yup.string()
            // .matches(/[A-Za-z]{3}/, t('You must enter a valid currency code'))
            .required(t('This field is required')),
    })

    useEffect(() => {
        const init = () => retrieveData()

        init()
    }, [retrieveData])

    return (
        <>
            <Card>
                <CardContent>
                    <PageTitle title={t('Update provider information')} />

                    {
                        loading ? <FormSkeletonWrapper /> :
                            <MethodProviderForm
                                isNew={false}
                                handleSubmit={handleSubmit}
                                handleCancel={handleCancel}
                                initialFormState={provider}
                                FORM_VALIDATION={FORM_VALIDATION}
                                hasPermissions={hasPermissions(METHOD_PROVIDERS__UPDATE)}
                            />
                    }
                </CardContent>
            </Card>
        </>
    )
}
