import useAuth from "./useAuth"


const useHasPermissions = () => {
    const { auth } = useAuth()

    if (!auth.user) {
        return false
    }

    const hasPermissions = (permission) => {
        const permissions = auth.user.permissions ? auth.user.permissions : []

        if (typeof permission === 'object') {
            return permission.filter(p => permissions.includes(p)).length
        }

        return permissions.length && permissions.includes(permission)
    }

    return hasPermissions
}

export default useHasPermissions