import { useState, useEffect, useCallback } from 'react'
import { Grid } from '@mui/material'

import { Card, CardContent } from '@mui/material'
import { useTranslation } from 'react-i18next'
import SpreadsTable from './SpreadsTable'

import PageTitle from 'components/Misc/PageTitle'
import { toast } from 'react-toastify'
import { Stack } from '@mui/material'
import useIsMounted from 'hooks/useIsMounted'
import useAxiosPrivate from 'hooks/useAxiosPrivate'
import TextField from '@mui/material/TextField'

import AddIcon from '@mui/icons-material/Add'
import IconButton from '@mui/material/IconButton'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import ButtonWrapper from 'components/FormUI/ButtonWrapper/ButtonWrapper'

import CircularProgress from '@mui/material/CircularProgress'


function validNewCountry(country, data) {
    const countries = data ? data.map((row) => row.country) : []
    return (!countries.includes(country)) && Boolean(country)
}


function CreateForm({ onCreate, onCancel, data }) {
    const { t } = useTranslation()
    const [newCountry, setNewCountry] = useState("")
    const [spread, setSpread] = useState(null)
    const [waitingResponse, setWaitingResponse] = useState(false)
    const api = useAxiosPrivate()

    const content = <Stack direction="row" alignItems="center" spacing={3}>
        <TextField
            id="country"
            label={t("Country")}
            error={(!validNewCountry(newCountry, data)) && Boolean(newCountry)}
            value={newCountry}
            sx={{ width: 90 }}
            InputLabelProps={{
                shrink: true,
            }}
            inputProps={{ style: { textTransform: "uppercase" } }}
            onChange={(e) => setNewCountry(e.target.value.toUpperCase())}
        />
        <TextField
            id="spread"
            type="number"
            label={t("Spread")}
            onChange={(e) => setSpread(e.target.value === '' ? null : e.target.value)}
            sx={{ width: 90 }}
            InputLabelProps={{
                shrink: true,
            }}
        />
        <Stack direction="row" alignItems="center">
            <IconButton onClick={onCancel}>
                <CancelIcon style={{ fill: "red" }} />
            </IconButton>
            <IconButton onClick={() => {
                if (!validNewCountry(newCountry, data)) return
                setWaitingResponse(true)
                api.put(`/internal/spreads/${newCountry}/`, { spread: spread })
                    .then(({ data: { data } }) => {
                        onCreate({ country: newCountry, spread: spread })
                    }).catch((error) => {
                        toast.error(t("Error updating spread"))
                    }).finally(() => {
                        setWaitingResponse(false)
                    })
            }}>
                <CheckCircleIcon style={{ fill: "green" }} />
            </IconButton>
        </Stack>
    </Stack>

    return waitingResponse ? <CircularProgress /> : content
}


export default function SpreadsList() {
    const { t } = useTranslation()

    const isMounted = useIsMounted()

    const api = useAxiosPrivate()

    const [params, setParams] = useState({})

    const [loading, setLoading] = useState(true)

    const [creating, setCreating] = useState(false)

    const [data, setData] = useState([])

    const [meta, setMeta] = useState({
        total_pages: 1,
        current_page: 1
    })

    const handleOnPageChange = (e, page) => {
        setMeta({ ...meta, current_page: page })
        setParams({ ...params, page: page })
    }

    const retrieveData = useCallback(() => {
        setData([])

        api.get(`/internal/spreads/`, { params: params })
            .then(({ data: { data, meta } }) => {
                if (isMounted()) {
                    const custom = data.filter(d => !d.is_global)

                    setMeta(meta)

                    setData([
                        ...custom, ...data.filter(d => !custom.filter(c => c.country === d.country).length)
                    ])
                }
            })
            .catch(() => isMounted() && toast.error(t('Something went wrong')))
            .finally(() => isMounted() && setLoading(false))
    }, [params, t, api, isMounted])

    useEffect(() => {
        const init = () => {
            setLoading(true)
            retrieveData()
        }

        init()
    }, [params, retrieveData])

    function removeItem(country) {
        setData(data.filter((row) => row.country !== country))
    }

    return (
        <>
            <Card>
                <CardContent>
                    <Stack direction="column" justifyContent="space-between" spacing={2}>
                        <PageTitle title={t('Spreads')} />
                        <Grid container spacing={2} mt={2} mb={2}>
                            {creating ?
                                <CreateForm data={data} onCreate={(newEntry) => {
                                    setCreating(false)
                                    setData([...data, newEntry])
                                }}
                                    onCancel={() => setCreating(false)} />
                                :
                                <ButtonWrapper
                                    variant="contained"
                                    to="create"
                                    size="small"
                                    startIcon={<AddIcon />}
                                    onClick={() => setCreating(true)}
                                >
                                    {t('New')}
                                </ButtonWrapper>
                            }
                        </Grid>
                    </Stack>

                    <Grid container spacing={2} mt={2} mb={2}>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={6} sx={{
                            textAlign: 'right'
                        }}>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12} xl={12}>
                            <SpreadsTable
                                data={data}
                                pages={meta.total_pages}
                                page={meta.current_page}
                                loading={loading}
                                handleOnPageChange={handleOnPageChange}
                                removeItem={removeItem}
                            />
                        </Grid>
                    </Grid>

                </CardContent>
            </Card>
        </>
    )
}
