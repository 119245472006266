import React, { useState, useEffect, useCallback } from 'react'
import MethodFeeForm from './MethodFeeForm'
import { Card, CardContent, Grid, Box } from "@mui/material"
import { useTranslation } from 'react-i18next'
import PageTitle from '../../../Misc/PageTitle'
import { useNavigate, useParams } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ButtonWrapper from '../../../FormUI/ButtonWrapper/ButtonWrapper'
import MethodFeesTable from './MethodFeesTable'
import axios from 'axios';
import { replaceNullValues } from '../../../../services/utils'
import { toast } from 'react-toastify'
import useIsMounted from '../../../../hooks/useIsMounted'
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'
import { PAYMENT_METHODS__FEES } from '../../../../enums/Caps'
import useHasPermissions from '../../../../hooks/useHasPermissions'


export default function MethodFeesList() {
    const { t } = useTranslation()

    const hasPermissions = useHasPermissions()

    const isMounted = useIsMounted()

    const api = useAxiosPrivate()

    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)

    const [method, setMethod] = useState()

    const [fees, setFees] = useState([])

    const { methodId } = useParams()

    const initialValues = {
        fee_fixed: 0,
        fee_fixed_currency: 'USD',
        fee_percent: 0,
        waiting_time: 30
    }

    const retrieveData = useCallback(() => {
        axios.all([
            api.get(`/internal/payment-methods/${methodId}/`),
            api.get(`/internal/payment-methods/${methodId}/fees/`)
        ])
            .then(axios.spread((...responses) => {
                if (isMounted()) {
                    const methodResponse = responses[0]
                    const feesResponse = responses[1]

                    setMethod(replaceNullValues(methodResponse.data.data))
                    setFees(feesResponse.data.data)
                }
            }))
            .catch(() => isMounted() && toast.error(t('Something went wrong')))
            .finally(() => isMounted() && setLoading(false))
    }, [t, methodId, api, isMounted])

    const handleSubmit = (values, { resetForm }) => {
        api.post(`/internal/payment-methods/${methodId}/fees/`, values)
            .then(({ data: { data } }) => {
                if (isMounted()) {
                    setFees([
                        data, ...fees
                    ])
                    resetForm()
                    toast.success(t('Fee has been created'))
                }
            })
            .catch(() => isMounted() && toast.error(t('Something went wrong')))
    }

    const handleDelete = (id) => {
        api.delete(`/internal/payment-methods/${methodId}/fees/${id}/`)
            .then(() => {
                if (isMounted()) {
                    setFees(fees.filter(f => f.id !== id))
                    toast.success(t('Fee has been deleted'))
                }
            })
            .catch(() => isMounted() && t('Something went wrong'))
    }

    useEffect(() => {
        const init = () => {
            retrieveData()
        }

        init()
    }, [retrieveData])

    return (
        <>
            <Card>
                <CardContent>
                    <PageTitle title={t('Payment method fees')} />

                    <Grid container spacing={2} mt={2} mb={2}>
                        <Grid item xs={12} sx={{
                            textAlign: 'right'
                        }}>
                            <ButtonWrapper
                                variant="text"
                                size="small"
                                onClick={() => navigate('/payment-methods')}
                                startIcon={<ArrowBackIcon />}>{t('Go back')}</ButtonWrapper>
                        </Grid>
                    </Grid>

                    {method && hasPermissions(PAYMENT_METHODS__FEES) ? <MethodFeeForm
                        method={method}
                        initialFormState={initialValues}
                        handleSubmit={handleSubmit}
                    /> : ''}

                    <Box width="100%" mt={4} />

                    <MethodFeesTable
                        data={fees}
                        loading={loading}
                        handleDelete={handleDelete}
                    />
                </CardContent>
            </Card>
        </>
    )
}
