import { Grid, Stack, Typography } from '@mui/material'
import { Formik, Form } from 'formik'
import TextfieldWrapper from '../../FormUI/Textfield/TextfieldWrapper'
import SelectWrapper from '../../FormUI/SelectWrapper/SelectWrapper'
import SelectApiWrapper from '../../FormUI/SelectWrapper/SelectApiWrapper'
import ButtonWrapper from '../../FormUI/ButtonWrapper/ButtonWrapper'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import SubmitWrapper from '../../FormUI/SubmitWrapper/SubmitWrapper'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import SwitchWrapper from '../../FormUI/SwitchWrapper/SwitchWrapper'
import { status } from "../../../enums/PaymentMethods"


export default function MethodForm({
    isNew,
    handleSubmit,
    initialFormState,
    providers,
    FORM_VALIDATION,
    hasPermissions
}) {
    const { t } = useTranslation()

    const navigate = useNavigate()

    return (
        <Formik
            initialValues={{
                ...initialFormState
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={handleSubmit}
        >
            <Form>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5">{t('Payment methods')}</Typography>
                    </Grid>

                    <Grid item xs={6} md={2}>
                        <SwitchWrapper
                            name="is_default"
                            label={t('Is default')}
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Box width="100%" />

                    {isNew && (<Grid item xs={6} md={3}>
                        <SelectApiWrapper
                            name="method_provider_id_"
                            label={t('Provider')}
                            options={providers}
                            translate={false}
                            disabled={!hasPermissions}
                        />
                    </Grid>)}

                    {!isNew && (<Grid item xs={6} md={3}>
                        <TextfieldWrapper
                            name="method_provider.name"
                            label={t('Provider')}
                            type="text"
                            disabled={true}
                        />
                    </Grid>)}

                    <Grid item xs={6} md={2}>
                        <SelectWrapper
                            name="status"
                            label={t('Status')}
                            options={status}
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={12} md={3}>
                        <TextfieldWrapper
                            name="name"
                            label={t('Name')}
                            type="text"
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <TextfieldWrapper
                            name="api_code"
                            label={t('Code')}
                            type="text"
                            disabled={!isNew || !hasPermissions}
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <TextfieldWrapper
                            name="min_amount"
                            label={t('Min. amount')}
                            type="number"
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <TextfieldWrapper
                            name="max_amount"
                            label={t('Max. amount')}
                            type="number"
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <TextfieldWrapper
                            name="default_currency"
                            label={t('Default currency')}
                            type="text"
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <TextfieldWrapper
                            name="countries"
                            label={t('Countries')}
                            type="text"
                            placeholder='CL,AR'
                            disabled={!hasPermissions}
                        />
                        <Typography variant='caption'>{t('Add countries separated by commas in ISO 3166 format - Alpha-2')}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <TextfieldWrapper
                            name="rules"
                            label={t('Rules')}
                            multiline
                            rows={4}
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2}>
                            {hasPermissions ? (<SubmitWrapper>{t('Save')}</SubmitWrapper>) : ''}
                            <ButtonWrapper
                                variant="text"
                                onClick={() => navigate('/payment-methods')}
                                startIcon={<CloseIcon />}>{t('Cancel')}</ButtonWrapper>
                        </Stack>
                    </Grid>
                </Grid>
            </Form>
        </Formik>
    )
}
