import { useEffect, useCallback } from 'react'
import MethodForm from './MethodForm'
import { Card, CardContent } from '@mui/material'
import { useState } from 'react'
import FormSkeletonWrapper from '../../SkeletonUI/FormSkeletonWrapper/FormSkeletonWrapper'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import PageTitle from '../../Misc/PageTitle'
import { replaceNullValues, parseErrors, removeNullValues } from '../../../services/utils'
import { toast } from 'react-toastify'
import axios from 'axios'
import useIsMounted from '../../../hooks/useIsMounted';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { PAYMENT_METHODS__UPDATE } from '../../../enums/Caps'
import useHasPermissions from '../../../hooks/useHasPermissions';


export default function MethodEdit() {
    const { t } = useTranslation()

    const hasPermissions = useHasPermissions()

    const isMounted = useIsMounted()

    const api = useAxiosPrivate()

    const navigate = useNavigate()

    const { methodId } = useParams()

    const [loading, setLoading] = useState(true)

    const [merchant, setMethod] = useState()

    const [providers, setProviders] = useState([])

    const handleCancel = () => {
        navigate('/payment-methods')
    }

    const handleSubmit = (values, { setFieldError }) => {
        delete values.method_provider_id_

        api.patch(`/internal/payment-methods/${methodId}/`, removeNullValues(values))
            .then(() => {
                if (isMounted()) {
                    toast.success(t('Payment method has been updated'))
                    navigate('/payment-methods')
                }
            })
            .catch(({ response }) => {
                if (isMounted()) {
                    const errors = parseErrors(response)

                    if (typeof errors === 'object') {
                        for (let i in errors) {
                            setFieldError(errors[i].field, t(errors[i].message), false)
                        }
                    } else if (typeof errors === 'string') {
                        toast.error(t(errors))
                    } else {
                        toast.error(t('Something went wrong'))
                    }
                }
            })
    }

    const retrieveData = useCallback(() => {
        axios.all([
            api.get(`/internal/payment-methods/${methodId}/`),
            api.get('/internal/payment-methods/providers/')
        ])
            .then(axios.spread((...responses) => {
                if (isMounted()) {
                    const methodResponse = responses[0]
                    const providersResponse = responses[1]

                    return {
                        method: methodResponse.data.data,
                        providers: providersResponse.data.data
                    }
                }
            }))
            .then(({
                method, providers
            }) => {
                setMethod(replaceNullValues(method))
                setProviders(providers)
            })
            .catch(() => isMounted() && toast.error(t('Something went wrong')))
            .finally(() => isMounted() && setLoading(false))
    }, [t, methodId, api, isMounted])

    const FORM_VALIDATION = Yup.object().shape({
        name: Yup.string()
            .required(t('This field is required')),
        api_code: Yup.string()
            .required(t('This field is required')),
        request_type: Yup.number()
            .required(t('This field is required')),
        min_amount: Yup.number()
            .required(t('This field is required'))
            .min(0, t('The minimum amount must be greater than or equal to 0')),
        max_amount: Yup.number()
            .required(t('This field is required'))
            .min(0, t('The maximum amount must be greater than or equal to 0')),
        default_currency: Yup.string()
            .required(t('This field is required')),
    })

    useEffect(() => {
        const init = () => retrieveData()

        init()
    }, [retrieveData])

    return (
        <>
            <Card>
                <CardContent>
                    <PageTitle title={t('Update payment method')} />

                    {
                        loading ? <FormSkeletonWrapper /> :
                            <MethodForm
                                isNew={false}
                                handleSubmit={handleSubmit}
                                handleCancel={handleCancel}
                                initialFormState={merchant}
                                providers={providers}
                                FORM_VALIDATION={FORM_VALIDATION}
                                hasPermissions={hasPermissions(PAYMENT_METHODS__UPDATE)}
                            />
                    }
                </CardContent>
            </Card>
        </>
    )
}
