import { useEffect, useState, useCallback } from 'react'
import { Card, CardContent, Grid, Hidden, Stack } from '@mui/material'
import FormSkeletonWrapper from '../../../SkeletonUI/FormSkeletonWrapper/FormSkeletonWrapper'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import SpreadsForm from './SpreadsForm'
import { parseErrors, replaceNullValues } from '../../../../services/utils'
import { toast } from 'react-toastify'
import PageTitle from '../../../Misc/PageTitle'
import MerchantMenu from '../MerchantMenu'
import MerchantAlias from '../MerchantAlias'
import MerchantSubmenu from '../MerchantSubmenu'
import useIsMounted from '../../../../hooks/useIsMounted'
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'
import useHasPermissions from '../../../../hooks/useHasPermissions'
import { COUNTRY_SPREADS__CREATE } from '../../../../enums/Caps'


export default function SpreadCreate() {
    const { t } = useTranslation()

    const hasPermissions = useHasPermissions()

    const navigate = useNavigate()

    const isMounted = useIsMounted()

    const api = useAxiosPrivate()

    const [loading, setLoading] = useState(true)

    const [countries, setCountries] = useState([])

    const { userId } = useParams()

    const getCountries = useCallback(() => {
        api.get('/internal/countries/')
            .then(({ data: { data } }) => {
                if (isMounted()) {
                    const country_list = data.sort((a, b) => (a.name_es > b.name_es) ? 1 : ((b.name_es > a.name_es) ? -1 : 0))

                    setCountries(replaceNullValues(
                        country_list.map((c) => ({
                            id: c.code,
                            name: c.name_es
                        }))
                    ))
                }
            })
            .catch(() => isMounted() && toast.error(t('Something went wrong')))
            .finally(() => isMounted() && setLoading(false))
    }, [t, api, isMounted])

    const handleSubmit = (values, { setFieldError }) => {
        api.post(`/internal/merchants/${userId}/spreads/`, values)
            .then(() => {
                if (isMounted()) {
                    navigate(`/merchants/${userId}/spreads`)
                }
            })
            .catch(({ response }) => {
                if (isMounted()) {
                    const errors = parseErrors(response)

                    if (typeof errors === 'object') {
                        for (let i in errors) {
                            setFieldError(errors[i].field, t(errors[i].message), false)
                        }
                    } else if (typeof errors === 'string') {
                        toast.error(t(errors))
                    } else {
                        toast.error(t('Something went wrong'))
                    }
                }
            })
    }

    const handleCancel = () => {
        navigate(`/merchants/${userId}/spreads`)
    }

    const initialFormState = {
        country: '',
        spread: ''
    }

    const FORM_VALIDATION = Yup.object().shape({
        country: Yup.string()
            .required(t('This field is required')),
        spread: Yup.number()
            .min(0, t('Spread value must be greater than or equal to zero'))
            .required(t('This field is required')),
    })


    useEffect(() => {
        const init = () => {
            getCountries()
        }

        init()
    }, [getCountries])

    return (
        <>
            <Card>
                <CardContent>
                    <Stack direction="row" justifyContent="space-between" spacing={2}>
                        <PageTitle title={t('Add new spread')} />

                        <Hidden xlUp={true}>
                            {userId ? <MerchantMenu id={userId} /> : ''}
                        </Hidden>
                    </Stack>

                    <MerchantAlias />

                    <Grid container spacing={2}>
                        <Grid item xs={12} xl={2}>
                            <MerchantSubmenu current='spreads' id={userId} />
                        </Grid>
                        <Grid item xs={12} xl={10}>
                            {
                                loading ? <FormSkeletonWrapper /> :
                                    <SpreadsForm
                                        isNew={true}
                                        handleSubmit={handleSubmit}
                                        handleCancel={handleCancel}
                                        initialFormState={initialFormState}
                                        FORM_VALIDATION={FORM_VALIDATION}
                                        merchant_id={userId}
                                        countries={countries}
                                        hasPermissions={hasPermissions(COUNTRY_SPREADS__CREATE)}
                                    />
                            }
                        </Grid>
                    </Grid>

                </CardContent>
            </Card>
        </>
    )
}
