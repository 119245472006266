import jwt_decode from "jwt-decode"

const useLoggedUser = () => {

    const user = JSON.parse(localStorage.getItem('user'))

    if (user) {
        return jwt_decode(user?.access)
    }

    return false
}

export default useLoggedUser