import { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import { Link, useSearchParams } from 'react-router-dom'

import DescriptionIcon from '@mui/icons-material/Description'
import AddIcon from '@mui/icons-material/Add'
import { Card, CardContent } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Filters from './Filters'
import AppFilters from '../../AppFilters'
import MerchantsTable from './MerchantsTable'

import { toast } from 'react-toastify'
import ButtonWrapper from '../../FormUI/ButtonWrapper/ButtonWrapper'
import PageTitle from '../../Misc/PageTitle'
import LoadingButton from '@mui/lab/LoadingButton'
import useIsMounted from '../../../hooks/useIsMounted'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { MERCHANTS__CREATE } from '../../../enums/Caps'
import useHasPermissions from '../../../hooks/useHasPermissions'


export default function MerchantsList() {
    const { t } = useTranslation()

    const isMounted = useIsMounted()

    const hasPermissions = useHasPermissions()

    const api = useAxiosPrivate()

    const [queryParams] = useSearchParams()

    const sp = new URLSearchParams(queryParams)

    const [params, setParams] = useState({
        username__icontains: sp.has('merchant') ? sp.get('merchant') : ''
    })

    const [loading, setLoading] = useState(true)

    const [fetchingData, setFetchingData] = useState(false)

    const [data, setData] = useState([])

    const [meta, setMeta] = useState({
        total_pages: 1,
        current_page: 1
    })

    const handleOnSearch = values => {
        if (values === params) {
            return
        }

        setLoading(true)
        setParams(values)
    }

    const handleOnPageChange = (e, page) => {
        setLoading(true)
        setMeta({ ...meta, current_page: page })
        setParams({ ...params, page: page })
    }

    const handleResetPassword = (id) => {
        return api.post(`/internal/merchants/${id}/password/`)
            .then(() => console.log('Password has been reset'))
            .catch(({ response }) => console.log(response.data))
    }

    const handleUpdateStatus = (id, event) => {
        api.patch(`/internal/merchants/${id}/`, { status: event.target.value })
            .then(() => {
                if (isMounted()) {
                    setData(data.map(m => {
                        if (m.id === id) {
                            m.status = event.target.value
                        }

                        return m
                    }))

                    toast.success(t('Merchant status has been updated'))
                }
            })
            .catch(() => isMounted() && toast.error(t('Something went wrong')))
    }

    const handleExportCsv = () => {
        setFetchingData(true)

        return api.get(`/internal/merchants/export/`, { params: params })
            .then(response => {
                if (isMounted) {
                    const headers = response.headers

                    const regexp = new RegExp(/filename=(.*)/)
                    const match = headers.hasOwnProperty('content-disposition') ? headers['content-disposition'].match(regexp) : ''
                    const filename = match.length > 1 ? match[1] : 'transactions.csv'

                    const binaryData = []

                    binaryData.push(response.data)

                    let anchor = document.createElement('a')

                    anchor.href = window.URL.createObjectURL(new Blob(binaryData, { type: "text/csv" }))

                    anchor.download = filename

                    anchor.click()

                    anchor.remove()
                }
            })
            .catch(() => isMounted() && toast.error(t('Something went wrong')))
            .finally(() => isMounted() && setFetchingData(false))
    }

    useEffect(() => {
        const retrieveData = () => {
            api.get('/internal/merchants/', { params: params })
                .then(({ data: { meta, data } }) => {
                    if (isMounted()) {
                        setMeta(meta)
                        setData(data)
                    }
                })
                .catch(() => isMounted() && toast.error(t('Something went wrong')))
                .finally(() => isMounted() && setLoading(false))
        }

        localStorage.removeItem('merchant')

        retrieveData()
    }, [params, t, isMounted, api])

    return (
        <>
            <Card>
                <CardContent>
                    <PageTitle title={t('Merchants')} />

                    <Grid container spacing={2} mt={2} mb={2}>
                        <Grid item xs={12}>

                            {hasPermissions(MERCHANTS__CREATE) && (<ButtonWrapper
                                variant="contained"
                                component={Link}
                                to="create"
                                size="small"
                                className="base__btn-action"
                                startIcon={<AddIcon />}>{t('New')}</ButtonWrapper>)}

                            <LoadingButton
                                loading={fetchingData}
                                onClick={() => handleExportCsv()}
                                loadingPosition="start"
                                startIcon={<DescriptionIcon />}
                                size="small"
                                className="base__btn-action"
                            >
                                {t('Export')}
                            </LoadingButton>
                        </Grid>
                    </Grid>

                    <AppFilters Filters={<Filters handleOnSearch={handleOnSearch} />} />

                    <MerchantsTable
                        data={data}
                        pages={meta.total_pages}
                        page={meta.current_page}
                        loading={loading}
                        handleOnPageChange={handleOnPageChange}
                        handleResetPassword={handleResetPassword}
                        handleUpdateStatus={handleUpdateStatus}
                    />
                </CardContent>
            </Card>
        </>
    )
}
