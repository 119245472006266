import { Formik, Form } from 'formik'
import { Grid, Box, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import TextfieldWrapper from 'components/FormUI/Textfield/TextfieldWrapper'
import SubmitWrapper from 'components/FormUI/SubmitWrapper/SubmitWrapper'
import ButtonWrapper from 'components/FormUI/ButtonWrapper/ButtonWrapper'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import SelectWrapper from 'components/FormUI/SelectWrapper/SelectWrapper'
import { address_types } from 'enums/Merchants'
import * as Yup from 'yup'



export default function AddressesForm({
    isNew,
    initialFormState,
    handleSubmit,
    merchant_id,
    hasPermissions
}) {
    const { t } = useTranslation()

    const navigate = useNavigate()

    function TextField(field, label, grProps = {}, txtProps = {}) {
        return (
            <Grid item {...grProps}>
                <TextfieldWrapper
                    name={field}
                    label={t(label)}
                    disabled={!hasPermissions}
                    {...txtProps}
                />
            </Grid>
        )
    }

    const FORM_VALIDATION = Yup.object().shape({
        address_type: Yup.string()
            .required(t('This field is required')),
        name: Yup.string()
            .required(t('This field is required')),
        country: Yup.string()
            .required(t('This field is required'))
            .max(2, t('Enter a valid country code')),
    })

    return (
        <Formik
            initialValues={{
                ...initialFormState
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={handleSubmit}
        >
            <Form>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <SelectWrapper
                            name="address_type"
                            label={t('Type')}
                            options={address_types}
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    {TextField("name", "Name")}
                    {TextField("country", "Country", {}, { inputProps: { maxLength: 2 } })}
                    {TextField("locality", "Locality")}
                    {TextField("zip_code", "Zip Code")}
                    {TextField("address", "Address", { xs: 12 })}
                    {TextField("address_line2", "Address Line 2", { xs: 12 })}
                    {TextField("subregion", "Subregion")}
                    {TextField("phone_number", "Phone Number")}

                    <Box width="100%" />

                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2}>
                            {hasPermissions ? (<SubmitWrapper>{t('Save')}</SubmitWrapper>) : ''}
                            <ButtonWrapper
                                variant="text"
                                onClick={() => navigate(`/merchants/${merchant_id}/addresses`)}
                                startIcon={<CloseIcon />}>{t('Cancel')}
                            </ButtonWrapper>
                        </Stack>
                    </Grid>
                </Grid>
            </Form>
        </Formik>
    )
}
