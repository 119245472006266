import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import Paper from '@mui/material/Paper'
import { Outlet } from 'react-router-dom'

const theme = createTheme()

export default function LoginScreen() {
    const { t } = useTranslation()

    return (
        <ThemeProvider theme={theme}>
            <Grid container component="main" sx={{ height: '100vh' }} >
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    pt={4}
                    sx={{
                        background: 'linear-gradient(90deg,#09097D 16%,#FF0054 99%)',
                        textAlign: 'center'
                    }}
                >
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ display: { xs: 'none', md: 'block' } }}
                    >
                        <Grid item>
                            <img src='https://cdn.paygol.com/images/v2/paygol-logo-white-230x64.png' alt='Paygol' />
                        </Grid>

                        <Grid item>
                            <Typography sx={{ color: '#fff', fontSize: '1.4rem', fontWeight: '400' }}>
                                {t('Welcome')}
                            </Typography>
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Outlet />
                </Grid>

            </Grid>
        </ThemeProvider>
    )
}