import { useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useTranslation } from 'react-i18next'
import Pagination from '../../Pagination/Pagination'
import TableBackdrop from '../../TableUI/TableBackdrop'
import MerchantMenu from './MerchantMenu'
import { FormControl, IconButton, InputBase, MenuItem, Select, Tooltip, Typography } from '@mui/material'
import HttpIcon from '@mui/icons-material/Http'
import { styled } from '@mui/material/styles'
import { status, status_info, ST_INCOMPLETE } from '../../../enums/Merchants'
import { Stack } from '@mui/material'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import Confirmation from '../../Misc/Confirmation'
import { toast } from 'react-toastify'
import DatetimeValue from '../../TableUI/DatetimeValue'
import Balance from './Balance/Balance'
import PaidIcon from '@mui/icons-material/Paid'
import { MERCHANTS__CREATE, MERCHANTS__UPDATE, MERCHANTS__VIEW_BALANCE } from '../../../enums/Caps'
import useHasPermissions from '../../../hooks/useHasPermissions'


const BootstrapInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        borderBottom: '1px solid #ced4da',
        fontSize: 12,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}));

export default function MerchantsTable({
    data,
    pages = 1,
    page = 1,
    loading = false,
    handleOnPageChange,
    handleResetPassword,
    handleUpdateStatus
}) {
    const { t } = useTranslation()

    const hasPermissions = useHasPermissions()

    const [selected, setSelected] = useState(null)
    const [merchant, setMerchant] = useState(null)

    const setSelectedWrapper = (merchant_id) => {
        setSelected(merchant_id)
        return new Promise((resolve) => resolve())
    }

    const handleConfirm = () => {
        handleResetPassword(selected)
            .then(() => {
                toast.success('Se ha enviado un correo con instrucciones para resetear la contraseña')
                setSelected(null)
            })
    }

    const handleClose = () => {
        setMerchant(null)
    }

    return (
        <>
            <Pagination
                handleOnPageChange={handleOnPageChange}
                page={page}
                pages={pages}
            />

            <Paper sx={{
                width: {
                    xs: 'calc(100vw - 100px)',
                    sm: 'calc(100vw - 330px)'
                }, overflow: 'hidden'
            }}>
                <TableContainer component={Paper}>
                    <Table sx={{ width: '100%' }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">{t('Internal ID')}</TableCell>
                                <TableCell align="center">{t('Service ID')}</TableCell>
                                <TableCell>{t('Email address')}</TableCell>
                                <TableCell>{t('Alias')}</TableCell>
                                <TableCell>{t('Name')}</TableCell>
                                <TableCell align="center">{t('Country')}</TableCell>
                                <TableCell align="center">{t('Payout method')}</TableCell>
                                <TableCell align="center">{t('Status')}</TableCell>
                                <TableCell align="center">{t('Last login')}</TableCell>
                                <TableCell>&nbsp;</TableCell>
                                <TableCell align="right">&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!loading && data.length ? data.map((row) => (
                                <TableRow
                                    key={row.email}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row" align="center">
                                        {row.id}
                                    </TableCell>
                                    <TableCell align="center">{row.service_id}</TableCell>
                                    <TableCell><i>{row.email}</i></TableCell>
                                    <TableCell>{row.username}</TableCell>
                                    <TableCell>{`${row.first_name} ${row.last_name}`}</TableCell>
                                    <TableCell align="center">{row.company_country}</TableCell>
                                    <TableCell align="center">{t(row.account_config?.payout_method_)}</TableCell>
                                    <TableCell align="center">

                                        {hasPermissions([
                                            MERCHANTS__UPDATE,
                                            MERCHANTS__CREATE
                                        ]) ? <FormControl sx={{ m: 1 }} fullWidth size="small">
                                            <Select
                                                labelId="demo-customized-select-label"
                                                value={row.status}
                                                onChange={(e) => handleUpdateStatus(row.id, e)}
                                                input={<BootstrapInput />}
                                            >
                                                {
                                                    Object.keys(status).map((st) => (
                                                        <MenuItem
                                                            key={st}
                                                            value={st}
                                                            title={t(status_info[st])}
                                                            disabled={parseInt(st) === ST_INCOMPLETE && row.status !== ST_INCOMPLETE}
                                                        >
                                                            {t(status[st])}
                                                        </MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl> : <Typography>{t(row.status_)}</Typography>}

                                    </TableCell>
                                    <TableCell>
                                        {row.owner?.last_login && (<DatetimeValue date={row.owner.last_login} />)}
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip title={(row.website ? row.website : '---')}>
                                            <IconButton>
                                                <HttpIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        <Stack direction="row" justifyContent="flex-end">
                                            {hasPermissions(MERCHANTS__VIEW_BALANCE) && (<IconButton
                                                color="primary"
                                                aria-label="Ver balance del Merchant"
                                                component="span"
                                                onClick={() => setMerchant(row)}
                                            >
                                                <PaidIcon />
                                            </IconButton>)}
                                            <MerchantMenu
                                                id={row.id}
                                                merchant={row}
                                                actions={[
                                                    {
                                                        id: `password-${row.id}`,
                                                        handleClick: () => { return setSelectedWrapper(row.id) },
                                                        icon: <VpnKeyIcon />,
                                                        title: t('Reset password'),
                                                        show: hasPermissions(MERCHANTS__UPDATE)
                                                    },
                                                ]}
                                            />
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            )) : <TableRow>
                                {
                                    loading ? <TableBackdrop open={loading} /> : <TableCell sx={{ textAlign: 'center' }} colSpan={100}>{t('No records found')}</TableCell>
                                }
                            </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            <Pagination
                handleOnPageChange={handleOnPageChange}
                page={page}
                pages={pages}
            />

            <Confirmation
                open={!!selected}
                title='Resetaer contraseña'
                legend='Se enviará un correo con instrucciones para resetear la contraseña. ¿Continuar?'
                handleConfirm={handleConfirm}
                handleClose={() => setSelected(null)}
                requesting={false}
            />

            {merchant && (<Balance
                merchant_id={merchant.id}
                username={merchant.username}
                open={true}
                handleClose={handleClose}
            />)}
        </>
    )
}
