import api from '../api'
import useAuth from './useAuth'


const useRefreshToken = () => {
    const { auth, setAuth } = useAuth()

    const refresh = async () => {
        try {
            const response = await api.post('/internal/login/refresh/', {
                refresh: auth?.user?.refresh
            })

            setAuth(prev => {
                const { user } = prev

                return { ...prev, user: { ...user, access: response.data.data.access } }
            })

            return response.data.data.access
        } catch (e) {
            localStorage.removeItem('user')
            setAuth({})
        }
    }

    return refresh
}

export default useRefreshToken
