import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import Button from '@mui/material/Button'
import React from 'react'
import { useTranslation } from 'react-i18next';

export default function Confirmation({
    open,
    title,
    legend = '',
    handleConfirm,
    handleClose,
    requesting
}) {
    const { t } = useTranslation()

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {legend}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t('Cancel')}</Button>
                    <Button
                        onClick={handleConfirm}
                        autoFocus
                        disabled={requesting}
                    >
                        {t('Confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
