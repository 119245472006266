import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

export default function BackdropWrapper({
    open
}) {
    return (
        <div>
            <Backdrop
                sx={{
                    position: 'relative',
                    color: '#0B1F3F',
                    backgroundColor: 'transparent',
                    padding: '1rem 0'
                }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    )
}