import TableHead from '@mui/material/TableHead'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'



import { Box, Grid, Stack } from "@mui/material"
import { Form, Formik } from "formik"
import { useTranslation } from 'react-i18next'

import ButtonWrapper from "components/FormUI/ButtonWrapper/ButtonWrapper"
import SubmitWrapper from "components/FormUI/SubmitWrapper/SubmitWrapper"

import SearchIcon from '@mui/icons-material/Search'
import UndoIcon from '@mui/icons-material/Undo'
import TextfieldWrapper from 'components/FormUI/Textfield/TextfieldWrapper';



export function tableHeadBuild(titles) {
    return (
        <TableHead>
            <TableRow>
                {titles.map((title, idx) => (
                    <TableCell key={idx}>{title}</TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

export function tableRowEntriesBuild(entry, fields) {
    return fields.map((field, idx) => (
        <TableCell key={idx}>{entry[field]}</TableCell>
    ))
}

export function MakeRowDataCellsComponent(fields) {
    return function RowDataCells({ entry }) {
        return tableRowEntriesBuild(entry, fields)
    }
}


export class FilterField {
    constructor(field, initial_value, title) {
        this.field = field;
        this.initial_value = initial_value;
        this.title = title;
    }
}


export function filtersBoxBuild(descriptions) {
    function formField(field_description, idx, t) {
        return (
            <Grid key={idx} item xs={12} md={4}>
                <TextfieldWrapper
                    name={field_description.field}
                    label={t(field_description.title)}
                    type="text"
                    size="small"
                />
            </Grid>
        )
    }

    const initialFormState = Object.fromEntries(
        descriptions.map((description) => [description.field, description.initial_value]))

    return function Filters({ handleOnSearch }) {

        const { t } = useTranslation()

        return (
            <Formik
                initialValues={{
                    ...initialFormState
                }}
                onSubmit={handleOnSearch}
            >
                {({ handleReset, handleSubmit }) => (<Form>
                    <Grid container spacing={2}>
                        {descriptions.map((description, idx) => formField(description, idx, t))}

                        <Box width="100%" />

                        <Grid item xs={12}>
                            <Stack direction="row" spacing={2} justifyContent="flex-end">
                                <SubmitWrapper startIcon={<SearchIcon />}>{t('Search')}</SubmitWrapper>
                                <ButtonWrapper
                                    onClick={() => {
                                        handleReset()
                                        handleSubmit()
                                    }}
                                    variant="text"
                                    startIcon={<UndoIcon />}>{t('Reset')}</ButtonWrapper>
                            </Stack>
                        </Grid>
                    </Grid>
                </Form>)}
            </Formik>
        )
    }
}

