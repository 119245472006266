import React from 'react'
import { Formik, Form } from 'formik'
import { Grid, Box, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import SelectWrapper from '../../../FormUI/SelectWrapper/SelectWrapper'
import TextfieldWrapper from '../../../FormUI/Textfield/TextfieldWrapper'
import SubmitWrapper from '../../../FormUI/SubmitWrapper/SubmitWrapper'
import ButtonWrapper from '../../../FormUI/ButtonWrapper/ButtonWrapper'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import SwitchWrapper from '../../../FormUI/SwitchWrapper/SwitchWrapper'
import { bank_accounts } from './../../../../enums/Merchants'


export default function BankAccountsForm({
    isNew,
    initialFormState,
    FORM_VALIDATION,
    handleSubmit,
    merchant_id,
    hasPermissions
}) {
    const { t } = useTranslation()

    const navigate = useNavigate()

    return (
        <Formik
            initialValues={{
                ...initialFormState
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={handleSubmit}
        >
            <Form>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={3}>
                        <SwitchWrapper
                            name="is_default"
                            label={t('Is default')}
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Grid item xs={6} md={3}>
                        <SwitchWrapper
                            name="is_intermediary"
                            label={t('Is intermediary')}
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={6} md={3}>
                        <SelectWrapper
                            name="status"
                            label={t('Status')}
                            options={bank_accounts.status}
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={6} md={3}>
                        <TextfieldWrapper
                            name="bank_country"
                            label={t('Country')}
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Grid item xs={12} md={9}>
                        <TextfieldWrapper
                            name="bank_address"
                            label={t('Bank address')}
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={12} md={6}>
                        <TextfieldWrapper
                            name="bank_name"
                            label={t('Bank name')}
                            type="text"
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextfieldWrapper
                            name="account_owner"
                            label={t('Account holder')}
                            type="text"
                            inputProps={{
                                maxLength: 128
                            }}
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <TextfieldWrapper
                            name="account_owner_personal_id"
                            label={t('Document number')}
                            type="text"
                            disabled={!hasPermissions}
                        />
                    </Grid>


                    <Grid item xs={12} md={3}>
                        <TextfieldWrapper
                            name="account_number"
                            label={t('Account number')}
                            type="text"
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <TextfieldWrapper
                            name="account_type"
                            label={t('Account type')}
                            type="text"
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextfieldWrapper
                            name="notification_email"
                            label={t('Notification email')}
                            type="email"
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextfieldWrapper
                            name="swift_bic"
                            label={t('SWIFT/BIC')}
                            type="text"
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextfieldWrapper
                            name="iban"
                            label={t('IBAN')}
                            type="text"
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2}>
                            {hasPermissions ? (<SubmitWrapper>{t('Save')}</SubmitWrapper>) : ''}
                            <ButtonWrapper
                                variant="text"
                                onClick={() => navigate(`/merchants/${merchant_id}/bank-accounts`)}
                                startIcon={<CloseIcon />}>{t('Cancel')}</ButtonWrapper>
                        </Stack>
                    </Grid>
                </Grid>
            </Form>
        </Formik>
    )
}
