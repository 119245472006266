import { useEffect } from 'react'
import { axiosCashouts } from '../api'
import useCashoutsSignature from './useCashoutsSignature'

const useAxiosCashouts = () => {
    const signature = useCashoutsSignature()

    // 'x-pg-sig': '8f70a37f1b7bbfcb4edc95596bd0c2e2bf4347e33f62027d66022933fc795404',
    // 'x-pg-key': '477980:1656646982'

    useEffect(() => {
        const requestIntercept = axiosCashouts.interceptors.request.use(
            config => {
                const localSig = JSON.parse(localStorage.getItem('cashouts.k'))

                if (!config.headers['x-pg-sig']) {
                    config.headers['x-pg-sig'] = localSig?.sign
                    config.headers['x-pg-key'] = localSig?.key
                }

                return config
            }, (error) => Promise.reject(error)
        )

        const responseItercept = axiosCashouts.interceptors.response.use(
            response => response,
            async (error) => {
                const prevRequest = error?.config

                if (error?.response?.status === 401 && !prevRequest?.sent) {
                    prevRequest.sent = true

                    const { sign, key } = await signature()

                    prevRequest.headers['x-pg-sig'] = sign
                    prevRequest.headers['x-pg-key'] = key

                    return axiosCashouts(prevRequest)
                }

                return Promise.reject(error)
            }
        )

        return () => {
            axiosCashouts.interceptors.request.eject(requestIntercept)
            axiosCashouts.interceptors.response.eject(responseItercept)
        }
    }, [signature])

    return axiosCashouts
}

export default useAxiosCashouts