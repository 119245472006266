import { useState, useEffect, useCallback } from 'react'
import { Card, CardContent } from '@mui/material'
import { useTranslation } from 'react-i18next'
import MethodsRequestedTable from '../MethodsRequested/MethodsRequestedTable'
import { toast } from 'react-toastify'
import AppFilters from '../../../AppFilters'
import Filters from './Filters'
import { ST_PRODUCTION, ST_REQUESTED } from '../../../../enums/MerchantPaymentMethods'
import PageTitle from '../../../Misc/PageTitle';
import useIsMounted from '../../../../hooks/useIsMounted'
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'


export default function MethodsRequestedList() {
    const { t } = useTranslation()

    const isMounted = useIsMounted()

    const api = useAxiosPrivate()

    const [loading, setLoading] = useState(true)

    const [params, setParams] = useState({
        status: ST_REQUESTED
    })

    const [meta, setMeta] = useState({
        total_pages: 1,
        current_page: 1
    })

    const [data, setData] = useState([])

    const handleOnSearch = values => {
        setLoading(true)

        setParams({ values, status: ST_REQUESTED })
    }

    const handleOnPageChange = (e, page) => {
        setLoading(true)
        setMeta({ ...meta, current_page: page })
        setParams({ ...params, page: page })
    }

    const retrieveData = useCallback(() => {
        api.get(`/internal/merchants/payment-methods/`, { params: params })
            .then((response) => {
                if (isMounted()) {
                    const { data, meta } = response.data;

                    setData(data)

                    if (meta) {
                        setMeta(meta)
                    }
                }
            })
            .catch(() => isMounted() && toast.error(t('Something went wrong')))
            .finally(() => isMounted() && setLoading(false))
    }, [t, params, api, isMounted])

    useEffect(() => {
        const init = () => {
            retrieveData()
        }

        init()
    }, [params, retrieveData])

    const handleChangeStatus = (id, status) => {
        api.put(`/internal/merchants/payment-methods/${id}/`, { status })
            .then(() => {
                if (isMounted()) {
                    const message = status === ST_PRODUCTION ? 'Request has been approved' : 'Request has been rejected'
                    toast.success(t(message))

                    setData(data.filter(d => d.id !== id))
                }
            })
            .catch(() => isMounted() && toast.error(t('Something went wrong')))
    }

    return (
        <>
            <Card>
                <CardContent>
                    <PageTitle title={t('Methods Pending approvals')} />

                    <AppFilters Filters={<Filters handleOnSearch={handleOnSearch} />} />

                    <MethodsRequestedTable
                        data={data}
                        setData={setData}
                        pages={meta.total_pages}
                        page={meta.current_page}
                        loading={loading}
                        handleOnPageChange={handleOnPageChange}
                        handleChangeStatus={handleChangeStatus}
                    />
                </CardContent>
            </Card>
        </>
    )
}
