const ST_CREATED = 1
const ST_COMPLETED = 2
const ST_FAILED = 3
const ST_CHARGEBACK = 4
const ST_REFUND = 5
const ST_ONHOLD = 6
const ST_REJECTED = 7

const status = {
    1: 'Created',
    2: 'Completed',
    3: 'Failed',
    4: 'Chargeback',
    5: 'Refund',
    6: 'On hold',
    7: 'Rejected'
}

export {
    status,
    ST_CREATED,
    ST_COMPLETED,
    ST_FAILED,
    ST_CHARGEBACK,
    ST_REFUND,
    ST_ONHOLD,
    ST_REJECTED
}