import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useTranslation } from 'react-i18next'
import Pagination from '../../Pagination/Pagination'

import TableBackdrop from '../../TableUI/TableBackdrop'
import { Backdrop, CircularProgress, Typography } from '@mui/material'
import DatetimeValue from '../../TableUI/DatetimeValue'
import CurrencyValue from '../../TableUI/CurrencyValue'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import TableActions from '../../TableUI/TableActions'


const styles = () => ({
    root: {
        width: '100%',
        overflowX: "auto",
    }, table: {
        minWidth: 650,
    }
})

export default function SettlementsTable({
    data,
    pages = 1,
    page = 1,
    loading = false,
    fetchingData,
    handleOnPageChange,
    handleExportCsv,
    handleExportPdf,
}) {
    const { t } = useTranslation()

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', fontWeight: 800, zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={fetchingData}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Pagination
                handleOnPageChange={handleOnPageChange}
                page={page}
                pages={pages}
            />

            <TableContainer className={styles.root}>
                <Table className={styles.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('Invoice Nº')}</TableCell>
                            <TableCell>{t('Merchant')}</TableCell>
                            <TableCell align="center">{t('Date')}</TableCell>
                            <TableCell align="right">{t('Total')}</TableCell>
                            <TableCell align="right">{t('Paid')}</TableCell>
                            <TableCell>{t('Country')}</TableCell>
                            <TableCell align="right">&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading && data.length ? data.map((row, idx) => (
                            <TableRow
                                key={idx}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <Typography noWrap>{row.invoice_code}</Typography>
                                </TableCell>
                                <TableCell>{row.merchant?.username}</TableCell>
                                <TableCell align="center">
                                    <DatetimeValue date={row.paid_at} />
                                </TableCell>
                                <TableCell align="right">
                                    <CurrencyValue amount={row.total_payments} currency={row.currency} />
                                </TableCell>
                                <TableCell align="right">
                                    <CurrencyValue amount={row.total_paid} currency={row.currency} />
                                </TableCell>
                                <TableCell>{row.merchant?.default_bank_account?.bank_country}</TableCell>
                                <TableCell align="right">
                                    <TableActions actions={[
                                        {
                                            id: `export-csv-${row.id}`,
                                            handleClick: () => handleExportCsv(row.id),
                                            icon: <FileCopyIcon />,
                                            title: t('Export CSV')
                                        },
                                        {
                                            id: `export-pdf-${row.id}`,
                                            handleClick: () => handleExportPdf(row.id, row.invoice_code),
                                            icon: <PictureAsPdfIcon />,
                                            title: t('Export PDF')
                                        }
                                    ]} />
                                </TableCell>
                            </TableRow>
                        )) : <TableRow>
                            {
                                loading ? <TableBackdrop open={loading} /> : <TableCell sx={{ textAlign: 'center' }} colSpan={100}>{t('No records found')}</TableCell>
                            }
                        </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <Pagination
                handleOnPageChange={handleOnPageChange}
                page={page}
                pages={pages}
            />
        </>
    )
}
