import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useTranslation } from 'react-i18next'
import EditIcon from '@mui/icons-material/Edit'
import { useNavigate } from 'react-router-dom'
import TableBackdrop from '../../../TableUI/TableBackdrop'
import Pagination from '../../../Pagination/Pagination'
import TableActions from '../../../TableUI/TableActions'
import { Typography } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import { BANK_ACCOUNTS__UPDATE } from '../../../../enums/Caps'
import PreviewIcon from '@mui/icons-material/Preview'
import useHasPermissions from '../../../../hooks/useHasPermissions'


export default function BankAccountsTable({
    data,
    pages = 1,
    page = 1,
    loading = false,
    handleOnPageChange
}) {
    const { t } = useTranslation()

    const hasPermissions = useHasPermissions()

    const navigate = useNavigate()

    const handleNavigate = (view) => {
        navigate(view)
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('Bank name')}</TableCell>
                            <TableCell>{t('Country')}</TableCell>
                            <TableCell>{t('Titular')}</TableCell>
                            <TableCell>{t('Account number')}</TableCell>
                            <TableCell>{t('SWIFT/BIC')}</TableCell>
                            <TableCell>{t('IBAN')}</TableCell>
                            <TableCell align='center'>{t('Is intermediary')}</TableCell>
                            <TableCell align='center'>{t('Is default')}</TableCell>
                            <TableCell>{t('Status')}</TableCell>
                            <TableCell align="center">&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.length ? data.map((row, idx) => (
                            <TableRow
                                key={idx}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <Typography noWrap>{row.bank_name}</Typography>
                                </TableCell>
                                <TableCell>{t(row.bank_country)}</TableCell>
                                <TableCell>
                                    <Typography>{t(row.account_owner)}</Typography>
                                    <Typography>{t(row.account_owner_personal_id)}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography noWrap>{row.account_number}</Typography>
                                    <Typography noWrap>{row.account_type}</Typography>
                                </TableCell>
                                <TableCell>{row.swift_bic}</TableCell>
                                <TableCell>
                                    <Typography noWrap>{row.iban}</Typography>
                                </TableCell>
                                <TableCell align='center'>{row.is_intermediary ? <CheckIcon /> : ''}</TableCell>
                                <TableCell align='center'>{row.is_default ? <CheckIcon /> : ''}</TableCell>
                                <TableCell>{t(row.status_)}</TableCell>
                                <TableCell align="center">
                                    <TableActions actions={[
                                        {
                                            id: `edit-${row.id}`,
                                            handleClick: () => { handleNavigate(`${row.id}/edit`) },
                                            icon: hasPermissions(BANK_ACCOUNTS__UPDATE) ? <EditIcon /> : <PreviewIcon />,
                                            title: hasPermissions(BANK_ACCOUNTS__UPDATE) ? t('Edit') : t('View')
                                        }
                                    ]} />
                                </TableCell>
                            </TableRow>
                        )) : <TableRow>
                            {
                                loading ? <TableBackdrop open={loading} /> : <TableCell sx={{ textAlign: 'center' }} colSpan={100}>{t('No records found')}</TableCell>
                            }
                        </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <Pagination
                handleOnPageChange={handleOnPageChange}
                page={page}
                pages={pages}
            />
        </>
    )
}
