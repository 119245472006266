import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import PropTypes from 'prop-types'
import BackdropWrapper from '../../../BackdropWrapper/BackdropWrapper'
import { Grid } from '@mui/material'
import CurrencyValue from '../../../TableUI/CurrencyValue'
import { useTranslation } from 'react-i18next'
import useIsMounted from '../../../../hooks/useIsMounted';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'
import { toast } from 'react-toastify'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {
        xs: '90%',
        md: '600px'
    },
    maxHeight: '100%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    overflow: 'scroll',
    p: 4,
}

export default function Balance({
    merchant_id,
    username,
    open,
    handleClose
}) {
    const { t } = useTranslation()

    const isMounted = useIsMounted()

    const api = useAxiosPrivate()

    const [loading, setLoading] = useState(true)

    const [data, setData] = useState([])

    useEffect(() => {
        const getBalance = () => {
            api.get(`/internal/merchants/${merchant_id}/balance/`)
                .then(({ data: { data } }) => isMounted() && setData(data))
                .catch(() => isMounted() && toast.error(t('Something went wrong')))
                .finally(() => isMounted() && setLoading(false))
        }

        getBalance()
    }, [merchant_id, api, isMounted, t])

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h5" component="h2">
                        {username}
                    </Typography>

                    <Grid container spacing={2}>
                        <Box width="100%" mt={2} />

                        <Grid item xs={12} sm={4}>
                            <Typography variant="h6">{t('Payment method')}</Typography>
                        </Grid>

                        <Grid item xs={6} sm={4} sx={{ textAlign: 'right' }}>
                            <Typography variant="h6">{t('Total amount')}</Typography>
                        </Grid>

                        <Grid item xs={6} sm={4} sx={{ textAlign: 'right' }}>
                            <Typography variant="h6">{t('Withdrawable amount')}</Typography>
                        </Grid>

                        {data.methods && (data?.methods?.map((m, idx) => (<React.Fragment key={idx}>
                            <Grid item xs={12} sm={4}>
                                {m.name}
                            </Grid>

                            <Grid item xs={6} sm={4} sx={{ textAlign: 'right' }}>
                                <CurrencyValue amount={m.total} currency={data.currency} />
                            </Grid>

                            <Grid item xs={6} sm={4} sx={{ textAlign: 'right' }}>
                                <CurrencyValue amount={m.available} currency={data.currency} />
                            </Grid>

                            <Box sx={{
                                height: '1px',
                                width: '100%',
                                background: '#c0c0c0',
                                my: 1
                            }} />
                        </React.Fragment>)))}

                        {data.summary?.chargebacks_fee > 0 && (
                            <>
                                <Grid item xs={6} sm={4}>{t('Chargebacks')}</Grid>

                                <Grid item xs={6} sm={8} sx={{ textAlign: 'right' }}>
                                    <CurrencyValue amount={data.summary.chargebacks_fee * -1} currency={data.currency} />
                                </Grid>

                                <Box sx={{
                                    height: '1px',
                                    width: '100%',
                                    background: '#c0c0c0',
                                    my: 1
                                }} />
                            </>)}

                        {data.summary?.refunds_fee > 0 && (
                            <>
                                <Grid item xs={6} sm={4}>{t('Refunds')}</Grid>

                                <Grid item xs={6} sm={8} sx={{ textAlign: 'right' }}>
                                    <CurrencyValue amount={data.summary.refunds_fee * -1} currency={data.currency} />
                                </Grid>

                                <Box sx={{
                                    height: '1px',
                                    width: '100%',
                                    background: '#c0c0c0',
                                    my: 1
                                }} />
                            </>)}

                        {data.summary?.cashouts > 0 && (
                            <>
                                <Grid item xs={6} sm={4}>{t('Cashouts')}</Grid>

                                <Grid item xs={6} sm={8} sx={{ textAlign: 'right' }}>
                                    <CurrencyValue amount={data.summary.cashouts * -1} currency={data.currency} />
                                </Grid>

                                <Box sx={{
                                    height: '1px',
                                    width: '100%',
                                    background: '#c0c0c0',
                                    my: 1
                                }} />
                            </>)}

                        {data.summary?.other_charges > 0 && (
                            <>
                                <Grid item xs={6} sm={4}>{t('Other charges')}</Grid>

                                <Grid item xs={6} sm={8} sx={{ textAlign: 'right' }}>
                                    <CurrencyValue amount={data.summary.other_charges * -1} currency={data.currency} />
                                </Grid>

                                <Box sx={{
                                    height: '1px',
                                    width: '100%',
                                    background: '#c0c0c0',
                                    my: 1
                                }} />
                            </>)}

                        {data.summary?.other_credits > 0 && (
                            <>
                                <Grid item xs={6} sm={4}>{t('Other credits')}</Grid>

                                <Grid item xs={6} sm={8} sx={{ textAlign: 'right' }}>
                                    <CurrencyValue amount={data.summary.other_credits} currency={data.currency} />
                                </Grid>

                                <Box sx={{
                                    height: '1px',
                                    width: '100%',
                                    background: '#c0c0c0',
                                    my: 1
                                }} />
                            </>)}

                        {data.summary?.settlement_cost > 0.2 && (
                            <>
                                <Grid item xs={6} sm={4}>{t('Settlement cost')}</Grid>

                                <Grid item xs={6} sm={8} sx={{ textAlign: 'right' }}>
                                    <CurrencyValue amount={data.summary.settlement_cost * -1} currency={data.currency} />
                                </Grid>

                                <Box sx={{
                                    height: '1px',
                                    width: '100%',
                                    background: '#c0c0c0',
                                    my: 1
                                }} />
                            </>)}

                        {data?.summary && (<><Grid item xs={12} sm={4}>{t('Total')}</Grid>
                            <Grid item xs={6} sm={4} sx={{ textAlign: 'right' }}>
                                <CurrencyValue amount={data?.summary?.total_payments} currency={data?.currency} />
                            </Grid>

                            <Grid item xs={6} sm={4} sx={{ textAlign: 'right' }}>
                                <CurrencyValue amount={data?.summary?.withdrawable_amount} currency={data?.currency} />
                            </Grid>

                            <Box sx={{
                                height: '1px',
                                width: '100%',
                                background: '#c0c0c0'
                            }} /></>)}
                    </Grid>

                    <BackdropWrapper open={loading} />
                </Box>
            </Modal>
        </div >
    )
}

Balance.propTypes = {
    merchant_id: PropTypes.number.isRequired,
    username: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired
}