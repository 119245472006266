import { useEffect, useCallback } from 'react'
import MerchantForm from './MerchantForm'
import { Card, CardContent, Grid, Hidden } from '@mui/material'
import { useState } from 'react'
import FormSkeletonWrapper from '../../SkeletonUI/FormSkeletonWrapper/FormSkeletonWrapper'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import PageTitle from '../../Misc/PageTitle'
import { replaceNullValues, parseErrors } from '../../../services/utils'
import { toast } from 'react-toastify'
import axios from 'axios'
import { Stack } from '@mui/material';
import MerchantMenu from './MerchantMenu'
import MerchantAlias from './MerchantAlias';
import MerchantSubmenu from './MerchantSubmenu'
import useIsMounted from '../../../hooks/useIsMounted'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { MERCHANTS__UPDATE } from '../../../enums/Caps'
import useHasPermissions from '../../../hooks/useHasPermissions'


export default function MerchantEdit() {
    const { t } = useTranslation()

    const hasPermissions = useHasPermissions()

    const navigate = useNavigate()

    const isMounted = useIsMounted()

    const api = useAxiosPrivate()

    const { userId } = useParams()

    const [loading, setLoading] = useState(true)

    const [merchant, setMerchant] = useState()

    const [countries, setCountries] = useState()

    const handleCancel = () => {
        navigate('/merchants')
    }

    const handleSubmit = (values, { setFieldError }) => {
        values.alias = values.username

        api.put(`/internal/merchants/${userId}/`, values)
            .then(() => {
                navigate('/merchants')
            })
            .catch(({ response }) => {
                if (isMounted()) {
                    const errors = parseErrors(response)

                    if (typeof errors === 'object') {
                        for (let i in errors) {
                            setFieldError(errors[i].field, t(errors[i].message), false)
                        }
                    } else if (typeof errors === 'string') {
                        toast.error(t(errors))
                    } else {
                        toast.error(t('Something went wrong'))
                    }
                }
            })
    }

    const retrieveData = useCallback(() => {
        axios.all([
            api.get('/internal/countries/'),
            api.get(`/internal/merchants/${userId}/`),
        ])
            .then(axios.spread((...responses) => {
                if (isMounted()) {
                    const countriesResponse = responses[0]
                    const merchantResponse = responses[1]

                    const country_list = countriesResponse.data.data.map((c) => ({
                        id: c.code,
                        name: c.name_es
                    }))
                        .sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

                    return {
                        merchant: replaceNullValues(merchantResponse.data.data),
                        countries: country_list
                    }
                }
            }))
            .then(({ merchant, countries }) => {
                if (!merchant.company_phone_number) {
                    merchant.company_phone_number = ''
                }

                setMerchant(merchant)
                setCountries(countries)
            })
            .catch(({ response }) => {
                if (response?.status === 404) {
                    navigate('/merchants')
                }
            })
            .catch(() => isMounted() && toast.error(t('Something went wrong')))
            .finally(() => isMounted() && setLoading(false))
    }, [userId, t, isMounted, api, navigate])

    const FORM_VALIDATION = Yup.object().shape({
        country: Yup.string()
            .required(t('This field is required'))
            .min(2, t('Invalid country code'))
            .max(2, t('Invalid country code')),
        company_country: Yup.string()
            .required(t('This field is required'))
            .min(2, t('Invalid country code'))
            .max(2, t('Invalid country code')),
        email: Yup.string()
            .max(128, t('The text exceeds the maximum length allowed ({{maxlen}})', { maxlen: 255 }))
            .email(t('You must enter a valid email address'))
            .required(t('Email address is required')),
        username: Yup.string()
            .max(45, t('The text exceeds the maximum length allowed ({{maxlen}})', { maxlen: 45 }))
            .required(t('This field is required')),
        first_name: Yup.string()
            .max(64, t('The text exceeds the maximum length allowed ({{maxlen}})', { maxlen: 64 }))
            .required(t('This field is required')),
        last_name: Yup.string()
            .max(64, t('The text exceeds the maximum length allowed ({{maxlen}})', { maxlen: 64 }))
            .required(t('This field is required')),
        document_number: Yup.string()
            .max(64, t('The text exceeds the maximum length allowed ({{maxlen}})', { maxlen: 64 }))
            .when('country', {
                is: (country) => country === 'CL',
                then: Yup.string()
                    .test('chilean-rut', t('Document number must be a valid chilean RUT'), (rut) => {
                        if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rut)) {
                            return false;
                        }

                        const parts = rut.split('-')
                        const number = parts[0]
                        const dv = parts[1] === 'K' ? 'k' : parts[1]

                        const check = (T) => {
                            let M = 0,
                                S = 1;
                            for (; T; T = Math.floor(T / 10))
                                S = (S + T % 10 * (9 - M++ % 6)) % 11;
                            return String(S ? S - 1 : 'k');
                        }

                        return check(number) === dv
                    })
            })
            .required(t('This field is required')),
        vat: Yup.string()
            .max(32, t('The text exceeds the maximum length allowed ({{maxlen}})', { maxlen: 32 }))
            .when('company_country', {
                is: (company_country) => company_country === 'CL',
                then: Yup.string()
                    .required(t('This field is required'))
                    .test('chilean-rut', t('Document number must be a valid chilean RUT'), (rut) => {
                        if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rut)) {
                            return false;
                        }

                        const parts = rut.split('-')
                        const number = parts[0]
                        const dv = parts[1] === 'K' ? 'k' : parts[1]

                        const check = (T) => {
                            let M = 0,
                                S = 1;
                            for (; T; T = Math.floor(T / 10))
                                S = (S + T % 10 * (9 - M++ % 6)) % 11;
                            return String(S ? S - 1 : 'k');
                        }

                        return check(number) === dv
                    })
            }),
        birthdate: Yup.date()
            .max(new Date(), t('You must enter a valid birthdate date')),
        company_name: Yup.string()
            .max(128, t('The text exceeds the maximum length allowed ({{maxlen}})', { maxlen: 128 }))
            .required(t('This field is required')),
        fantasy_name: Yup.string()
            .max(64, t('The text exceeds the maximum length allowed ({{maxlen}})', { maxlen: 64 }))
            .required(t('This field is required')),
        company_type: Yup.string()
            .max(128, t('The text exceeds the maximum length allowed ({{maxlen}})', { maxlen: 128 }))
            .required(t('This field is required')),
        company_description: Yup.string()
            .max(255, t('The text exceeds the maximum length allowed ({{maxlen}})', { maxlen: 255 })),
        company_phone_number: Yup.string()
            .max(45, t('The text exceeds the maximum length allowed ({{maxlen}})', { maxlen: 45 }))
            .required(t('This field is required')),
        phone_number: Yup.string()
            .max(45, t('The text exceeds the maximum length allowed ({{maxlen}})', { maxlen: 45 }))
            .required(t('This field is required')),
        website: Yup.string()
            .max(255, t('The text exceeds the maximum length allowed ({{maxlen}})', { maxlen: 255 }))
            .when("company_country", {
                is: (company_country) => company_country !== 'CL',
                then: Yup.string().required('This field is required for countries other than Chile')
            })
            .matches(
                /((https|http):\/\/)(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?/,
                t('This field must be a valid url')
            ),
        industry: Yup.string()
            .max(128, t('The text exceeds the maximum length allowed ({{maxlen}})', { maxlen: 128 }))
            .required(t('This field is required'))
    })

    useEffect(() => {
        const init = () => retrieveData()

        init()
    }, [retrieveData])

    return (
        <>
            <Card>
                <CardContent>
                    <Stack direction="row" justifyContent="space-between" spacing={2}>
                        <PageTitle
                            title={t('Update merchant information')}
                        />

                        <Hidden xlUp={true}>
                            {userId ? <MerchantMenu id={userId} /> : ''}
                        </Hidden>
                    </Stack>

                    <MerchantAlias />

                    <Grid container spacing={2}>
                        <Grid item xs={12} xl={2}>
                            <MerchantSubmenu current='edit' id={userId} />
                        </Grid>
                        <Grid item xs={12} xl={10}>
                            {
                                loading ? <FormSkeletonWrapper /> :
                                    <MerchantForm
                                        isNew={false}
                                        handleSubmit={handleSubmit}
                                        handleCancel={handleCancel}
                                        initialFormState={merchant}
                                        FORM_VALIDATION={FORM_VALIDATION}
                                        countries={countries}
                                        hasPermissions={hasPermissions(MERCHANTS__UPDATE)}
                                    />
                            }
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}
