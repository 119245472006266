const agents = [
    {
        code: 'B67619429',
        name: 'Razvantis SL'
    },
    {
        code: 'B67202101',
        name: 'OXON SOFT TECHNOLOGY SL'
    },
    {
        code: 'B67062869',
        name: 'Full and Art SL'
    },
    {
        code: 'B27727692',
        name: 'Vitel Comunicaciones Noreste SL'
    },
    {
        code: 'B01845452',
        name: 'SIG & SOFT SL'
    },
    {
        code: 'B16539785',
        name: 'TECHVEDIC INTERNATIONAL SL'
    },
    {
        code: 'B16513921',
        name: 'EUNICOM PLAYER SL'
    },
    {
        code: 'B66953993',
        name: 'TRISTAN ECONOMYCS SL'
    },
    {
        code: 'B02873644',
        name: 'CRYPTOGRAFIC IBERIA'
    },
    {
        code: 'B02833663',
        name: 'COUDGENICS'
    },
    {
        code: 'B02711547',
        name: 'LICENCE BROKER SL'
    }, {
        code: 'B16986945',
        name: 'TIQUEGAMES SL'
    },
    {
        code: 'B97528780',
        name: 'INTERNET & BUSINESS SYSTEM SL'
    },
]

export { agents }