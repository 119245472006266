import { useEffect, useState } from 'react'
import UserForm from './UserForm'
import { Card, CardContent } from '@mui/material'
import FormSkeletonWrapper from '../../SkeletonUI/FormSkeletonWrapper/FormSkeletonWrapper'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import PageTitle from '../../Misc/PageTitle'
import { parseErrors } from '../../../services/utils'
import { toast } from 'react-toastify'
import useIsMounted from '../../../hooks/useIsMounted'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import useHasPermissions from '../../../hooks/useHasPermissions'
import { USERS__CREATE } from '../../../enums/Caps'


export default function UserCreate() {
    const { t } = useTranslation()

    const hasPermissions = useHasPermissions()

    const isMounted = useIsMounted()

    const api = useAxiosPrivate()

    const navigate = useNavigate()

    const handleSubmit = (values, { setFieldError }) => {
        /**
         * No deberia ser oblgatorio. Corregir en backend
         */

        values.language = 'ES'
        api.post('/internal/users/', values)
            .then(({data: {data}}) => {
                if (isMounted()) {
                    toast.success(t('Account has been created'))
                    navigate(`/users/${data.id}/edit`)
                }
            })
            .catch(({ response }) => {
                if (isMounted()) {
                    const errors = parseErrors(response)

                    if (typeof errors === 'object') {
                        for (let i in errors) {
                            setFieldError(errors[i].field, t(errors[i].message), false)
                        }
                    } else if (typeof errors === 'string') {
                        toast.error(t(errors))
                    } else {
                        toast.error(t('Something went wrong'))
                    }
                }
            })
    }

    const handleCancel = () => {
        navigate('/users')
    }

    const initialFormState = {
        email: '',
        password: '',
        password_confirmation: ''
    }

    const FORM_VALIDATION = Yup.object().shape({
        email: Yup.string()
            .email(t('You must enter a valid email address'))
            .required(t('Email address is required')),
        password: Yup.string()
            .required(t('Password is required'))
            .min(8, t('Password is too short - should be 8 chars minimum'))
            .matches(/[a-zA-Z0-9]/, t('Password can only contain letters and numbers')),
        password_confirmation: Yup.string()
            .required(t('Password confirmation is required'))
            .oneOf([Yup.ref('password'), null], t('Passwords must match'))
    })

    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setIsLoading(false), 1000)
    }, [])

    return (
        <>
            <Card>
                <CardContent>
                    <PageTitle title={t('Create new account')} />

                    {
                        isLoading ? <FormSkeletonWrapper /> :
                            <UserForm
                                isNew={true}
                                handleSubmit={handleSubmit}
                                handleCancel={handleCancel}
                                initialFormState={initialFormState}
                                FORM_VALIDATION={FORM_VALIDATION}
                                hasPermissions={hasPermissions(USERS__CREATE)}
                            />
                    }
                </CardContent>
            </Card>
        </>
    )
}
