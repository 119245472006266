import { Grid, Stack, Typography } from '@mui/material'
import { Formik, Form } from 'formik'
import TextfieldWrapper from '../../../FormUI/Textfield/TextfieldWrapper'
import SelectWrapper from '../../../FormUI/SelectWrapper/SelectWrapper'
import ButtonWrapper from '../../../FormUI/ButtonWrapper/ButtonWrapper'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import SubmitWrapper from '../../../FormUI/SubmitWrapper/SubmitWrapper'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import { currencies } from './../../../../enums/PaymentMethods'


export default function MethodForm({
    method,
    handleSubmit,
    initialFormState,
    FORM_VALIDATION
}) {
    const { t } = useTranslation()

    const navigate = useNavigate()

    return (
        <Formik
            initialValues={{
                ...initialFormState
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={handleSubmit}
        >
            <Form>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5">{method.name}</Typography>
                        <Typography>{method.provider}</Typography>
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <TextfieldWrapper
                            name="fee_percent"
                            label={t('Fee percent')}
                            type="number"
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <TextfieldWrapper
                            name="fee_fixed"
                            label={t('Fee fixed')}
                            type="number"
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <SelectWrapper
                            name="fee_fixed_currency"
                            label={t('Fee fixed currency')}
                            options={currencies}
                            translate={false}
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <TextfieldWrapper
                            name="waiting_time"
                            label={t('Waiting time')}
                            type="number"
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2}>
                            <SubmitWrapper>{t('Save')}</SubmitWrapper>
                            <ButtonWrapper
                                variant="text"
                                onClick={() => navigate('/payment-methods')}
                                startIcon={<CloseIcon />}>{t('Clear')}</ButtonWrapper>
                        </Stack>
                    </Grid>
                </Grid>
            </Form>
        </Formik>
    )
}
