import React from 'react';
import { useFormikContext } from 'formik';
import { Button } from '@mui/material';

export default function SubmitWrapper({
    children,
    ...otherProps
}) {
    const { submitForm } = useFormikContext();

    const handleSubmit = () => {
        submitForm();
    };

    const configButton = {
        variant: 'contained',
        color: 'primary',
        ...otherProps,
        onClick: handleSubmit
    };

    return (
        <Button
            {...configButton}
        >
            {children}
        </Button>
    )
}
