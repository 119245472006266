import React from 'react'
import { Grid, Stack, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import MethodFeeForm from './MethodFeeForm'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { replaceNullValues } from '../../../../services/utils'


export default function MethodsForm({
    userId,
    methods,
    handleUpdateMethods
}) {
    const { t } = useTranslation()

    const navigate = useNavigate()

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={2} mb={2}>
                <Stack direction="row" spacing={2}>
                    <Button
                        variant="text"
                        onClick={() => navigate('/merchants')}
                        startIcon={<ArrowBackIcon />}>{t('Go back')}</Button>
                </Stack>
            </Grid>

            <Grid item xs={12}>
                {methods.map((m, idx) => (
                    <React.Fragment key={idx}>
                        {m.default_method && (<MethodFeeForm
                            method={replaceNullValues(m)}
                            userId={userId}
                            handleUpdateMethods={handleUpdateMethods}
                        />)}
                    </React.Fragment>))
                }
            </Grid>
        </Grid>
    )
}
