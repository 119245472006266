import { Stack } from '@mui/material'
import React from 'react'

export default function ToolsStack({
    children,
    ...otherProps
}) {
    return (
        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            {...otherProps}
        >
            {children}
        </Stack>
    )
}
