import { useState, useEffect } from 'react'
import { Card, CardContent } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import MethodProviderForm from './MethodProviderForm'
import { useTranslation } from 'react-i18next'
import PageTitle from '../../Misc/PageTitle'
import { parseErrors } from '../../../services/utils'
import FormSkeletonWrapper from '../../SkeletonUI/FormSkeletonWrapper/FormSkeletonWrapper'
import useIsMounted from '../../../hooks/useIsMounted'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { METHOD_PROVIDERS__CREATE } from '../../../enums/Caps'
import useHasPermissions from '../../../hooks/useHasPermissions'


export default function MethodProviderCreate() {
    const { t } = useTranslation()

    const hasPermissions = useHasPermissions()

    const isMounted = useIsMounted()

    const api = useAxiosPrivate()

    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)

    const handleSubmit = (values, { setFieldError }) => {
        api.post(`/internal/payment-methods/providers/`, values)
            .then(() => {
                if (isMounted()) {
                    toast.success(t('Element has been created'))
                    navigate('/method-providers')
                }
            })
            .catch(({ response }) => {
                if (isMounted()) {
                    const errors = parseErrors(response)

                    if (typeof errors === 'object') {
                        for (let i in errors) {
                            setFieldError(errors[i].field, t(errors[i].message), false)
                        }
                    } else if (typeof errors === 'string') {
                        toast.error(t(errors))
                    } else {
                        toast.error(t('Something went wrong'))
                    }
                }
            })
    }

    const handleCancel = () => {
        navigate('/method-providers')
    }

    const initialFormState = {
        name: '',
        api_url: '',
        api_key: '',
        secret_key: '',
        code: '',
        countries: '',
        ip_whitelist: '',
        provider_currency: '',
    }

    const FORM_VALIDATION = Yup.object().shape({
        name: Yup.string()
            .required(t('This field is required')),
        code: Yup.string()
            .required(t('This field is required')),
        api_url: Yup.string(),
        api_key: Yup.string(),
        secret_key: Yup.string(),
        provider_currency: Yup.string()
            .matches(/[A-Za-z]{3}/, t('You must enter a valid currency code'))
            .required(t('This field is required')),
    })

    useEffect(() => {
        const init = () => {
            setLoading(false)
        }

        init()
    }, [])

    return (
        <>
            <Card>
                <CardContent>
                    <PageTitle title={t('Create new payment method')} />

                    {
                        loading ? <FormSkeletonWrapper /> :
                            <MethodProviderForm
                                isNew={true}
                                handleSubmit={handleSubmit}
                                handleCancel={handleCancel}
                                initialFormState={initialFormState}
                                FORM_VALIDATION={FORM_VALIDATION}
                                hasPermissions={hasPermissions(METHOD_PROVIDERS__CREATE)}
                            />
                    }
                </CardContent>
            </Card>
        </>
    )
}
