import React, { useState } from 'react'
import { Grid, Stack, TextField, Typography, MenuItem } from '@mui/material'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify'
import { isGreaterThan, parseErrors, replaceEmptyValues } from '../../../../services/utils'
import { currencies, method_status } from '../../../../enums/Merchants'
import { LoadingButton } from '@mui/lab'
import useIsMounted from '../../../../hooks/useIsMounted'
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'
import { MERCHANT_PAYMENT_METHODS__UPDATE } from '../../../../enums/Caps'
import useHasPermissions from '../../../../hooks/useHasPermissions'


const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'left',
    width: '100%',
    fontWeight: '500',
    color: theme.palette.text.secondary,
}))

export default function MethodFeeForm({
    method,
    userId,
    handleUpdateMethods
}) {
    const { t } = useTranslation()

    const hasPermissions = useHasPermissions()

    const isMounted = useIsMounted()

    const api = useAxiosPrivate()

    const [data, setData] = useState({
        ...method, has_fees: method.available_methods.filter(m => m.current_fee).length > 0
    })

    const [working, setWorking] = useState()

    const handleChange = (event) => {
        const value = event.target.value

        setData({
            ...data,
            [event.target.name]: value
        })
    }

    const handleChangeProvider = (event) => {
        const value = event.target.value

        const payment_method = data.available_methods.filter(m => m.id === value)

        setData({
            ...data,
            default_method: payment_method[0],
            payment_method: payment_method[0]
        })
    }

    /**
     * Agregar validacion de que metodo tiene comisiones 
     */
    const handleSubmit = (event) => {
        event.preventDefault()

        const post_data = {
            id: data.id,
            status: data.status,
            fee_percent: data.default_method && data.fee_percent !== data.default_method?.current_fee?.fee_percent ? data.fee_percent : null,
            fee_fixed: data.fee_fixed !== data.default_method?.current_fee?.fee_fixed ? data.fee_fixed : null,
            fee_fixed_currency: data.fee_fixed_currency !== data.default_method?.current_fee?.fee_fixed_currency ? data.fee_fixed_currency : null,
            waiting_time: data.waiting_time !== data.default_method?.current_fee?.waiting_time ? data.waiting_time : null,
            payment_method: data?.default_method && !data.default_method.is_default ? data.default_method.id : '',
            method_key: data.method_key
        }

        setWorking(true)

        api.put(`/internal/merchants/${userId}/payment-methods/${post_data.id}/`, replaceEmptyValues(post_data))
            .then(({ data: { data } }) => {
                if (isMounted()) {
                    handleUpdateMethods(data)
                    toast.success(t('The information has been updated'))
                }
            })
            .catch(({ response }) => {
                if (isMounted()) {
                    const errors = parseErrors(response)

                    if (typeof errors === 'string') {
                        toast.error(t(errors))
                    } else {
                        toast.error(t('Something went wrong'))
                    }
                }
            })
            .finally(() => isMounted() && setWorking(false))
    }

    return (
        <form onSubmit={handleSubmit}>
            <Grid container item spacing={2} alignItems="center" mb={3}>
                <Grid item xs={12} md={2}>
                    <Stack direction="row" spacing={2}>
                        <Item elevation={0}>
                            <Typography>{data?.default_method?.name}</Typography>
                            <Typography sx={{
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                width: '180px',
                                whiteSpace: 'nowrap',
                                fontWeight: 'bold'
                            }}>{data?.default_method?.countries}</Typography>
                        </Item>
                    </Stack>
                </Grid>

                <Grid item xs={12} md={9}>
                    <Grid container item spacing={1}>
                        <Grid item xs={6} md={2}>
                            <TextField
                                name={`fee_fixed`}
                                label={t('Fee fixed')}
                                type="number"
                                fullWidth={true}
                                value={
                                    isGreaterThan(data.fee_fixed) ?
                                        data.fee_fixed : // merchant fee
                                        data.payment_method && isGreaterThan(data.payment_method.current_fee) ?
                                            data.payment_method.current_fee.fee_fixed : // method fee
                                            data.default_method && data.default_method.current_fee ?
                                                data.default_method.current_fee.fee_fixed :
                                                ''
                                }
                                onChange={handleChange}
                                disabled={!hasPermissions(MERCHANT_PAYMENT_METHODS__UPDATE)}
                            />
                        </Grid>

                        <Grid item xs={6} md={2}>
                            <TextField
                                name={`fee_fixed_currency`}
                                label={t('Currency')}
                                select={true}
                                fullWidth={true}
                                value={
                                    data.fee_fixed_currency ?
                                        data.fee_fixed_currency :
                                        data.payment_method && data.payment_method.current_fee ?
                                            data.payment_method.current_fee.fee_fixed_currency :
                                            data.default_method && data.default_method.current_fee ?
                                                data.default_method.current_fee.fee_fixed_currency :
                                                ''
                                }
                                onChange={handleChange}
                                disabled={!hasPermissions(MERCHANT_PAYMENT_METHODS__UPDATE)}
                            >
                                {Object.keys(currencies).map((k, idx) => (
                                    <MenuItem value={k} key={idx}>
                                        {currencies[k]}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid item xs={6} md={2}>
                            <TextField
                                name={`fee_percent`}
                                label={t('Fee percent')}
                                type="number"
                                fullWidth={true}
                                value={
                                    isGreaterThan(data.fee_percent) ?
                                        data.fee_percent :
                                        data.payment_method && isGreaterThan(data.payment_method.current_fee) ?
                                            data.payment_method.current_fee.fee_percent :
                                            data.default_method && data.default_method.current_fee ?
                                                data.default_method.current_fee.fee_percent :
                                                ''
                                }
                                onChange={handleChange}
                                InputProps={{
                                    endAdornment: <Typography variant='h6'>%</Typography>,
                                }}
                                disabled={!hasPermissions(MERCHANT_PAYMENT_METHODS__UPDATE)}
                            />
                        </Grid>

                        <Grid item xs={6} md={2}>
                            <TextField
                                name={`waiting_time`}
                                label={t('Waiting time')}
                                type="number"
                                fullWidth={true}
                                value={
                                    data.waiting_time ?
                                        data.waiting_time :
                                        data.payment_method && data.payment_method.current_fee ?
                                            data.payment_method.current_fee.waiting_time :
                                            data.default_method && data.default_method.current_fee ?
                                                data.default_method.current_fee.waiting_time :
                                                ''
                                }
                                onChange={handleChange}
                                disabled={!hasPermissions(MERCHANT_PAYMENT_METHODS__UPDATE)}
                            />
                        </Grid>

                        <Grid item xs={6} md={2}>
                            <TextField
                                name={`default_method.id`}
                                label={t('Provider')}
                                select={true}
                                fullWidth={true}
                                value={
                                    data.payment_method ? data.payment_method.id : data.default_method ? data.default_method.id : ''
                                }
                                onChange={handleChangeProvider}
                                disabled={!hasPermissions(MERCHANT_PAYMENT_METHODS__UPDATE)}
                            >
                                {data.available_methods.map((p, idx) => (
                                    <MenuItem
                                        value={p.id}
                                        key={idx}
                                        disabled={!p.current_fee}
                                    >
                                        {p.provider} {!p.current_fee ? `(${t('There are no commissions')})` : ''}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid item xs={6} md={2}>
                            <TextField
                                name={`status`}
                                label={t('Status')}
                                select={true}
                                fullWidth={true}
                                value={data.status}
                                onChange={handleChange}
                                disabled={!data.has_fees || !hasPermissions(MERCHANT_PAYMENT_METHODS__UPDATE)}
                            >
                                {Object.keys(method_status).map((k, idx) => (
                                    <MenuItem value={k} key={idx}>
                                        {t(method_status[k])}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <TextField
                                name={`method_key`}
                                label={t('Method key')}
                                type="text"
                                fullWidth={true}
                                value={data.method_key}
                                onChange={handleChange}
                                disabled={!hasPermissions(MERCHANT_PAYMENT_METHODS__UPDATE)}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={1}>
                    {hasPermissions(MERCHANT_PAYMENT_METHODS__UPDATE) ? (<LoadingButton
                        variant='contained'
                        fullWidth={true}
                        color='primary'
                        type='submit'
                        disabled={!data.has_fees}
                        loading={working}
                    >
                        {t('Save')}
                    </LoadingButton>) : ''}
                </Grid>
            </Grid>
        </form>
    )
}
