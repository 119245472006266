import { useEffect } from 'react'
import UserForm from './UserForm'
import { Card, CardContent } from '@mui/material'
import { useState } from 'react'
import FormSkeletonWrapper from '../../SkeletonUI/FormSkeletonWrapper/FormSkeletonWrapper'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import PageTitle from '../../Misc/PageTitle'
import { toast } from 'react-toastify'
import { parseErrors } from '../../../services/utils'
import useIsMounted from '../../../hooks/useIsMounted';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import useHasPermissions from '../../../hooks/useHasPermissions'
import { USERS__UPDATE } from '../../../enums/Caps'


export default function UserEdit() {
    const { t } = useTranslation()

    const hasPermissions = useHasPermissions()

    const navigate = useNavigate()

    const isMounted = useIsMounted()

    const api = useAxiosPrivate()

    const { userId } = useParams()

    const [loading, setLoading] = useState(true)

    const [user, setUser] = useState()

    const [permissions, setPermissions] = useState([])

    const handleCancel = () => {
        navigate('/users')
    }

    const handleSubmit = (values, { setFieldError }) => {
        api.put(`/internal/users/${userId}/`, values)
            .then(() => {
                if (isMounted()) {
                    toast.success(t('Account has been updated'))
                    navigate('/users')
                }
            })
            .catch(({ response }) => {
                if (isMounted()) {
                    const errors = parseErrors(response)

                    if (typeof errors === 'object') {
                        for (let i in errors) {
                            setFieldError(errors[i].field, t(errors[i].message), false)
                        }
                    } else if (typeof errors === 'string') {
                        toast.error(t(errors))
                    } else {
                        toast.error(t('Something went wrong'))
                    }
                }
            })
    }

    const FORM_VALIDATION = Yup.object().shape({
        email: Yup.string()
            .email(t('You must enter a valid email address'))
            .required(t('Email address is required'))
    })

    useEffect(() => {
        const getUser = () => {
            api.get(`/internal/users/${userId}/`)
                .then(({ data: { data } }) => {
                    if (isMounted()) {
                        setUser(data)
                        setPermissions(data.permissions.map(p => p.permission))
                    }
                })
                .catch(() => isMounted() && toast.error(t('Something went wrong')))
                .finally(() => isMounted() && setLoading(false))
        }

        getUser()
    }, [userId, t, api, isMounted])

    return (
        <>
            <Card>
                <CardContent>
                    <PageTitle title={t('Update account')} />

                    {
                        loading ? <FormSkeletonWrapper /> :
                            <UserForm
                                isNew={false}
                                handleSubmit={handleSubmit}
                                handleCancel={handleCancel}
                                initialFormState={user}
                                FORM_VALIDATION={FORM_VALIDATION}
                                hasPermissions={hasPermissions(USERS__UPDATE)}
                                user_permissions={permissions}
                            />
                    }
                </CardContent>
            </Card>
        </>
    )
}
