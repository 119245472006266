import { createStore } from 'redux'
// import reducers from '../reducers'
import { authReducer } from '../reducers/authReducer';
// import thunk from 'redux-thunk'

export const store = createStore(
    authReducer,
    // reducers,
    {}
    // applyMiddleware(thunk)
);