import { AppBar, IconButton, Toolbar, Typography } from '@mui/material'
import { Box } from '@mui/system'
import Logo from './Navtabs/Logo'
import ProfileTab from './Navtabs/ProfileTab'
import MenuIcon from '@mui/icons-material/Menu'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useLoggedUser from '../../hooks/useLoggedUser'
import { BASE_URL } from 'api'


export default function Navbar({
    handleDrawerToggle,
    drawerWidth
}) {
    const [email, setEmail] = useState()

    const loggedUser = useLoggedUser()

    const navigate = useNavigate()

    useEffect(() => {
        if (!loggedUser || !loggedUser.email) {
            navigate('/auth')
        }

        const { email } = loggedUser

        setEmail(email)
    }, [navigate, loggedUser])

    return (
        <AppBar
            position="fixed"
            sx={{
                color: 'secondary',
                zIndex: (theme) => theme.zIndex.drawer + 1,
                borderBottom: !/gateway/.test(BASE_URL) ? '5px solid red' : 'none'
            }}
        >

            <Toolbar>
                <IconButton
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { sm: 'none' }, color: '#fff' }}
                >
                    <MenuIcon />
                </IconButton>

                <Logo />

                <Box sx={{ flexGrow: 1 }} />

                <Box style={{ display: 'flex', alignItems: 'center' }}>
                    {email ? <Typography>{email}</Typography> : ''}
                    {/* <NotificationsTab /> */}
                    <ProfileTab />
                </Box>
            </Toolbar>
        </AppBar>
    );
}
