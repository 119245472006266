import { Checkbox, FormControlLabel, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { useRef, useState } from 'react'
import { Formik, Form } from 'formik'
import TextfieldWrapper from '../../FormUI/Textfield/TextfieldWrapper'
import SelectWrapper from '../../FormUI/SelectWrapper/SelectWrapper'
import SelectApiWrapper from '../../FormUI/SelectWrapper/SelectApiWrapper'
import ButtonWrapper from '../../FormUI/ButtonWrapper/ButtonWrapper'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import SubmitWrapper from '../../FormUI/SubmitWrapper/SubmitWrapper'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import DateTimePickerWrapper from '../../FormUI/DateTimePickerWrapper/DateTimePickerWrapper'
import { languages, currencies } from './../../../enums/Merchants'
import CachedIcon from '@mui/icons-material/Cached'
import { generateRandomPassword } from '../../../services/utils'


export default function MerchantForm({
    isNew,
    handleSubmit,
    initialFormState,
    FORM_VALIDATION,
    countries,
    hasPermissions
}) {
    const { t } = useTranslation()

    const navigate = useNavigate()

    const [contacts, setContacts] = useState({
        bussiness: false,
        operational: false,
        service: false
    })

    const ref = useRef()

    const handleNewPassword = () => {
        ref.current.setFieldValue('password', generateRandomPassword())
    }

    const handleChange = (e, key) => {
        setContacts({
            ...contacts,
            [key]: e.target.checked
        })

        const tmp = ref.current.values._contacts[key]

        ref.current.setFieldValue('_contacts', {
            ...ref.current.values._contacts,
            [key]: {
                ...tmp,
                first_name: e.target.checked ? ref.current.values.first_name : '',
                last_name: e.target.checked ? ref.current.values.last_name : '',
                email: e.target.checked ? ref.current.values.email : '',
                phone: e.target.checked ? ref.current.values.phone_number : '',
            }
        })
    }

    return (
        <Formik
            innerRef={ref}
            initialValues={{
                ...initialFormState
            }}
            validationSchema={FORM_VALIDATION}
            enableReinitialize
            onSubmit={handleSubmit}
        >
            {({
                values
            }) => (<Form autoComplete='off'>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5">{t('Account information')}</Typography>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <SelectApiWrapper
                            name="country"
                            label={t('Country')}
                            options={countries}
                            translate={false}
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    {isNew ? <Grid item xs={12} md={2}>
                        <SelectWrapper
                            name="language"
                            label={t('Language')}
                            options={languages}
                            disabled={!hasPermissions}
                        />
                    </Grid> : ''}

                    {isNew ? <Grid item xs={12} md={2}>
                        <SelectWrapper
                            name="settlement_currency"
                            label={t('Settlement currency')}
                            options={currencies}
                            translate={false}
                            disabled={!hasPermissions}
                        />
                    </Grid> : ''}

                    <Box width="100%" />

                    <Grid item xs={12} md={6}>
                        <TextfieldWrapper
                            name="email"
                            label={t('Email address')}
                            type="email"
                            inputProps={{
                                maxLength: 128
                            }}
                            disabled={!isNew || !hasPermissions}
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <TextfieldWrapper
                            name="username"
                            label={t('Alias')}
                            type="text"
                            inputProps={{
                                maxLength: 45
                            }}
                            disabled={!isNew || !hasPermissions}
                        />
                    </Grid>

                    {
                        isNew && (<Grid item xs={12} md={3}>
                            <Grid container spacing={2} alignItems="center" justifyContent="center">
                                <Grid item xs={10}>
                                    <TextfieldWrapper
                                        name="password"
                                        label={t('Password')}
                                        type="text"
                                        disabled={!hasPermissions}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <Tooltip title={t('Generate new password')}>
                                        <IconButton onClick={handleNewPassword}>
                                            <CachedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>

                        </Grid>)
                    }

                    <Box width="100%" mt={2} />

                    <Grid item xs={12}>
                        <Typography variant="h5">{t('Personal information')}</Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextfieldWrapper
                            name="first_name"
                            label={t('First name')}
                            type="text"
                            inputProps={{
                                maxLength: 64
                            }}
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextfieldWrapper
                            name="last_name"
                            label={t('Last name')}
                            type="text"
                            inputProps={{
                                maxLength: 64
                            }}
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextfieldWrapper
                            name="document_number"
                            label={values.country === 'CL' ? t('RUT') : t('Document number')}
                            placeholder={values.country === 'CL' ? 'xxxxxxxx-x' : ''}
                            type="text"
                            inputProps={{
                                maxLength: 32
                            }}
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <TextfieldWrapper
                            name="phone_number"
                            label={t('Phone number')}
                            type="text"
                            placeholder='+56991234556'
                            inputProps={{
                                maxLength: 45
                            }}
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <DateTimePickerWrapper
                            name="birthdate"
                            label={t('Birthdate')}
                            max={new Date()}
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Box width="100%" mt={2} />

                    {isNew && hasPermissions ? (<>
                        <Grid item xs={12}>
                            <Typography variant="h5">{t('Contact information')}</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="h6">{t('Bussiness contact')}</Typography>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={contacts.bussiness}
                                    onChange={(e) => handleChange(e, 'bussiness')}
                                />}
                                label="Utilizar información del representante legal"
                                disabled={!values.email}
                            />
                        </Grid>

                        <Grid item xs={12} md={8}>
                            <TextfieldWrapper
                                name="_contacts.bussiness.email"
                                label={t('Email address')}
                                type="email"
                                inputProps={{
                                    maxLength: 128
                                }}
                                disabled={contacts.bussiness}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <TextfieldWrapper
                                name="_contacts.bussiness.phone"
                                label={t('Phone number')}
                                type="text"
                                placeholder='+56991234556'
                                inputProps={{
                                    maxLength: 45
                                }}
                                disabled={contacts.bussiness}
                            />
                        </Grid>

                        <Box width="100%" />

                        <Grid item xs={12} md={6}>
                            <TextfieldWrapper
                                name="_contacts.bussiness.first_name"
                                label={t('First name')}
                                type="text"
                                inputProps={{
                                    maxLength: 64
                                }}
                                disabled={contacts.bussiness}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextfieldWrapper
                                name="_contacts.bussiness.last_name"
                                label={t('Last name')}
                                type="text"
                                inputProps={{
                                    maxLength: 64
                                }}
                                disabled={contacts.bussiness}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="h6">{t('Operational contact')}</Typography>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={contacts.operational}
                                    onChange={(e) => handleChange(e, 'operational')}
                                />}
                                label="Utilizar información del representante legal"
                                disabled={!values.email}
                            />
                        </Grid>

                        <Grid item xs={12} md={8}>
                            <TextfieldWrapper
                                name="_contacts.operational.email"
                                label={t('Email address')}
                                type="email"
                                inputProps={{
                                    maxLength: 128
                                }}
                                disabled={contacts.operational}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <TextfieldWrapper
                                name="_contacts.operational.phone"
                                label={t('Phone number')}
                                type="text"
                                placeholder='+56991234556'
                                inputProps={{
                                    maxLength: 45
                                }}
                                disabled={contacts.operational}
                            />
                        </Grid>

                        <Box width="100%" />

                        <Grid item xs={12} md={6}>
                            <TextfieldWrapper
                                name="_contacts.operational.first_name"
                                label={t('First name')}
                                type="text"
                                inputProps={{
                                    maxLength: 64
                                }}
                                disabled={contacts.operational}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextfieldWrapper
                                name="_contacts.operational.last_name"
                                label={t('Last name')}
                                type="text"
                                inputProps={{
                                    maxLength: 64
                                }}
                                disabled={contacts.operational}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="h6">{t('Customer service contact')}</Typography>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={contacts.service}
                                    onChange={(e) => handleChange(e, 'service')}
                                />}
                                label="Utilizar información del representante legal"
                                disabled={!values.email}
                            />
                        </Grid>

                        <Grid item xs={12} md={8}>
                            <TextfieldWrapper
                                name="_contacts.service.email"
                                label={t('Email address')}
                                type="email"
                                inputProps={{
                                    maxLength: 128
                                }}
                                disabled={contacts.service}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <TextfieldWrapper
                                name="_contacts.service.phone"
                                label={t('Phone number')}
                                type="text"
                                placeholder='+56991234556'
                                inputProps={{
                                    maxLength: 45
                                }}
                                disabled={contacts.service}
                            />
                        </Grid>

                        <Box width="100%" />

                        <Grid item xs={12} md={6}>
                            <TextfieldWrapper
                                name="_contacts.service.first_name"
                                label={t('First name')}
                                type="text"
                                inputProps={{
                                    maxLength: 64
                                }}
                                disabled={contacts.service}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextfieldWrapper
                                name="_contacts.service.last_name"
                                label={t('Last name')}
                                type="text"
                                inputProps={{
                                    maxLength: 64
                                }}
                                disabled={contacts.service}
                            />
                        </Grid>
                    </>) : ''}

                    <Box width="100%" mt={2} />

                    <Grid item xs={12}>
                        <Typography variant="h5">{t('Company information')}</Typography>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <SelectApiWrapper
                            name="company_country"
                            label={t('Country')}
                            options={countries}
                            translate={false}
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <TextfieldWrapper
                            name="vat"
                            label={values.company_country === 'CL' ? t('RUT') : t('VAT')}
                            placeholder={values.company_country === 'CL' ? 'xxxxxxxx-x' : ''}
                            type="text"
                            inputProps={{
                                maxLength: 32
                            }}
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={12} md={6}>
                        <TextfieldWrapper
                            name="company_name"
                            label={t('Company name')}
                            type="text"
                            inputProps={{
                                maxLength: 128
                            }}
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextfieldWrapper
                            name="company_type"
                            label={t('Company type')}
                            type="text"
                            inputProps={{
                                maxLength: 128
                            }}
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextfieldWrapper
                            name="industry"
                            label={t('Industry')}
                            type="text"
                            inputProps={{
                                maxLength: 128
                            }}
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextfieldWrapper
                            name="fantasy_name"
                            label={t('Fantasy name')}
                            type="text"
                            inputProps={{
                                maxLength: 64
                            }}
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextfieldWrapper
                            name="company_phone_number"
                            label={t('Phone number')}
                            type="text"
                            placeholder='+56991234556'
                            inputProps={{
                                maxLength: 45
                            }}
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Grid item xs={12} md={8}>
                        <TextfieldWrapper
                            name="website"
                            label={t('Website')}
                            type="text"
                            inputProps={{
                                maxLength: 255
                            }}
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextfieldWrapper
                            name="company_description"
                            label={t('Company description')}
                            multiline
                            rows={2}
                            inputProps={{
                                maxLength: 255
                            }}
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2}>
                            {hasPermissions ? (<SubmitWrapper>{t('Save')}</SubmitWrapper>) : ''}
                            <ButtonWrapper
                                variant="text"
                                onClick={() => navigate(`/merchants?merchant=${ref.current?.values?.username}`)}
                                startIcon={<CloseIcon />}>{t('Cancel')}</ButtonWrapper>
                        </Stack>
                    </Grid>
                </Grid>
            </Form>)}
        </Formik>
    )
}
