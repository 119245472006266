import { Grid, Stack, Typography } from '@mui/material'
import { Formik, Form } from 'formik'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import TextfieldWrapper from '../../FormUI/Textfield/TextfieldWrapper'
import ButtonWrapper from '../../FormUI/ButtonWrapper/ButtonWrapper'
import SubmitWrapper from '../../FormUI/SubmitWrapper/SubmitWrapper'

export default function MethodProviderForm({
    isNew,
    handleSubmit,
    initialFormState,
    FORM_VALIDATION,
    hasPermissions
}) {
    const { t } = useTranslation()

    const navigate = useNavigate()

    return (
        <Formik
            initialValues={{
                ...initialFormState
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={handleSubmit}
        >
            <Form>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5">{t('Payment method providers')}</Typography>
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={12} md={3}>
                        <TextfieldWrapper
                            name="name"
                            label={t('Name')}
                            type="text"
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <TextfieldWrapper
                            name="code"
                            label={t('Code')}
                            type="text"
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <TextfieldWrapper
                            name="provider_currency"
                            label={t('Currency')}
                            type="text"
                            disabled={!hasPermissions}
                        // inputProps={{ maxLength: 3 }}
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={12} md={6}>
                        <TextfieldWrapper
                            name="api_key"
                            label={t('API key')}
                            type="text"
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextfieldWrapper
                            name="secret_key"
                            label={t('Secret key')}
                            type="text"
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextfieldWrapper
                            name="api_url"
                            label={t('API URL')}
                            type="text"
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <TextfieldWrapper
                            name="countries"
                            label={t('Countries')}
                            type="text"
                            placeholder='CL,AR'
                            disabled={!hasPermissions}
                        />
                        <Typography variant='caption'>{t('Add countries separated by commas in ISO 3166 format - Alpha-2')}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <TextfieldWrapper
                            name="ip_whitelist"
                            label={t('IP whitelist')}
                            type="text"
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2}>
                            {hasPermissions ? (<SubmitWrapper>{t('Save')}</SubmitWrapper>) : ''}
                            <ButtonWrapper
                                variant="text"
                                onClick={() => navigate('/method-providers')}
                                startIcon={<CloseIcon />}>{t('Cancel')}</ButtonWrapper>
                        </Stack>
                    </Grid>
                </Grid>
            </Form>
        </Formik>
    )
}
