import useAxiosPrivate from './useAxiosPrivate'

const minutes = 600

/**
 * Obtener una firma valida para API de cashouts. Se utilizara de esta manera mientras se concreta
 */
const useCashoutsSignature = () => {
    const api = useAxiosPrivate()

    const signature = async () => {
        const timestamp = (new Date()).getTime() + minutes * 60 * 1000

        const response = await api.post('/internal/signature/', {
            data: `477980:${timestamp}`
        })

        const credentials = {
            sign: response.data.data,
            key: `477980:${timestamp}`
        }

        localStorage.setItem('cashouts.k', JSON.stringify(credentials))

        return credentials
    }

    return signature;
}

export default useCashoutsSignature