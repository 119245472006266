import { useEffect, useState, useCallback } from 'react'
import MerchantForm from './MerchantForm'
import { Card, CardContent } from '@mui/material'
import FormSkeletonWrapper from '../../SkeletonUI/FormSkeletonWrapper/FormSkeletonWrapper'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import PageTitle from '../../Misc/PageTitle'
import { toast } from 'react-toastify'
import { parseErrors, removeNullValues } from '../../../services/utils'
import useIsMounted from '../../../hooks/useIsMounted'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import useHasPermissions from '../../../hooks/useHasPermissions'
import { MERCHANTS__CREATE } from '../../../enums/Caps'


export default function MerchantCreate() {
    const { t } = useTranslation()

    const hasPermissions = useHasPermissions()

    const navigate = useNavigate()

    const isMounted = useIsMounted()

    const api = useAxiosPrivate()

    const [loading, setLoading] = useState(true)

    const [countries, setCountries] = useState()

    const getCountries = useCallback(() => {
        api.get('/internal/countries/')
            .then(({ data: { data } }) => {
                if (isMounted()) {
                    const country_list = data.sort((a, b) => (a.name_es > b.name_es) ? 1 : ((b.name_es > a.name_es) ? -1 : 0))

                    setCountries(
                        country_list.map((c) => ({
                            id: c.code,
                            name: c.name_es
                        }))
                    )
                }
            })
            .catch(() => isMounted() && toast.error(t('Something went wrong')))
            .finally(() => isMounted() && setLoading(false))
    }, [t, api, isMounted])

    const handleSubmit = (values, { setFieldError }) => {
        values.alias = values.username
        values.payout_method = 2

        const contacts = values._contacts

        values.contacts = [
            contacts.service,
            contacts.bussiness,
            contacts.operational,
        ]

        api.post('/internal/merchants/', removeNullValues(values))
            .then(({ data: { data } }) => {
                if (isMounted()) {
                    toast.success(t('Merchant account has been created'))
                    localStorage.setItem('merchant', JSON.stringify(data))
                    navigate(`/merchants/${data.id}/config`)
                }
            })
            .catch(({ response }) => {
                if (isMounted()) {
                    const errors = parseErrors(response)

                    if (typeof errors === 'object') {
                        for (let i in errors) {
                            setFieldError(errors[i].field, t(errors[i].message), false)
                        }
                    } else if (typeof errors === 'string') {
                        toast.error(t(errors))
                    } else {
                        toast.error(t('Something went wrong'))
                    }
                }
            })
    }

    const handleCancel = () => {
        navigate('/merchants')
    }

    const initialFormState = {
        country: 'CL',
        language: 'ES',
        email: '',
        username: '',
        first_name: '',
        last_name: '',
        document_number: '',
        birthdate: '',
        company_name: '',
        fantasy_name: '',
        company_type: '',
        company_description: '',
        company_country: 'CL',
        company_phone_number: '',
        phone_number: '',
        website: '',
        industry: '',
        status: '',
        vat: '',
        settlement_currency: 'CLP',
        password: '',
        _contacts: {
            bussiness: {
                key: 'bussiness',
                title: 'Comercial',
                first_name: 'Nombre comercial',
                last_name: '',
                email: '',
                phone: ''
            },
            operational: {
                key: 'operational',
                title: 'Operaciones',
                first_name: 'Nombre Op',
                last_name: '',
                email: '',
                phone: ''
            },
            service: {
                key: 'service',
                title: 'Servicio al Cliente',
                first_name: 'Nombre Serv. al cliente',
                last_name: '',
                email: '',
                phone: ''
            },
        }
    }

    const FORM_VALIDATION = Yup.object().shape({
        country: Yup.string()
            .required(t('This field is required'))
            .min(2, t('Invalid country code'))
            .max(2, t('Invalid country code')),
        company_country: Yup.string()
            .required(t('This field is required'))
            .min(2, t('Invalid country code'))
            .max(2, t('Invalid country code')),
        settlement_currency: Yup.string()
            .required(t('This field is required'))
            .min(3, t('Invalid currency code'))
            .max(3, t('Invalid currency code')),
        language: Yup.string()
            .required(t('This field is required'))
            .min(2, t('Invalid country code'))
            .max(2, t('Invalid country code')),
        email: Yup.string()
            .email(t('You must enter a valid email address'))
            .max(128, t('The text exceeds the maximum length allowed ({{maxlen}})', { maxlen: 128 }))
            .required(t('Email address is required')),
        username: Yup.string()
            .max(45, t('The text exceeds the maximum length allowed ({{maxlen}})', { maxlen: 45 }))
            .required(t('This field is required')),
        first_name: Yup.string()
            .max(64, t('The text exceeds the maximum length allowed ({{maxlen}})', { maxlen: 64 }))
            .required(t('This field is required')),
        last_name: Yup.string()
            .max(64, t('The text exceeds the maximum length allowed ({{maxlen}})', { maxlen: 64 }))
            .required(t('This field is required')),
        document_number: Yup.string()
            .max(64, t('The text exceeds the maximum length allowed ({{maxlen}})', { maxlen: 64 }))
            .when('country', {
                is: (country) => country === 'CL',
                then: Yup.string()
                    .test('chilean-rut', t('Document number must be a valid chilean RUT'), (rut) => {
                        if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rut)) {
                            return false;
                        }

                        const parts = rut.split('-')
                        const number = parts[0]
                        const dv = parts[1] === 'K' ? 'k' : parts[1]

                        const check = (T) => {
                            let M = 0,
                                S = 1;
                            for (; T; T = Math.floor(T / 10))
                                S = (S + T % 10 * (9 - M++ % 6)) % 11;
                            return String(S ? S - 1 : 'k');
                        }

                        return check(number) === dv
                    })
            })
            .required(t('This field is required')),
        vat: Yup.string()
            .max(32, t('The text exceeds the maximum length allowed ({{maxlen}})', { maxlen: 32 }))
            .when('company_country', {
                is: (company_country) => company_country === 'CL',
                then: Yup.string()
                    .required(t('This field is required'))
                    .test('chilean-rut', t('Document number must be a valid chilean RUT'), (rut) => {
                        if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rut)) {
                            return false;
                        }

                        const parts = rut.split('-')
                        const number = parts[0]
                        const dv = parts[1] === 'K' ? 'k' : parts[1]

                        const check = (T) => {
                            let M = 0,
                                S = 1;
                            for (; T; T = Math.floor(T / 10))
                                S = (S + T % 10 * (9 - M++ % 6)) % 11;
                            return String(S ? S - 1 : 'k');
                        }

                        return check(number) === dv
                    })
            }),
        birthdate: Yup.date()
            .max(new Date(), t('You must enter a valid birthdate date')),
        company_name: Yup.string()
            .max(128, t('The text exceeds the maximum length allowed ({{maxlen}})', { maxlen: 128 }))
            .required(t('This field is required')),
        fantasy_name: Yup.string()
            .max(64, t('The text exceeds the maximum length allowed ({{maxlen}})', { maxlen: 64 }))
            .required(t('This field is required')),
        company_type: Yup.string()
            .max(128, t('The text exceeds the maximum length allowed ({{maxlen}})', { maxlen: 128 }))
            .required(t('This field is required')),
        company_description: Yup.string()
            .max(255, t('The text exceeds the maximum length allowed ({{maxlen}})', { maxlen: 255 })),
        company_phone_number: Yup.string()
            .max(45, t('The text exceeds the maximum length allowed ({{maxlen}})', { maxlen: 45 }))
            .required(t('This field is required')),
        phone_number: Yup.string()
            .max(45, t('The text exceeds the maximum length allowed ({{maxlen}})', { maxlen: 45 }))
            .required(t('This field is required')),
        website: Yup.string()
            .max(255, t('The text exceeds the maximum length allowed ({{maxlen}})', { maxlen: 255 }))
            .when("company_country", {
                is: (company_country) => company_country !== 'CL',
                then: Yup.string().required('This field is required for countries other than Chile')
            })
            .matches(
                /((https|http):\/\/)(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?/,
                t('This field must be a valid url')
            ),
        industry: Yup.string()
            .max(128, t('The text exceeds the maximum length allowed ({{maxlen}})', { maxlen: 128 }))
            .required(t('This field is required')),
        password: Yup.string()
            .required(t('Password is required'))
            .min(8, t('Password is too short - should be 8 characters minimum'))
            .matches(/[a-zA-Z0-9]/, t('Password can only contain letters and numbers')),
        _contacts: Yup.object().shape({
            bussiness: Yup.object().shape({
                email: Yup.string()
                    .email(t('You must enter a valid email address'))
                    .max(128, t('The text exceeds the maximum length allowed ({{maxlen}})', { maxlen: 128 }))
            }),
            operational: Yup.object().shape({
                email: Yup.string()
                    .email(t('You must enter a valid email address'))
                    .max(128, t('The text exceeds the maximum length allowed ({{maxlen}})', { maxlen: 128 }))
            }),
            service: Yup.object().shape({
                email: Yup.string()
                    .email(t('You must enter a valid email address'))
                    .max(128, t('The text exceeds the maximum length allowed ({{maxlen}})', { maxlen: 128 }))
            })
        })
    })

    useEffect(() => {
        getCountries()
    }, [getCountries])

    return (
        <>
            <Card>
                <CardContent>
                    <PageTitle title={t('Create new merchant account')} />

                    {
                        loading ? <FormSkeletonWrapper /> :
                            <MerchantForm
                                isNew={true}
                                handleSubmit={handleSubmit}
                                handleCancel={handleCancel}
                                initialFormState={initialFormState}
                                FORM_VALIDATION={FORM_VALIDATION}
                                countries={countries}
                                hasPermissions={hasPermissions(MERCHANTS__CREATE)}
                            />
                    }
                </CardContent>
            </Card>
        </>
    )
}
