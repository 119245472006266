import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useTranslation } from 'react-i18next'
import EditIcon from '@mui/icons-material/Edit'
import { useNavigate } from 'react-router-dom'
import TableBackdrop from '../../../TableUI/TableBackdrop'
import Pagination from '../../../Pagination/Pagination'
import TableActions from '../../../TableUI/TableActions'
import { Typography } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { MERCHANT_CONTACTS__DELETE, MERCHANT_CONTACTS__UPDATE } from '../../../../enums/Caps'
import PreviewIcon from '@mui/icons-material/Preview'
import useHasPermissions from '../../../../hooks/useHasPermissions'


export default function ContactsTable({
    data,
    pages = 1,
    page = 1,
    loading = false,
    handleOnPageChange,
    handleRemove
}) {
    const { t } = useTranslation()

    const hasPermissions = useHasPermissions()

    const navigate = useNavigate()

    const handleNavigate = (view) => {
        navigate(view)
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('Title')}</TableCell>
                            <TableCell>{t('Name')}</TableCell>
                            <TableCell>{t('Email')}</TableCell>
                            <TableCell>{t('Phone number')}</TableCell>
                            <TableCell align="center">&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.length ? data.map((row, idx) => (
                            <TableRow
                                key={idx}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <Typography noWrap>{row.title}</Typography>
                                </TableCell>
                                <TableCell>{`${row.first_name} ${row.last_name}`}</TableCell>
                                <TableCell>
                                    <Typography noWrap>{row.email}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography noWrap>{row.phone}</Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <TableActions actions={[
                                        {
                                            id: `edit-${row.id}`,
                                            handleClick: () => { handleNavigate(`${row.id}/edit`) },
                                            icon: hasPermissions(MERCHANT_CONTACTS__UPDATE) ? <EditIcon /> : <PreviewIcon />,
                                            title: hasPermissions(MERCHANT_CONTACTS__UPDATE) ? t('Edit') : t('View')
                                        },
                                        {
                                            id: `remove-${row.id}`,
                                            handleClick: () => { return handleRemove(row.id) },
                                            icon: <DeleteIcon />,
                                            title: t('Remove'),
                                            show: hasPermissions(MERCHANT_CONTACTS__DELETE)
                                        }
                                    ]} />
                                </TableCell>
                            </TableRow>
                        )) : <TableRow>
                            {
                                loading ? <TableBackdrop open={loading} /> : <TableCell sx={{ textAlign: 'center' }} colSpan={100}>{t('No records found')}</TableCell>
                            }
                        </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <Pagination
                handleOnPageChange={handleOnPageChange}
                page={page}
                pages={pages}
            />
        </>
    )
}
