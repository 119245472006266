import { Suspense } from 'react';
import AppRouter from "./routers/AppRouter"
import { createTheme, ThemeProvider } from '@mui/material'
import PageSkeletonWrapper from './components/SkeletonUI/PageSkeletonWrapper/PageSkeletonWrapper'
import { Provider } from 'react-redux'
import { store } from './store/store'
import { ToastContainer } from 'react-toastify'

import { AuthProvider } from './context/AuthProvider'

import 'react-toastify/dist/ReactToastify.css'


const theme = createTheme({
  palette: {
    primary: {
      main: '#0B1F3F'
    },
    secondary: {
      main: '#09097D'
    },
    text: {
      primary: '#0B1F3F',
      secondary: '#0B1F3F'
    },
    background: {
      default: '#f0f2f5'
    }
  },
  typography: {
    fontSize: 12
  }
});

export default function App() {
  return (
    <AuthProvider>
      <Suspense fallback={<PageSkeletonWrapper />}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <AppRouter />
          </ThemeProvider>
        </Provider>
      </Suspense>
    </AuthProvider>
  )
}
