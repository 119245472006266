import { useEffect, useState } from 'react'
import { Box, Grid, Stack } from "@mui/material"
import { Form, Formik } from "formik"
import { useTranslation } from 'react-i18next'
import SearchIcon from '@mui/icons-material/Search'
import UndoIcon from '@mui/icons-material/Undo'
import SubmitWrapper from '../../../FormUI/SubmitWrapper/SubmitWrapper'
import ButtonWrapper from "../../../FormUI/ButtonWrapper/ButtonWrapper"
import DateTimePickerWrapper from '../../../FormUI/DateTimePickerWrapper/DateTimePickerWrapper'
import { getFormatedDate, substractDays } from '../../../../services/utils'
import SelectWrapper from '../../../FormUI/SelectWrapper/SelectWrapper'
import axios from "axios"
import SelectApiWrapper from "../../../FormUI/SelectWrapper/SelectApiWrapper"
import useIsMounted from '../../../../hooks/useIsMounted'
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate';
import TextfieldWrapper from '../../../FormUI/Textfield/TextfieldWrapper'


export default function Filters({
    handleOnSearch,
    loading = false
}) {
    const { t } = useTranslation()

    const isMounted = useIsMounted()

    const api = useAxiosPrivate()

    const [methods, setMethods] = useState([])

    const [providers, setProviders] = useState([])

    const [countries, setCountries] = useState([])

    const today = new Date()

    const from_date = new Intl.DateTimeFormat("fr-CA", { year: "numeric", month: "2-digit", day: "2-digit" }).format(substractDays(30))

    const to_date = new Intl.DateTimeFormat("fr-CA", { year: "numeric", month: "2-digit", day: "2-digit" }).format(today)

    const initialFormState = {
        date__gte: from_date,
        date__lte: to_date,
        group_by: [],
        country: [],
        api_code: [],
        merchant__username: '',
        payment_method__method_provider: []
    }

    useEffect(() => {
        const retrieveData = () => {
            axios.all([
                api.get('/internal/payment-methods/grouped/'),
                api.get('/internal/payment-methods/providers/'),
                api.get('/internal/countries/')
            ])
                .then(axios.spread((...responses) => ({
                    methods: responses[0].data.data,
                    providers: responses[1].data.data,
                    countries: responses[2].data.data
                })))
                .then(({
                    methods,
                    providers,
                    countries
                }) => {
                    if (isMounted()) {
                        const payment_methods = methods.map(m => {
                            return {
                                id: m.api_code,
                                name: m.payment_methods[0].name
                            }
                        })
                            .sort((a, b) => (a.name > b.name) ? 1 : (a.name < b.name ? -1 : 0))

                        const country_list = countries.sort((a, b) => (a.name_es > b.name_es) ? 1 : ((b.name_es > a.name_es) ? -1 : 0))

                        setMethods(payment_methods)
                        setCountries(country_list.map(c => ({ id: c.code, name: c.name })))
                        setProviders(providers)
                    }
                })

        }

        retrieveData()
    }, [isMounted, api])

    return (
        <Formik
            initialValues={{
                ...initialFormState
            }}
            onSubmit={handleOnSearch}
        >
            {({ handleReset, handleSubmit, values }) => (<Form>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={2}>
                        <DateTimePickerWrapper
                            name="date__gte"
                            label={t('From date')}
                            size="small"
                            inputProps={{
                                max: getFormatedDate(today)
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <DateTimePickerWrapper
                            name="date__lte"
                            label={t('To date')}
                            size="small"
                            inputProps={{
                                max: getFormatedDate(today)
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={1}>
                        <SelectWrapper
                            name="group_by"
                            label={t('Group by')}
                            options={{
                                'date': 'Day',
                                'month': 'Month'
                            }}
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <SelectApiWrapper
                            name="api_code"
                            label={t('Payment method')}
                            options={[
                                { id: 'all', name: t('All') }, ...methods
                            ]}
                            size="small"
                            translate={false}
                            SelectProps={{
                                multiple: true,
                                value: values.api_code
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <SelectApiWrapper
                            name="payment_method__method_provider"
                            label={t('Provider')}
                            options={[
                                { id: 'all', name: t('All') }, ...providers
                            ]}
                            size="small"
                            translate={false}
                            SelectProps={{
                                multiple: true,
                                value: values.payment_method__method_provider
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <TextfieldWrapper
                            name="merchant__username"
                            label={t('Merchant')}
                            type="text"
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={1}>
                        <SelectApiWrapper
                            name="country"
                            label={t('Country')}
                            options={[
                                { id: 'all', name: t('All') }, ...countries
                            ]}
                            size="small"
                            translate={false}
                            SelectProps={{
                                multiple: true,
                                value: values.country
                            }}
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            <SubmitWrapper startIcon={<SearchIcon />} disabled={loading}>{t('Search')}</SubmitWrapper>
                            <ButtonWrapper
                                onClick={() => {
                                    handleReset()
                                    handleSubmit()
                                }}
                                variant="text"
                                disabled={loading}
                                startIcon={<UndoIcon />}>{t('Reset')}</ButtonWrapper>
                        </Stack>
                    </Grid>
                </Grid>
            </Form>)}
        </Formik>
    )
}
