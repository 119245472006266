import { useState, useEffect, useCallback } from 'react'
import MethodForm from './MethodForm'
import { Card, CardContent } from '@mui/material'
import FormSkeletonWrapper from '../../SkeletonUI/FormSkeletonWrapper/FormSkeletonWrapper'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import PageTitle from '../../Misc/PageTitle'
import { parseErrors, removeNullValues } from '../../../services/utils'
import { toast } from 'react-toastify';
import useIsMounted from '../../../hooks/useIsMounted'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import useHasPermissions from '../../../hooks/useHasPermissions'
import { PAYMENT_METHODS__CREATE } from '../../../enums/Caps'


export default function MethodCreate() {
    const { t } = useTranslation()

    const hasPermissions = useHasPermissions()

    const isMounted = useIsMounted()

    const api = useAxiosPrivate()

    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)

    const [providers, setProviders] = useState([])

    const getProviders = useCallback(() => {
        api.get('/internal/payment-methods/providers/')
            .then(({ data: { data } }) => isMounted() && setProviders(data.sort((a, b) => (
                String(a.name).toLocaleLowerCase() > String(b.name).toLocaleLowerCase()) ? 1 : ((String(b.name).toLocaleLowerCase() > String(a.name).toLocaleLowerCase()) ? -1 : 0))
            ))
            .catch(() => isMounted() && toast.error(t('Something went wrong')))
            .finally(() => isMounted(), isMounted, api && setLoading(false))
    }, [t, isMounted, api])

    const handleSubmit = (values, { setFieldError }) => {
        const data = removeNullValues(values)

        api.post(`/internal/payment-methods/`, data)
            .then(() => {
                if (isMounted()) {
                    toast.success(t('Element has been created'))
                    navigate('/payment-methods')
                }
            })
            .catch(({ response }) => {
                if (isMounted()) {
                    const errors = parseErrors(response)

                    if (typeof errors === 'object') {
                        for (let i in errors) {
                            setFieldError(errors[i].field, t(errors[i].message), false)
                        }
                    } else if (typeof errors === 'string') {
                        toast.error(t(errors))
                    } else {
                        toast.error(t('Something went wrong'))
                    }
                }
            })
    }

    const handleCancel = () => {
        navigate('/payment-methods')
    }

    const initialFormState = {
        name: '',
        api_code: '',
        method_provider_id_: '',
        status: 0,
        max_amount: 0,
        min_amount: 0,
        default_currency: '',
        rules: '',
        countries: '',
        lifetime: '',
        priority: '',
        is_default: false,
        request_type: 1,
        categories: ''
    }

    const FORM_VALIDATION = Yup.object().shape({
        name: Yup.string()
            .required(t('This field is required')),
        api_code: Yup.string()
            .required(t('This field is required')),
        method_provider_id_: Yup.number()
            .required(t('This field is required')),
        status: Yup.number()
            .required(t('This field is required')),
        request_type: Yup.number()
            .required(t('This field is required')),
        min_amount: Yup.number()
            .required(t('This field is required'))
            .min(0, t('The minimum amount must be greater than or equal to 0')),
        max_amount: Yup.number()
            .required(t('This field is required'))
            .min(0, t('The maximum amount must be greater than or equal to 0')),
        default_currency: Yup.string()
            .required(t('This field is required')),
    })

    useEffect(() => {
        const init = () => {
            getProviders()
        }

        init()
    }, [getProviders])

    return (
        <>
            <Card>
                <CardContent>
                    <PageTitle title={t('Create new payment method')} />

                    {
                        loading ? <FormSkeletonWrapper /> :
                            <MethodForm
                                isNew={true}
                                handleSubmit={handleSubmit}
                                handleCancel={handleCancel}
                                initialFormState={initialFormState}
                                providers={providers}
                                FORM_VALIDATION={FORM_VALIDATION}
                                hasPermissions={hasPermissions(PAYMENT_METHODS__CREATE)}
                            />
                    }
                </CardContent>
            </Card>
        </>
    )
}
