import { useState, useEffect, useCallback } from 'react'

import { Card, CardContent } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CashoutsTable from './CashoutsTable'
import PageTitle from '../../Misc/PageTitle'
import { toast } from 'react-toastify'
import AppFilters from '../../AppFilters'
import Filters from './Filters'
import useIsMounted from '../../../hooks/useIsMounted'
import { removeNullValues } from '../../../services/utils'
import { STATUS_PAID } from '../../../enums/Cashouts'
import DescriptionIcon from '@mui/icons-material/Description'
import { LoadingButton } from '@mui/lab'
import ToolsStack from '../../Misc/ToolsStack'
import useAxiosCashouts from '../../../hooks/useAxiosCashouts'


export default function CashoutsList() {
    const { t } = useTranslation()

    const isMounted = useIsMounted()

    const axiosCashouts = useAxiosCashouts()

    const [params, setParams] = useState({
        status: STATUS_PAID
    })

    const [data, setData] = useState([])

    const [fetchingData, setFetchingData] = useState(false)

    const [loading, setLoading] = useState(true)

    const [meta, setMeta] = useState({
        total_pages: 1,
        current_page: 1
    })

    const handleOnSearch = values => {
        setLoading(true)

        setParams(removeNullValues(values))
    }

    const handleOnPageChange = (e, page) => {
        setLoading(true)

        setMeta({ ...meta, current_page: page })
        setParams({ ...params, page: page })
    }

    const handleExportCsv = (id) => {
        setFetchingData(true)

        return axiosCashouts.get('/cashouts/export', { params: params })
            .then(({ data, headers }) => {
                if (isMounted()) {
                    const binaryData = []
                    binaryData.push(data)

                    let anchor = document.createElement('a')

                    anchor.href = window.URL.createObjectURL(new Blob(binaryData, { type: "text/csv" }))

                    anchor.download = headers.filename

                    anchor.click()
                }
            })
            .catch(() => toast.error(t('Something went wrong')))
            .finally(() => setFetchingData(false))
    }

    const retrieveData = useCallback(() => {
        setData([])

        axiosCashouts.get('/cashouts', { params: params })
            .then(({ data: { data, current_page, last_page } }) => {
                if (isMounted()) {
                    setData(data)
                    setMeta({
                        current_page: current_page,
                        total_pages: last_page
                    })
                }
            })
            .catch((response) => {
                toast.error(t('Something went wrong'))
            })
            .finally(() => setLoading(false))
    }, [params, t, isMounted, axiosCashouts])

    useEffect(() => {
        const loadData = () => {
            retrieveData()
        }

        loadData()
    }, [params, retrieveData])

    return (
        <>
            <Card>
                <CardContent>
                    <PageTitle title={t('Cashouts list')} />

                    <ToolsStack>
                        <LoadingButton
                            loading={fetchingData}
                            onClick={() => handleExportCsv()}
                            loadingPosition="start"
                            startIcon={<DescriptionIcon />}
                            size="small"
                            className="base__btn-action"
                        >
                            {t('Export')}
                        </LoadingButton>
                    </ToolsStack>

                    <AppFilters
                        Filters={<Filters
                            handleOnSearch={handleOnSearch}
                            isPending={false}
                        />}
                        open={true}
                    />

                    <CashoutsTable
                        data={data}
                        pages={meta.total_pages}
                        page={meta.current_page}
                        loading={loading}
                        working={fetchingData}
                        handleOnPageChange={handleOnPageChange}
                        isPending={false}
                    />
                </CardContent>
            </Card>
        </>
    )
}
