import React from 'react'
import { Formik, Form } from 'formik'
import { Grid, Typography, Box, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import SelectApiWrapper from '../../../FormUI/SelectWrapper/SelectApiWrapper'
import TextfieldWrapper from '../../../FormUI/Textfield/TextfieldWrapper'
import SubmitWrapper from '../../../FormUI/SubmitWrapper/SubmitWrapper'
import ButtonWrapper from '../../../FormUI/ButtonWrapper/ButtonWrapper'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

export default function SpreadsForm({
    isNew,
    initialFormState,
    FORM_VALIDATION,
    handleSubmit,
    merchant_id,
    countries,
    hasPermissions
}) {
    const { t } = useTranslation()

    const navigate = useNavigate()

    return (
        <Formik
            initialValues={{
                ...initialFormState
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={handleSubmit}
        >
            <Form>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={3}>
                        <SelectApiWrapper
                            name="country"
                            label={t('Country')}
                            options={countries}
                            translate={false}
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Grid item xs={6} md={2}>
                        <TextfieldWrapper
                            name={`spread`}
                            label={t('Spread')}
                            type="number"
                            InputProps={{
                                endAdornment: <Typography variant='h6'>%</Typography>,
                            }}
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2}>
                            {hasPermissions ? (<SubmitWrapper>{t('Save')}</SubmitWrapper>) : ''}
                            <ButtonWrapper
                                variant="text"
                                onClick={() => navigate(`/merchants/${merchant_id}/spreads`)}
                                startIcon={<ArrowBackIcon />}>{t('Go back')}</ButtonWrapper>
                        </Stack>
                    </Grid>
                </Grid>
            </Form>
        </Formik>
    )
}
