import { Box, Grid, Stack } from "@mui/material"
import { Form, Formik } from "formik"
import { useTranslation } from 'react-i18next'

import ButtonWrapper from "../../../FormUI/ButtonWrapper/ButtonWrapper"
import SubmitWrapper from "../../../FormUI/SubmitWrapper/SubmitWrapper"

import TextfieldWrapper from "../../../FormUI/Textfield/TextfieldWrapper"
import SearchIcon from '@mui/icons-material/Search'
import UndoIcon from '@mui/icons-material/Undo'
import SelectWrapper from '../../../FormUI/SelectWrapper/SelectWrapper'
import { method_status } from "../../../../enums/Merchants"

export default function Filters({
    handleOnSearch
}) {
    const { t } = useTranslation()

    const initialFormState = {
        name: '',
        country: '',
        status: ''
    }

    return (
        <Formik
            initialValues={{
                ...initialFormState
            }}
            onSubmit={handleOnSearch}
        >
            {({ handleReset, handleSubmit }) => (<Form>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <TextfieldWrapper
                            name="name"
                            label={t('Method')}
                            type="text"
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={1}>
                        <TextfieldWrapper
                            name="country"
                            label={t('Country')}
                            type="text"
                            size="small"
                            inputProps={{ maxLength: 2, style: {
                                textTransform: 'uppercase'
                            } }}
                        />
                    </Grid>

                    <Grid item xs={6} md={2}>
                        <SelectWrapper
                            name="status"
                            label={t('Status')}
                            options={
                                { ...method_status, 'all': 'All' }
                            }
                            size="small"
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            <SubmitWrapper startIcon={<SearchIcon />}>{t('Search')}</SubmitWrapper>
                            <ButtonWrapper
                                onClick={() => {
                                    handleReset()
                                    handleSubmit()
                                }}
                                variant="text"
                                startIcon={<UndoIcon />}>{t('Reset')}</ButtonWrapper>
                        </Stack>
                    </Grid>
                </Grid>
            </Form>)}
        </Formik>
    )
}
