import { Route } from 'react-router'
import LoginScreen from '../components/Auth/LoginScreen'
import LoginForm from '../components/Auth/LoginForm'
import PasswordForm from '../components/Auth/PasswordForm'
import PasswordResetForm from '../components/Auth/PasswordResetForm'


export default function AuthRouter() {
    return (
        <>
            <Route path="/auth" element={<LoginScreen />}>
                <Route index element={<LoginForm />} />
                <Route path="password" element={<PasswordForm />} />
                <Route path="password/restore/:token" element={<PasswordResetForm />} />
                <Route path="*" element={<LoginForm />} />
            </Route>
        </>
    )
}
