import { useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useTranslation } from 'react-i18next'
import Pagination from '../../Pagination/Pagination'

import TableBackdrop from '../../TableUI/TableBackdrop'
import {
    Backdrop,
    Checkbox,
    Chip,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    IconButton,
    Stack,
    Tooltip,
    Typography
} from '@mui/material'
import DatetimeValue from '../../TableUI/DatetimeValue'
import CurrencyValue from '../../TableUI/CurrencyValue'
import { STATUS_FAILED, STATUS_PAID } from '../../../enums/Cashouts'
import DeleteIcon from '@mui/icons-material/Delete'
import CashoutDelete from './CashoutDelete'
import { toast } from 'react-toastify'
import useIsMounted from '../../../hooks/useIsMounted'
import { parseErrors } from '../../../services/utils'
import useAxiosCashouts from '../../../hooks/useAxiosCashouts'
import { CASHOUTS__DELETE, CASHOUTS__PROCESS } from './../../../enums/Caps'
import useHasPermissions from '../../../hooks/useHasPermissions'


const styles = () => ({
    root: {
        width: '100%',
        overflowX: "auto",
    }, table: {
        minWidth: 650,
    }
})

export default function CashoutsTable({
    data,
    pages = 1,
    page = 1,
    loading = false,
    working,
    handleOnPageChange,
    isPending,
    setData,
    selectedData = [],
    setSelectedData = () => { }
}) {
    const { t } = useTranslation()

    const hasPermissions = useHasPermissions()

    const isMounted = useIsMounted()

    const axiosCashouts = useAxiosCashouts()

    const [selectedElement, setSelectedElement] = useState(null)

    const [confirming, setConfirming] = useState(false)

    const [open, setOpen] = useState({
        confirm: false,
        delete: false
    })

    const handleOpen = (element, action) => {
        setSelectedElement(element)
        setOpen({
            ...open, [action]: true
        })
    }

    const handleClose = (action) => {
        setSelectedElement(null)
        setOpen({
            ...open, [action]: false
        })
    }

    const handleRemove = () => {
        if (!selectedElement) {
            toast.error(t('Something went wrong'))
            return
        }
        setConfirming(true)

        axiosCashouts.delete(`/cashouts/${selectedElement.id}`)
            .then(() => {
                if (isMounted()) {
                    setData(data.filter(d => d.id !== selectedElement.id))
                    toast.success(t('Cashout has been deleted'))
                    handleClose('delete')
                }
            })
            .catch(({ response }) => {
                const errors = parseErrors(response)

                if (typeof errors === 'string') {
                    toast.error(t(errors))
                } else {
                    toast.error(t('Something went wrong'))
                }
            })
            .finally(() => setConfirming(false))
    }

    const handleChange = (e) => {
        const { checked, value } = e.target

        if (checked) {
            setSelectedData([
                ...selectedData, value * 1
            ])
        } else {
            setSelectedData(selectedData.filter(c => parseInt(c) !== parseInt(value)))
        }
    }

    const checkAll = (e) => {
        const { checked } = e.target

        if (checked) {
            setSelectedData(data.map(r => r.id))
        } else {
            setSelectedData([])
        }
    }

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', fontWeight: 800, zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={working}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Pagination
                handleOnPageChange={handleOnPageChange}
                page={page}
                pages={pages}
            />

            <TableContainer className={styles.root}>
                <Table className={styles.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {(isPending && hasPermissions(CASHOUTS__PROCESS)) && (<TableCell>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox
                                            onChange={checkAll}
                                            indeterminate={selectedData.length > 0 && selectedData.length !== data.length}
                                            checked={selectedData.length > 0 && selectedData.length === data.length}
                                        />}
                                    />
                                </FormGroup>
                            </TableCell>)}
                            <TableCell>{t('Nº')}</TableCell>
                            <TableCell>{t('Merchant')}</TableCell>
                            <TableCell align="center">{t('Country')}</TableCell>
                            <TableCell>{t('Paygol ID')}</TableCell>
                            <TableCell align="right">{t('Amount')}</TableCell>
                            <TableCell>{t('Client / Bank')}</TableCell>
                            <TableCell align="center">{t('Created at')}</TableCell>
                            {!isPending && (<TableCell align="center">{t('Paid at / Failed at')}</TableCell>)}
                            <TableCell align="center">{t('Status')}</TableCell>
                            {(isPending && hasPermissions(CASHOUTS__DELETE)) && (<TableCell align="right">&nbsp;</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!loading && data.length ? data.map((row, idx) => (
                            <TableRow
                                key={idx}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                {(isPending && hasPermissions(CASHOUTS__PROCESS)) && (<TableCell align="center">
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Checkbox
                                                value={row.id}
                                                checked={selectedData.includes(row.id)}
                                                onChange={handleChange}
                                            />}
                                        />
                                    </FormGroup>
                                </TableCell>)}
                                <TableCell component="th" scope="row">
                                    <Typography noWrap>{idx + 1}</Typography>
                                </TableCell>
                                <TableCell>{row.username}</TableCell>
                                <TableCell align="center">{row.country}</TableCell>
                                <TableCell>
                                    <Typography noWrap>{row.str_payout_id}</Typography>
                                    <Typography>{row.merchant_payout_id}</Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <CurrencyValue amount={row.amount} currency={row.currency} />
                                </TableCell>
                                <TableCell>
                                    <Typography>{row.full_name}</Typography>
                                    <Typography>{row.bank?.name}</Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <DatetimeValue date={row.created_at} />
                                </TableCell>
                                {!isPending && (<TableCell align="center">
                                    {row.status === STATUS_PAID && (<DatetimeValue date={row.paid_at} />)}
                                    {row.status === STATUS_FAILED && (<DatetimeValue date={row.updated_at} />)}
                                </TableCell>)}
                                <TableCell align="center">
                                    {row.status === 'created' && (<Chip label={row.status.toUpperCase()} color="primary" variant="outlined" />)}
                                    {row.status === 'paid' && (<Chip label={row.status.toUpperCase()} color="success" variant="outlined" />)}
                                    {row.status === 'in-process' && (<Chip label={row.status.toUpperCase()} color="info" variant="outlined" />)}
                                    {row.status === 'failed' && (
                                        <Tooltip title={row.error_message}><Chip label={row.status.toUpperCase()} color="error" variant="outlined" /></Tooltip>
                                    )}
                                </TableCell>
                                {(isPending && hasPermissions(CASHOUTS__DELETE)) && (<TableCell>
                                    <Stack direction="row" justifyContent="flex-end">
                                        <IconButton
                                            color="primary"
                                            aria-label="Eliminar Cashout"
                                            component="span"
                                            onClick={() => { handleOpen(row, 'delete') }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Stack>
                                </TableCell>)}
                            </TableRow>
                        )) : <TableRow>
                            {
                                loading ? <TableBackdrop open={loading} /> : <TableCell sx={{ textAlign: 'center' }} colSpan={100}>{t('No records found')}</TableCell>
                            }
                        </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>

            <Pagination
                handleOnPageChange={handleOnPageChange}
                page={page}
                pages={pages}
            />

            {(isPending && selectedElement) && (<CashoutDelete
                open={open.delete}
                handleClose={handleClose}
                handleConfirm={handleRemove}
                confirming={confirming}
                cashout={selectedElement}
            />)}
        </>
    )
}
