import React from 'react';
import { Grid, Typography, Skeleton, Stack, Container } from '@mui/material';


export default function PageSkeletonWrapper() {
    return (
        <>
            <Container maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h1"><Skeleton /></Typography>
                    </Grid>

                    <Grid item xs={2} md={3} display={{ xs: "none", md: "block" }}>
                        <Grid>
                            <Grid><Typography variant="h1"><Skeleton /></Typography></Grid>
                            <Grid><Typography variant="h5"><Skeleton /></Typography></Grid>
                            <Grid><Typography variant="h4"><Skeleton /></Typography></Grid>
                            <Grid><Typography variant="h4"><Skeleton /></Typography></Grid>
                            <Grid><Typography variant="h4"><Skeleton /></Typography></Grid>
                            <Grid><Typography variant="h1"><Skeleton /></Typography></Grid>
                            <Grid><Typography variant="text"><Skeleton /></Typography></Grid>
                            <Grid><Typography variant="text"><Skeleton /></Typography></Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={10} md={9}>
                        <Grid><Typography variant="h1"><Skeleton /></Typography></Grid>

                        <Grid><Typography variant="h5"><Skeleton /></Typography></Grid>

                        <Grid item xs={6}>
                            <Stack>
                                <Skeleton variant="text" />
                                <Skeleton variant="circular" width={40} height={40} />
                                <Skeleton variant="rectangular" width={210} height={118} />
                            </Stack>
                        </Grid>

                    </Grid>
                </Grid>
            </Container>
        </>
    )
}
