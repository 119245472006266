import { useState, useEffect } from 'react'
import { Card, CardContent, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import PageTitle from '../../../Misc/PageTitle'
import { useTranslation } from 'react-i18next'
import AppFilters from '../../../AppFilters'
import Filters from './Filters'
import { substractDays } from '../../../../services/utils'
import BackdropWrapper from '../../../BackdropWrapper/BackdropWrapper'
import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts'
import useIsMounted from '../../../../hooks/useIsMounted'
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'
import { ST_COMPLETED, ST_CREATED, ST_FAILED } from '../../../../enums/Transactions'


export default function Merchant() {
    /**
     * Para no mostrar warning por versiones de google charts
     */
    const originalWarn = console.warn;

    console.warn = function (...args) {
        const arg = args && args[0];

        if (arg && arg.includes('Attempting to load version \'51\' of Google Charts')) return;

        originalWarn(...args);
    }
    /**
     * End
     */

    const { t } = useTranslation()

    const isMounted = useIsMounted()

    const api = useAxiosPrivate()

    const today = new Date()

    const [loading, setLoading] = useState(true)

    const [params, setParams] = useState({
        date__gte: new Intl.DateTimeFormat("fr-CA", { year: "numeric", month: "2-digit", day: "2-digit" }).format(substractDays(30)),
        date__lte: new Intl.DateTimeFormat("fr-CA", { year: "numeric", month: "2-digit", day: "2-digit" }).format(today),
        group_by: [],
    })

    const [merchants, setMerchants] = useState([])

    const [total, setTotal] = useState(0)

    const handleOnSearch = (values) => {
        setParams(values)
    }

    useEffect(() => {
        const retrieveData = () => {
            setLoading(true)

            api.get('/internal/analytics/traffic/merchants/', { params: params })
                .then(({ data: { data: { aggregations, data } } }) => {
                    if (isMounted()) {
                        const new_data = data.map(d => {
                            const merchant_completed = d.data.filter(sd => sd.status === ST_COMPLETED)[0]
                            const merchant_created = d.data.filter(sd => sd.status === ST_CREATED)[0]
                            const merchant_failed = d.data.filter(sd => sd.status === ST_FAILED)[0]
                            const total_completed = merchant_completed ? merchant_completed.aggregations.count : 0
                            const total_created = merchant_created ? merchant_created.aggregations.count : 0
                            const total_failed = merchant_failed ? merchant_failed.aggregations.count : 0
                            const total = total_completed + total_created + total_failed

                            return merchant_completed ? {
                                username: d.merchant__username,
                                total: merchant_completed.aggregations.amount,
                                count: merchant_completed.aggregations.count,
                                data: merchant_completed.data.map(sssd => ({
                                    date: params.group_by.includes('month') ? sssd.month : sssd.date,
                                    amount: sssd.aggregations.amount
                                })),
                                meta: {
                                    total_amount: merchant_completed.aggregations.amount,
                                    total_transactions: merchant_completed.aggregations.count + total_created + total_failed,
                                    completed: {
                                        total: total_completed,
                                        p: parseFloat((total_completed / total) * 100).toFixed(2)
                                    },
                                    created: {
                                        total: total_created,
                                        p: parseFloat((total_created / total) * 100).toFixed(2)
                                    },
                                    failed: {
                                        total: total_failed,
                                        p: parseFloat((total_failed / total) * 100).toFixed(2)
                                    }
                                }
                            } : null

                        })
                            .filter(n => n)

                        const total = new_data.reduce((a, b) => a + b.total, 0)

                        setTotal(total)

                        const newMerchantData = new_data.sort((a, b) => a.total > b.total ? -1 : 0)
                        setMerchants(newMerchantData)
                    }
                })
                .finally(() => isMounted() && setLoading(false))
        }

        retrieveData()
    }, [params, api, isMounted])

    return (
        <>
            <Card>
                <CardContent>
                    <PageTitle title={t('Sales stats')} />

                    <AppFilters Filters={<Filters handleOnSearch={handleOnSearch} loading={loading} />} open={true} />

                    {loading && (<Stack spacing={2} direction="row" mt={4} justifyContent="center">
                        <BackdropWrapper open={loading} />
                    </Stack>)}

                    {!loading && (<Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        sx={{
                            mr: 4
                        }}
                    >
                        <Typography variant='h4'>Total ventas: {new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(total)}</Typography>
                    </Stack>)}

                    <Grid container spacing={2}>
                        {!loading && (merchants.map((m, idx) => (
                            <React.Fragment key={idx}>
                                <Grid item xs={12} md={9} sx={{
                                    height: '300px',
                                    paddingBottom: '2rem'
                                }}>
                                    <ResponsiveContainer width="100%" height="100%">
                                        <LineChart
                                            width={500}
                                            height={400}
                                            data={m.data}
                                            margin={{
                                                top: 5,
                                                right: 30,
                                                left: 20,
                                                bottom: 5,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="date" />
                                            <YAxis />
                                            <Tooltip
                                                formatter={(value, name, props) => [
                                                    new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(value),
                                                    "Ventas"
                                                ]}
                                            />
                                            <Legend verticalAlign="top" height={36} />
                                            <Line
                                                type="monotone"
                                                dataKey="amount"
                                                name={`Volumen de ventas (EUR) / ${m.username}`}
                                                stroke="#8884d8"
                                                activeDot={{ r: 8 }}
                                            />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </Grid>

                                <Grid item xs={12} md={3}>
                                    {!loading && (<Stack
                                        alignItems="left"
                                        spacing={2}
                                        sx={{
                                            mr: 4
                                        }}
                                    >
                                        <Typography component='div' sx={{ display: 'block', lineHeight: '.5' }}>Total ventas en periodo <strong>{new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(m.total)}</strong></Typography>
                                        <Typography component='div' sx={{ display: 'block', lineHeight: '.5' }}>Total transacciones <strong>{new Intl.NumberFormat('es-CL').format(m.meta.total_transactions)}</strong></Typography>
                                        <Typography component='div' sx={{ display: 'block', lineHeight: '.5' }}>Completadas <strong>{new Intl.NumberFormat('es-CL').format(m.meta.completed?.total)} ~{m.meta.completed?.p}%</strong></Typography>
                                        <Typography component='div' sx={{ display: 'block', lineHeight: '.5' }}>Creadas <strong>{new Intl.NumberFormat('es-CL').format(m.meta.created?.total)} ~{m.meta.created?.p}%</strong></Typography>
                                        <Typography component='div' sx={{ display: 'block', lineHeight: '.5' }}>Fallidas <strong>{new Intl.NumberFormat('es-CL').format(m.meta.failed?.total)} ~{m.meta.failed?.p}%</strong></Typography>
                                    </Stack>)}
                                </Grid>
                            </React.Fragment>
                        )))}
                    </Grid>

                </CardContent>
            </Card>
        </>
    )
}
