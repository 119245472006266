const TYPE_FEE = 1;
const TYPE_CREDIT = 2;
const TYPE_CASHOUT = 3;
const TYPE_CHARGEBACK = 4;
const TYPE_REFUND = 5;

const types = {
    1: 'Fee',
    2: 'Credit',
    3: 'Cashout',
    4: 'Chargeback',
    5: 'Refund',
}

export {
    TYPE_CREDIT,
    TYPE_FEE,
    TYPE_CASHOUT,
    TYPE_CHARGEBACK,
    TYPE_REFUND,
    types
}