import { Grid, Stack, Typography } from '@mui/material'
import { Formik, Form } from 'formik'
import TextfieldWrapper from '../../FormUI/Textfield/TextfieldWrapper'
import ButtonWrapper from '../../FormUI/ButtonWrapper/ButtonWrapper'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import SubmitWrapper from '../../FormUI/SubmitWrapper/SubmitWrapper'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import PermissionsModal from './Permisssions/PermissionsModal'
import SelectApiWrapper from '../../FormUI/SelectWrapper/SelectApiWrapper'


export default function UserForm({
    isNew,
    handleSubmit,
    initialFormState,
    FORM_VALIDATION,
    hasPermissions,
    user_permissions = []
}) {
    const { t } = useTranslation()

    const navigate = useNavigate()

    return (
        <Formik
            initialValues={{
                ...initialFormState
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={handleSubmit}
        >
            <Form>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {isNew ? <Typography variant="h6">{t('Add a new user account')}</Typography> : <Typography variant="h6">{t('Update account info')}</Typography>}
                    </Grid>

                    {!isNew ? (<Grid item xs={12} sx={{ textAlign: 'right' }}>
                        <PermissionsModal
                            user={initialFormState}
                            user_permissions={user_permissions}
                        />
                    </Grid>) : ''}

                    <Grid item xs={12} md={3}>
                        <SelectApiWrapper
                            name="status"
                            label={t('Status')}
                            options={[
                                {
                                    id: 0,
                                    name: 'Inactive'
                                },
                                {
                                    id: 1,
                                    name: 'Active'
                                },
                            ]}
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    <Box width="100%" mt={2} />

                    <Grid item xs={12}>
                        <TextfieldWrapper
                            name="email"
                            label={t('Email address')}
                            type="email"
                            disabled={!hasPermissions}
                        />
                    </Grid>

                    {
                        isNew ? <Grid item xs={12} md={6} lg={5}>
                            <TextfieldWrapper
                                name="password"
                                label={t('Password')}
                                type="password"
                                disabled={!hasPermissions}
                            />
                        </Grid> : ''
                    }

                    {
                        isNew ? <Grid item xs={12} md={6} lg={5}>
                            <TextfieldWrapper
                                name="password_confirmation"
                                label={t('Password confirmation')}
                                type="password"
                                disabled={!hasPermissions}
                            />
                        </Grid> : ''
                    }

                    <Box width="100%" />

                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2}>
                            {hasPermissions ? (<SubmitWrapper>{t('Save')}</SubmitWrapper>) : ''}
                            <ButtonWrapper
                                variant="text"
                                onClick={() => navigate('/users')}
                                startIcon={<CloseIcon />}>{t('Cancel')}</ButtonWrapper>
                        </Stack>
                    </Grid>
                </Grid>
            </Form>
        </Formik >
    )
}
