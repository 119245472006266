import { Modal, Box, Typography, Grid, Stack } from '@mui/material'
import { Formik, Form } from 'formik'
import ButtonWrapper from '../../FormUI/ButtonWrapper/ButtonWrapper'
import SubmitWrapper from '../../FormUI/SubmitWrapper/SubmitWrapper'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'
import TextfieldWrapper from '../../FormUI/Textfield/TextfieldWrapper'
import * as Yup from 'yup'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
}

export default function TransactionStatusModal({
    open,
    otherProps,
    handleClose
}) {
    const { t } = useTranslation()

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h3" mb={2}>
                    {otherProps.title}
                </Typography>

                <Typography mb={2}>
                    {t('Indicate the reason why the status of this transaction is modified manually')}
                </Typography>

                <Formik
                    onSubmit={otherProps.handleSubmit}
                    initialValues={{
                        comments: ''
                    }}
                    validationSchema={Yup.object().shape({
                        comments: Yup.string()
                            .required(t('This field is required'))
                            .min(8, t('You must enter at least 8 characters'))
                    })}
                >
                    <Form>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextfieldWrapper
                                    multiline
                                    fullWidth
                                    rows="5"
                                    name="comments"
                                    label={t('Comments')}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Stack direction="row" spacing={2} justifyContent="flex-end">
                                    <ButtonWrapper
                                        variant="text"
                                        onClick={handleClose}
                                        startIcon={<CloseIcon />}
                                    >{t('Cancel')}</ButtonWrapper>
                                    <SubmitWrapper>{t('Save')}</SubmitWrapper>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Form>
                </Formik>
            </Box>
        </Modal>
    )
}
