import { Grid, Stack, Typography } from '@mui/material'
import { Formik, Form } from 'formik'
import TextfieldWrapper from '../../../FormUI/Textfield/TextfieldWrapper'
import SelectWrapper from '../../../FormUI/SelectWrapper/SelectWrapper'
import ButtonWrapper from '../../../FormUI/ButtonWrapper/ButtonWrapper'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import SubmitWrapper from '../../../FormUI/SubmitWrapper/SubmitWrapper'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import SwitchWrapper from '../../../FormUI/SwitchWrapper/SwitchWrapper'
import { country_regions, subregions } from '../../../../enums/Countries'


export default function CountryForm({
    handleSubmit,
    initialFormState,
    FORM_VALIDATION
}) {
    const { t } = useTranslation()

    const navigate = useNavigate()

    return (
        <Formik
            initialValues={{
                ...initialFormState
            }}
            validationSchema={FORM_VALIDATION}
            onSubmit={handleSubmit}
        >
            {({ values }) => (
                <Form>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={2}>
                            <TextfieldWrapper
                                name="code"
                                label={t('Code')}
                                type="text"
                            />
                        </Grid>

                        <Grid item xs={12} md={2}>
                            <TextfieldWrapper
                                name="currency"
                                label={t('Currency')}
                                type="text"
                            />
                        </Grid>

                        <Grid item xs={6} md={2}>
                            <SelectWrapper
                                name="region"
                                label={t('Region')}
                                options={country_regions}
                            />
                        </Grid>

                        <Grid item xs={6} md={2}>
                            <SelectWrapper
                                name="subregion"
                                label={t('Subregion')}
                                options={subregions}
                            />
                        </Grid>

                        <Box width="100%" />

                        <Grid item xs={12} md={3}>
                            <TextfieldWrapper
                                name="name"
                                label={t('English name')}
                                type="text"
                            />
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <TextfieldWrapper
                                name="name_es"
                                label={t('Spanish name')}
                                type="text"
                            />
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <TextfieldWrapper
                                name="name_pt"
                                label={t('Portuguese name')}
                                type="text"
                            />
                        </Grid>

                        <Box width="100%" />

                        <Grid item xs={2}>
                            <SwitchWrapper
                                name="apply_vat"
                                label={t('Apply VAT')}
                            />
                        </Grid>

                        <Box width="100%" />

                        <Grid item xs={12} md={2}>
                            <TextfieldWrapper
                                name="vat"
                                label={t('VAT')}
                                type="number"
                                InputProps={{
                                    endAdornment: <Typography variant='h6'>%</Typography>,
                                }}
                            />
                        </Grid>

                        <Box width="100%" />

                        <Grid item xs={2}>
                            <SwitchWrapper
                                name="apply_digital_vat"
                                label={t('Apply digital VAT')}
                            />
                        </Grid>

                        <Box width="100%" />

                        <Grid item xs={12} md={2}>
                            <TextfieldWrapper
                                name="digital_vat"
                                label={t('Digital VAT')}
                                type="number"
                                InputProps={{
                                    endAdornment: <Typography variant='h6'>%</Typography>,
                                }}
                            />
                        </Grid>

                        <Box width="100%" />

                        <Grid item xs={12}>
                            <Stack direction="row" spacing={2}>
                                <SubmitWrapper>{t('Save')}</SubmitWrapper>
                                <ButtonWrapper
                                    variant="text"
                                    onClick={() => navigate('/settings/countries')}
                                    startIcon={<CloseIcon />}>{t('Cancel')}</ButtonWrapper>
                            </Stack>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    )
}
