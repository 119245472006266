import { useState, useEffect, useCallback } from 'react'
import { Grid } from '@mui/material'

import { Card, CardContent } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CountriesTable from './CountriesTable'
import { toast } from 'react-toastify'
import AppFilters from '../../../AppFilters';
import Filters from './Filters';
import PageTitle from '../../../Misc/PageTitle'
import useIsMounted from '../../../../hooks/useIsMounted'
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'


export default function CountriesList() {
    const { t } = useTranslation()

    const isMounted = useIsMounted()

    const api = useAxiosPrivate()

    const [loading, setLoading] = useState(true)

    const [params, setParams] = useState({
        page_size: 50,
        region: 'Americas'
    })

    const [meta, setMeta] = useState({
        total_pages: 1,
        current_page: 1
    })

    const [data, setData] = useState([])

    const handleOnSearch = values => {
        setLoading(true)
        setParams(values)
    }

    const handleOnPageChange = (e, page) => {
        setLoading(true)
        setMeta({ ...meta, current_page: page })
        setParams({ ...params, page: page })
    }

    const retrieveData = useCallback(() => {
        api.get('/internal/countries/', { params: params })
            .then(({ data: { data, meta } }) => {
                if (isMounted()) {
                    setData(data.map(d => {
                        d.vat = d.vat * 100
                        return d
                    }))

                    if (meta) {
                        setMeta(meta)
                    }
                }
            })
            .catch(() => isMounted() && toast.error(t('Something went wrong')))
            .finally(() => isMounted() && setLoading(false))
    }, [t, params, isMounted, api])

    useEffect(() => {
        const init = () => {
            retrieveData()
        }

        init()
    }, [params, retrieveData])

    return (
        <>
            <Card>
                <CardContent>
                    <PageTitle title={t('Country list')} />

                    <Grid container spacing={2}>
                        {/* <Grid item xs={6}>
                            <ButtonWrapper
                                variant="contained"
                                component={Link}
                                to="create"
                                size="small"
                                className="base__btn-action"
                                startIcon={<AddIcon />}>{t('New')}</ButtonWrapper>
                        </Grid> */}
                    </Grid>

                    <AppFilters Filters={<Filters handleOnSearch={handleOnSearch} />} />

                    <CountriesTable
                        data={data}
                        pages={meta.total_pages}
                        page={meta.current_page}
                        loading={loading}
                        handleOnPageChange={handleOnPageChange}
                    />
                </CardContent>
            </Card>
        </>
    )
}
