import { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import { Card, CardContent } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ToPayPayoutsTable from './ToPayPayoutsTable'
import PageTitle from '../../Misc/PageTitle'
import { toast } from 'react-toastify'
import AppFilters from '../../AppFilters'
import Filters from './Filters';
import AmountBox from '../../TableUI/AmountBox'
import useIsMounted from '../../../hooks/useIsMounted'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'


export default function ToPayPayoutsList() {
    const { t } = useTranslation()

    const isMounted = useIsMounted()

    const api = useAxiosPrivate()

    const [params, setParams] = useState({
        minimum: 100
    })

    const [total, setTotal] = useState({
        usd: 0,
        clp: 0,
        eur: 0
    })

    const [data, setData] = useState([])

    const [loading, setLoading] = useState(true)

    const [meta, setMeta] = useState({
        total_pages: 1,
        current_page: 1
    })

    const handleOnSearch = values => {
        if (values === params) {
            return
        }

        setLoading(true)

        values = {
            ...values,
            merchant__status: String(values.merchant__status).replace('all', '')
        }

        setParams(values)
    }

    const handleOnPageChange = (e, page) => {
        setLoading(true)

        setMeta({ ...meta, current_page: page })
        setParams({ ...params, page: page })
    }

    useEffect(() => {
        setLoading(true)

        const retrieveData = () => {
            api.get(`/internal/to-pay-payouts/`, { params: params })
                .then(({ data: { data } }) => {
                    if (isMounted()) {
                        const usd = data.filter(a => a.account_currency === 'USD').reduce((a, { total }) => a + total, 0)
                        const eur = data.filter(a => a.account_currency === 'EUR').reduce((a, { total }) => a + total, 0)
                        const clp = data.filter(a => a.account_currency === 'CLP').reduce((a, { total }) => a + total, 0)

                        setTotal({
                            ...total, clp: clp, usd: usd, eur: eur
                        })

                        setData(data)
                    }

                })
                .catch(() => isMounted() && toast.error(t('Something went wrong')))
                .finally(() => isMounted() && setLoading(false))
        }

        retrieveData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, t, isMounted])

    return (
        <>
            <Card>
                <CardContent>
                    <PageTitle title={t('To pay payouts')} />

                    <Grid container spacing={2} sx={{ mt: 1, p: 2 }}>
                        <Grid item xs={12} sm={3}>
                            <AmountBox amount={total.clp} title='CLP' loading={loading} fractionDigits={0} />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <AmountBox amount={total.usd} title='USD' loading={loading} />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            <AmountBox amount={total.eur} title='EUR' loading={loading} />
                        </Grid>
                    </Grid>

                    <AppFilters Filters={<Filters handleOnSearch={handleOnSearch} loading={loading} />} />

                    <ToPayPayoutsTable
                        data={data}
                        pages={meta.total_pages}
                        page={meta.current_page}
                        loading={loading}
                        handleOnPageChange={handleOnPageChange}
                    />
                </CardContent>
            </Card>
        </>
    )
}
