import { useState, useEffect, useCallback } from 'react'

import { Alert, Badge, Card, CardContent, Chip, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CashoutsTable from './../CashoutsTable'
import PageTitle from '../../../Misc/PageTitle'
import { toast } from 'react-toastify'
import AppFilters from '../../../AppFilters'
import Filters from './../Filters'
import useIsMounted from '../../../../hooks/useIsMounted'
import { removeNullValues } from '../../../../services/utils'
import { STATUS_CREATED } from '../../../../enums/Cashouts'
import { LoadingButton } from '@mui/lab'
import ToolsStack from '../../../Misc/ToolsStack'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import useAxiosCashouts from '../../../../hooks/useAxiosCashouts'
import { CASHOUTS__PROCESS } from '../../../../enums/Caps'
import useHasPermissions from '../../../../hooks/useHasPermissions'


export default function Pending() {
  const { t } = useTranslation()

  const hasPermissions = useHasPermissions()

  const isMounted = useIsMounted()

  const axiosCashouts = useAxiosCashouts()

  const navigate = useNavigate()

  const [params, setParams] = useState({
    status: STATUS_CREATED
  })

  const [data, setData] = useState([])

  const [errors, setErrors] = useState([])

  const [stats, setStats] = useState({
    pending: 0,
    running: 0
  })

  const [selectedData, setSelectedData] = useState([])

  const [working, setWorking] = useState(false)

  const [loading, setLoading] = useState(true)

  const [meta, setMeta] = useState({
    total_pages: 1,
    current_page: 1
  })

  const handleOnSearch = values => {
    setLoading(true)

    setSelectedData([])

    setErrors([])

    setParams(removeNullValues(values))
  }

  const handleOnPageChange = (e, page) => {
    setLoading(true)

    setMeta({ ...meta, current_page: page })
    setParams({ ...params, page: page })
  }

  const retrieveData = useCallback(() => {
    setData([])

    axios.all([
      axiosCashouts.get('/cashouts', { params: params }),
      axiosCashouts.get('/cashouts/stats')
    ])
      .then(axios.spread((...responses) => ({
        data: responses[0].data,
        stats: responses[1].data,
      })))
      .then(({ data: { data, current_page, last_page }, stats }) => {
        if (isMounted()) {
          setData(data)
          setStats(stats)
          setMeta({
            current_page: current_page,
            total_pages: last_page
          })
        }
      })
      .catch(() => {
        toast.error(t('Something went wrong'))
      })
      .finally(() => setLoading(false))
  }, [params, t, isMounted, axiosCashouts])

  const handleProcess = async () => {
    setWorking(true)

    axiosCashouts.post('/cashouts', { payouts: selectedData.join(','), pv3: 3 })
      .then((response) => {
        // Soporte old cashouts
        const { res, data: { ids, stats } } = response.data

        if (res !== 0) {
          const errors = JSON.parse(response.data.error)
          setErrors(errors)
        }

        try {
          setData(data.filter(d => !ids.includes(d.id)))
          setSelectedData([])
          setStats(stats)
          toast.success(t('Cashouts have been moved to the payment area'))
        } catch (e) {
          console.log(e)
        }

      })
      .catch(({ response }) => {
        toast.error(t('Something went wrong'))
      })
      .finally(() => setWorking(false))
  }

  useEffect(() => {
    const loadData = () => {
      retrieveData()
    }

    loadData()
  }, [params, retrieveData])

  return (
    <Card>
      <CardContent>
        <PageTitle title={t('Pending Cashouts')} />

        <ToolsStack justifyContent="space-between">
          {hasPermissions(CASHOUTS__PROCESS) ? (<LoadingButton
            variant="contained"
            loading={working}
            onClick={() => handleProcess()}
            loadingPosition="start"
            startIcon={<PlayCircleOutlineIcon />}
            size="small"
            disabled={!selectedData.length}
          >
            {t('Process')}
          </LoadingButton>) : <span>.</span>}

          <Stack direction="row" spacing={3}>
            <Badge badgeContent={stats.running} color="success">
              <Chip
                label="In process"
                variant="outlined"
                color="success"
                onClick={() => navigate('/cashouts/active')}
              />
            </Badge>

            <Badge badgeContent={stats.pending} color="info">
              <Chip label="Pending" variant="outlined" color="info" />
            </Badge>
          </Stack>
        </ToolsStack>

        <AppFilters
          Filters={<Filters
            handleOnSearch={handleOnSearch}
            isPending={true}
          />}
          open={true}
        />

        {
          errors.map((e, idx) => (
            <Alert key={idx} severity="warning">{e}</Alert>
          ))
        }

        <CashoutsTable
          data={data}
          pages={meta.total_pages}
          page={meta.current_page}
          loading={loading}
          working={working}
          handleOnPageChange={handleOnPageChange}
          isPending={true}
          setData={setData}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          errors={errors}
        />
      </CardContent>
    </Card>
  )
}
