const STATUS_CREATED = 'created'
const STATUS_IN_PROCESS = 'in-process'
const STATUS_PAID = 'paid'
const STATUS_FAILED = 'failed'

const status_list = {
    [STATUS_IN_PROCESS]: 'In process',
    [STATUS_PAID]: 'Paid',
    [STATUS_FAILED]: 'Failed',
}

const status_idx = {
    [STATUS_CREATED]: 1,
    [STATUS_IN_PROCESS]: 2,
    [STATUS_PAID]: 3,
    [STATUS_FAILED]: 4,
}

const failed_options = [
    {id: 1, text: 'INSUFFICIENT FUNDS _ DEBIT NOT PERFORMED'},
    {id: 2, text: 'TYPE OF MOVEMENT INVALID'},
    {id: 3, text: 'INVALID BRANCH OR AGENCY CODE'},
    {id: 4, text: 'INVALID BANK CODE'},
    {id: 5, text: 'INVALID ACCOUNT NUMBER'},
    {id: 6, text: 'NAME NOT SENT'},
    {id: 7, text: 'INVALID CURRENCY TYPE/QUANTITY'},
    {id: 8, text: 'INVALID AMOUNT'},
    {id: 9, text: 'ACCOUNT NUMBER DOES NOT CORRESPOND TO DOCUMENT NUMBER'},
    {id: 10, text: 'INVALID DOCUMENT NUMBER'},
]

export {
    STATUS_CREATED,
    STATUS_IN_PROCESS,
    STATUS_PAID,
    STATUS_FAILED,
    status_list,
    status_idx,
    failed_options
}