import { Grid, Box, Typography, Stack } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import Copyright from './Copyright'
import Button from '@mui/material/Button'
import { Link, useNavigate, useParams } from 'react-router-dom'
import api from '../../api'
import { useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import TextfieldWrapper from '../FormUI/Textfield/TextfieldWrapper'
import { toast } from 'react-toastify'
import { parseErrors } from '../../services/utils'


const SignInButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '2px solid',
    lineHeight: 1.5,
    backgroundColor: '#09097d',
    color: 'white',
    borderColor: 'white',
    '&:hover': {
        backgroundColor: '#0B1F3F',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        borderColor: '#005cbf',
    }
})

const TextButton = styled(Button)({
    textTransform: 'none'
})

export default function PasswordResetForm() {
    const { t } = useTranslation()

    const { token } = useParams()

    const navigate = useNavigate()

    const [authenticating, setAuthenticating] = useState(false)

    const initialFormState = {
        password: '',
        password_confirmation: ''
    }

    const FORM_VALIDATION = Yup.object().shape({
        password: Yup.string()
            .required(t('Password is required'))
            .min(8, t('Password is too short - should be 8 chars minimum'))
            .matches(/[a-zA-Z0-9]/, t('Password can only contain letters and numbers')),
        password_confirmation: Yup.string()
            .required(t('Password confirmation is required'))
            .oneOf([Yup.ref('password'), null], t('Passwords must match'))
    })

    const handleSubmit = (values, { setFieldError }) => {
        setAuthenticating(true)

        api.post(`/auth/password/restore/`, { ...values, token: token }, {
            headers: { 'Content-Type': 'application/json' },
        })
            .then(() => {
                toast.success(t('Your password has been updated'))
                navigate('/auth/login')
            })
            .catch(({ response }) => {
                const errors = parseErrors(response)

                if (typeof errors === 'object') {
                    for (let i in errors) {
                        if (errors[i].field === 'token') {
                            toast.error(t('Invalid token'))
                        } else {
                            setFieldError(errors[i].field, t(errors[i].message), false)
                        }
                    }
                } else if (typeof errors === 'string') {
                    toast.error(t(errors))
                } else {
                    toast.error(t('Something went wrong'))
                }
            })
            .finally(() => setAuthenticating(false))
    }

    return (
        <>
            <Box
                sx={{
                    my: 8,
                    mx: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Stack sx={{ m: 1 }}>
                    <img src='https://cdn.paygol.com/images/v2/paygol-isotipo-50x50.png' alt="Paygol" />
                </Stack>

                <Typography component="h1" variant="h5">
                    {t('Update your password')}
                </Typography>

                <Formik
                    initialValues={{
                        ...initialFormState
                    }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={handleSubmit}
                >
                    <Form sx={{ mt: 1 }}>
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                            <Grid item xs={12}>
                                <TextfieldWrapper
                                    name="password"
                                    fullWidth
                                    label={t('Password')}
                                    type="password"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextfieldWrapper
                                    name="password_confirmation"
                                    fullWidth
                                    label={t('Password confirmation')}
                                    type="password"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Stack direction="row" spacing={2}>
                                    <SignInButton
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                        disabled={authenticating}
                                    >
                                        {t('Update password')}
                                    </SignInButton>
                                </Stack>

                                <Grid container>
                                    <Grid item xs align="center">
                                        <TextButton to="/auth/login" component={Link}>
                                            {t('Login to your account')}
                                        </TextButton>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item sm={12}>
                                <Copyright sx={{ mt: 5 }} />
                            </Grid>
                        </Grid>
                    </Form>
                </Formik>
            </Box>
        </>
    )
}
