import { useEffect, useState, useCallback } from 'react'
import { Card, CardContent, Grid, Hidden, Stack } from '@mui/material'
import FormSkeletonWrapper from '../../../SkeletonUI/FormSkeletonWrapper/FormSkeletonWrapper'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import ContactsForm from './ContactsForm'
import { toast } from 'react-toastify';
import { parseErrors, replaceNullValues } from '../../../../services/utils'
import PageTitle from '../../../Misc/PageTitle'
import MerchantMenu from '../MerchantMenu'
import MerchantAlias from '../MerchantAlias'
import MerchantSubmenu from '../MerchantSubmenu'
import useIsMounted from '../../../../hooks/useIsMounted';
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'
import useHasPermissions from '../../../../hooks/useHasPermissions'
import { MERCHANT_CONTACTS__UPDATE } from '../../../../enums/Caps'


export default function ContactEdit() {
    const { t } = useTranslation()

    const hasPermissions = useHasPermissions()

    const navigate = useNavigate()

    const isMounted = useIsMounted()

    const api = useAxiosPrivate()

    const [data, setData] = useState()

    const { userId, contactId } = useParams()

    const [isLoading, setLoading] = useState(true)

    const getAccount = useCallback(() => {
        api.get(`/internal/merchants/${userId}/contacts/${contactId}/`)
            .then(({ data: { data } }) => isMounted() && setData(replaceNullValues(data)))
            .catch(() => isMounted() && toast.error(t('something went wrong!')))
            .finally(() => isMounted() && setLoading(false))
    }, [contactId, userId, t, api, isMounted])

    const handleSubmit = (values, { setFieldError }) => {
        api.put(`/internal/merchants/${userId}/contacts/${contactId}/`, values)
            .then(() => {
                if (isMounted()) {
                    toast.success(t('Contact has been updated'))
                    navigate(`/merchants/${userId}/contacts`)
                }
            })
            .catch(({ response }) => {
                if (isMounted()) {
                    const errors = parseErrors(response)

                    if (typeof errors === 'object') {
                        for (let i in errors) {
                            setFieldError(errors[i].field, t(errors[i].message), false)
                        }
                    } else if (typeof errors === 'string') {
                        toast.error(t(errors))
                    } else {
                        toast.error(t('Something went wrong'))
                    }
                }
            })
    }

    const handleCancel = () => {
        navigate(`/merchants/${userId}/contacts`)
    }

    const FORM_VALIDATION = Yup.object().shape({
        title: Yup.string()
            .required(t('This field is required')),
        first_name: Yup.string()
            .required(t('This field is required')),
        last_name: Yup.string()
            .required(t('This field is required')),
        email: Yup.string()
            .email(t('You must enter a valid email address'))
    })

    useEffect(() => {
        const init = () => {
            getAccount()
        }

        init()
    }, [getAccount])

    return (
        <>
            <Card>
                <CardContent>
                    <Stack direction="row" justifyContent="space-between" spacing={2}>
                        <PageTitle title={t('Edit merchant contact')} />

                        <Hidden xlUp={true}>
                            {userId ? <MerchantMenu id={userId} /> : ''}
                        </Hidden>
                    </Stack>

                    <MerchantAlias />

                    <Grid container spacing={2}>
                        <Grid item xs={12} xl={2}>
                            <MerchantSubmenu current='contacts' id={userId} />
                        </Grid>
                        <Grid item xs={12} xl={10}>
                            {
                                isLoading ? <FormSkeletonWrapper /> :
                                    <ContactsForm
                                        isNew={false}
                                        handleSubmit={handleSubmit}
                                        handleCancel={handleCancel}
                                        initialFormState={data}
                                        FORM_VALIDATION={FORM_VALIDATION}
                                        merchant_id={userId}
                                        hasPermissions={hasPermissions(MERCHANT_CONTACTS__UPDATE)}
                                    />
                            }
                        </Grid>
                    </Grid>

                </CardContent>
            </Card>
        </>
    )
}
