import { useEffect, useState, useCallback } from 'react'
import { Card, CardContent, Grid, Hidden, Stack } from '@mui/material'
import FormSkeletonWrapper from 'components/SkeletonUI/FormSkeletonWrapper/FormSkeletonWrapper'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import AddressesForm from './AddressesForm'
import { toast } from 'react-toastify';
import { parseErrors, replaceNullValues } from 'services/utils'
import PageTitle from 'components/Misc/PageTitle'
import MerchantMenu from '../MerchantMenu'
import MerchantAlias from '../MerchantAlias'
import MerchantSubmenu from '../MerchantSubmenu'
import useIsMounted from 'hooks/useIsMounted';
import useAxiosPrivate from 'hooks/useAxiosPrivate'
import useHasPermissions from 'hooks/useHasPermissions'
import { ADDRESSES__UPDATE } from 'enums/Caps'


export default function AddressEdit() {
    const { t } = useTranslation()

    const hasPermissions = useHasPermissions()

    const navigate = useNavigate()

    const isMounted = useIsMounted()

    const api = useAxiosPrivate()

    const [data, setData] = useState()

    const { userId, addressId } = useParams()

    const [isLoading, setLoading] = useState(true)

    const getAccount = useCallback(() => {
        api.get(`/internal/merchants/${userId}/addresses/${addressId}/`)
            .then(({ data: { data } }) => isMounted() && setData(replaceNullValues(data)))
            .catch(() => isMounted() && toast.error(t('something went wrong!')))
            .finally(() => isMounted() && setLoading(false))
    }, [addressId, userId, t, api, isMounted])

    const handleSubmit = (values, { setFieldError }) => {
        api.put(`/internal/merchants/${userId}/addresses/${addressId}/`, values)
            .then(() => {
                if (isMounted()) {
                    toast.success(t('Address has been updated'))
                    navigate(`/merchants/${userId}/addresses`)
                }
            })
            .catch(({ response }) => {
                if (isMounted()) {
                    const errors = parseErrors(response)

                    if (typeof errors === 'object') {
                        for (let i in errors) {
                            setFieldError(errors[i].field, t(errors[i].message), false)
                        }
                    } else if (typeof errors === 'string') {
                        toast.error(t(errors))
                    } else {
                        toast.error(t('Something went wrong'))
                    }
                }
            })
    }

    useEffect(() => {
        const init = () => {
            getAccount()
        }

        init()
    }, [getAccount])

    return (
        <>
            <Card>
                <CardContent>
                    <Stack direction="row" justifyContent="space-between" spacing={2}>
                        <PageTitle title={t('Edit merchant address')} />

                        <Hidden xlUp={true}>
                            {userId ? <MerchantMenu id={userId} /> : ''}
                        </Hidden>
                    </Stack>

                    <MerchantAlias />

                    <Grid container spacing={2}>
                        <Grid item xs={12} xl={2}>
                            <MerchantSubmenu current='addresses' id={userId} />
                        </Grid>
                        <Grid item xs={12} xl={10}>
                            {
                                isLoading ? <FormSkeletonWrapper /> :
                                    <AddressesForm
                                        isNew={false}
                                        handleSubmit={handleSubmit}
                                        initialFormState={data}
                                        merchant_id={userId}
                                        hasPermissions={hasPermissions(ADDRESSES__UPDATE)}
                                    />
                            }
                        </Grid>
                    </Grid>

                </CardContent>
            </Card>
        </>
    )
}
