import { useEffect, useState } from 'react'
import { Card, CardContent, Grid, Hidden, Stack } from '@mui/material'
import FormSkeletonWrapper from '../../../SkeletonUI/FormSkeletonWrapper/FormSkeletonWrapper'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import MiscellaneousForm from './MiscellaneousForm'
import { parseErrors } from '../../../../services/utils'
import { toast } from 'react-toastify'
import PageTitle from '../../../Misc/PageTitle'
import { TYPE_FEE } from '../../../../enums/Miscellaneous'
import MerchantMenu from '../MerchantMenu'
import MerchantAlias from '../MerchantAlias'
import MerchantSubmenu from '../MerchantSubmenu'
import useIsMounted from '../../../../hooks/useIsMounted'
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'
import useHasPermissions from '../../../../hooks/useHasPermissions'


export default function MiscellaneousCreate() {
    const { t } = useTranslation()

    const hasPermissions = useHasPermissions()

    const navigate = useNavigate()

    const isMounted = useIsMounted()

    const api = useAxiosPrivate()

    const [loading, setLoading] = useState(true)

    const [initialFormState, setInitialFormState] = useState({
        type: TYPE_FEE,
        amount: '',
        currency: '',
        comments: ''
    })

    const { userId } = useParams()

    const handleSubmit = (values, { setFieldError }) => {
        api.post(`/internal/merchants/${userId}/miscellaneous/`, values)
            .then(() => {
                if (isMounted()) {
                    toast.success(t('Element has been created'))
                    navigate(`/merchants/${userId}/miscellaneous`)
                }
            })
            .catch(({ response }) => {
                if (isMounted()) {
                    const errors = parseErrors(response)

                    if (typeof errors === 'object') {
                        for (let i in errors) {
                            setFieldError(errors[i].field, t(errors[i].message), false)
                        }
                    } else if (typeof errors === 'string') {
                        toast.error(t(errors))
                    } else {
                        toast.error(t('Something went wrong'))
                    }
                }
            })
    }

    const handleCancel = () => {
        navigate(`/merchants/${userId}/miscellaneous`)
    }

    const FORM_VALIDATION = Yup.object().shape({
        currency: Yup.string()
            .required(t('This field is required')),
        comments: Yup.string()
            .required(t('This field is required')),
        type: Yup.number()
            .required(t('This field is required')),
        amount: Yup.number()
            .min(0, t('Amount field must be greater than 0'))
            .required(t('This field is required')),
    })

    useEffect(() => {
        const getAccountConfig = () => {
            api.get(`/internal/merchants/${userId}/account-config/`)
                .then(({ data: { data: { settlement_currency } } }) => {
                    if (isMounted()) {
                        setInitialFormState({
                            ...initialFormState,
                            currency: settlement_currency
                        })
                    }
                })
                .catch(() => isMounted() && toast.error(t('Something went wrong')))
                .finally(() => isMounted() && setLoading(false))
        }

        getAccountConfig()
    }, [t, userId, api, isMounted, initialFormState])

    return (
        <>
            <Card>
                <CardContent>
                    <Stack direction="row" justifyContent="space-between" spacing={2}>
                        <PageTitle title={t('Add new miscellaneous')} />

                        <Hidden xlUp={true}>
                            {userId ? <MerchantMenu id={userId} /> : ''}
                        </Hidden>
                    </Stack>

                    <MerchantAlias />

                    <Grid container spacing={2}>
                        <Grid item xs={12} xl={2}>
                            <MerchantSubmenu current='miscellaneous' id={userId} />
                        </Grid>
                        <Grid item xs={12} xl={10}>
                            {
                                loading ? <FormSkeletonWrapper /> :
                                    <MiscellaneousForm
                                        isNew={true}
                                        handleSubmit={handleSubmit}
                                        handleCancel={handleCancel}
                                        initialFormState={initialFormState}
                                        FORM_VALIDATION={FORM_VALIDATION}
                                        merchant_id={userId}
                                        hasPermissions={hasPermissions}
                                    />
                            }
                        </Grid>
                    </Grid>

                </CardContent>
            </Card>
        </>
    )
}
