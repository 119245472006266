import { Card, CardContent, Tab, Tabs } from '@mui/material'
import { Box } from '@mui/system'
import { useState, useEffect } from 'react'
import PasswordForm from './PasswordForm'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import FormSkeletonWrapper from '../../SkeletonUI/FormSkeletonWrapper/FormSkeletonWrapper'
import { parseErrors } from './../../../services/utils'
import { toast } from 'react-toastify'
import authService from '../../../services/auth.service'

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}

export default function Profile() {
    const { t } = useTranslation()

    const [loading, setLoading] = useState(true)

    const [value, setValue] = useState(0)

    const passwordInitialFormState = {
        password: '',
        password_confirmation: '',
        old_password: ''
    }

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleUpdatePassword = (values, { resetForm, setFieldError }) => {
        authService.resetPassword(values)
            .then(() => {
                resetForm()
                toast.success(t('Your password has been updated'))
            })
            .catch(({ response }) => {
                const errors = parseErrors(response)

                if (typeof errors === 'object') {
                    for (let i in errors) {
                        setFieldError(errors[i].field, t(errors[i].message), false)
                    }
                } else if (typeof errors === 'string') {
                    toast.error(t(errors))
                } else {
                    toast.error(t('Something went wrong'))
                }
            })
    }

    useEffect(() => {
        setTimeout(() => setLoading(false), 2000)
    }, [])

    return (
        <>
            <Card>
                <CardContent>
                    {
                        loading ? <FormSkeletonWrapper /> : (
                            <>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <Tabs value={value} onChange={handleChange} aria-label="Account info">
                                        {/* <Tab label={t('Two-Factor Authentication')} {...a11yProps(0)} /> */}
                                        <Tab label={t('Security')} {...a11yProps(0)} />
                                    </Tabs>
                                </Box>
                                {/* <TabPanel value={value} index={0}>
                                    <TotpForm totpEnabled={false} />
                                </TabPanel> */}
                                <TabPanel value={value} index={0}>
                                    <PasswordForm
                                        initialFormState={passwordInitialFormState}
                                        handleSubmit={handleUpdatePassword}
                                    />
                                </TabPanel>
                            </>
                        )}
                </CardContent>
            </Card>
        </>
    )
}
