import { Box, Grid, Stack } from "@mui/material"
import { Form, Formik } from "formik"
import { useTranslation } from 'react-i18next'

import ButtonWrapper from "../../FormUI/ButtonWrapper/ButtonWrapper"
import SubmitWrapper from "../../FormUI/SubmitWrapper/SubmitWrapper"

import TextfieldWrapper from "../../FormUI/Textfield/TextfieldWrapper"
import SearchIcon from '@mui/icons-material/Search'
import UndoIcon from '@mui/icons-material/Undo'
import SelectWrapper from "../../FormUI/SelectWrapper/SelectWrapper"
import { status } from "../../../enums/Merchants"


export default function Filters({
    handleOnSearch
}) {
    const { t } = useTranslation()

    const initialFormState = {
        email: '',
        username__icontains: '',
        first_name__icontains: '',
        last_name__icontains: '',
        website__icontains: '',
        service_id: '',
        status: ''
    }

    return (
        <Formik
            initialValues={{
                ...initialFormState
            }}
            onSubmit={handleOnSearch}
        >
            {({ handleReset, handleSubmit }) => (<Form>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={2}>
                        <TextfieldWrapper
                            name="username__icontains"
                            label={t('Alias')}
                            type="text"
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <TextfieldWrapper
                            name="email"
                            label={t('Email')}
                            type="email"
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <TextfieldWrapper
                            name="service_id"
                            label={t('Service ID')}
                            type="text"
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <SelectWrapper
                            name="status"
                            label={t('Status')}
                            options={
                                { ...status, '': 'All' }
                            }
                            size="small"
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={12} md={3}>
                        <TextfieldWrapper
                            name="first_name__icontains"
                            label={t('First name')}
                            type="text"
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <TextfieldWrapper
                            name="last_name__icontains"
                            label={t('Last name')}
                            type="text"
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <TextfieldWrapper
                            name="website__icontains"
                            label={t('Website')}
                            type="text"
                            size="small"
                        />
                    </Grid>

                    <Box width="100%" />

                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2} justifyContent="flex-end">
                            <SubmitWrapper startIcon={<SearchIcon />}>{t('Search')}</SubmitWrapper>
                            <ButtonWrapper
                                onClick={() => {
                                    handleReset()
                                    handleSubmit()
                                }}
                                variant="text"
                                startIcon={<UndoIcon />}>{t('Reset')}</ButtonWrapper>
                        </Stack>
                    </Grid>
                </Grid>
            </Form>)}
        </Formik>
    )
}
