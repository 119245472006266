import { Collapse, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { menu } from "../../menu";
import { hasChildren } from "../../services/utils";
import { useState } from "react";

import { NavLink, useLocation } from "react-router-dom";
import i18n from 'i18next';
import useHasPermissions from "../../hooks/useHasPermissions";


export default function SideMenu() {
    // FIXME
    const { pathname } = useLocation()

    return menu.map((item, key) => <MenuItem
        key={key}
        item={item}
        current={pathname}
    />);
}

const MenuItem = ({ item, current }) => {
    const hasPermissions = useHasPermissions()

    const Component = hasChildren(item) ? MultiLevel : SingleLevel
    const _hasPermissions = !item?.caps || hasPermissions(item?.caps)

    return <Component item={item} current={current} hasPermissions={_hasPermissions} />;
};

const SingleLevel = ({ item, current, hasPermissions = false }) => {
    // const regexp = new RegExp(item.to)
    // const selected = (item.to !== '/' && regexp.test(current)) || (item.to === '/' && current === '/')
    const selected = item.to === current

    return (
        hasPermissions ? (<ListItem
            button
            component={NavLink}
            to={item.to}
            selected={selected}
        >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={i18n.t(item.title)} />
        </ListItem>) : ''
    );
};

const MultiLevel = ({ item, current, hasPermissions = false }) => {
    const { items: children } = item

    const hasChildPermissions = useHasPermissions()

    const [open, setOpen] = useState(false)

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    return (
        hasPermissions ? (<>
            <ListItem button onClick={handleClick}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={i18n.t(item.title)} />
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {children.map((child, key) => (
                        !child?.caps || hasChildPermissions(child.caps) ? <MenuItem key={key} item={child} current={current} /> : ''
                    ))}
                </List>
            </Collapse>
        </>) : ''
    );
};