import { useEffect, useState } from 'react'
import { Card, CardContent, Grid, Hidden, Stack } from '@mui/material'
import FormSkeletonWrapper from '../../../SkeletonUI/FormSkeletonWrapper/FormSkeletonWrapper'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import BankAccountsForm from './BankAccountsForm'
import { parseErrors } from '../../../../services/utils'
import { toast } from 'react-toastify'
import PageTitle from '../../../Misc/PageTitle';
import MerchantAlias from '../MerchantAlias'
import MerchantSubmenu from '../MerchantSubmenu'
import MerchantMenu from '../MerchantMenu'
import useIsMounted from '../../../../hooks/useIsMounted'
import useAxiosPrivate from '../../../../hooks/useAxiosPrivate'
import useHasPermissions from '../../../../hooks/useHasPermissions'
import { BANK_ACCOUNTS__CREATE } from '../../../../enums/Caps'


export default function BankAccountCreate() {
    const { t } = useTranslation()

    const hasPermissions = useHasPermissions()

    const navigate = useNavigate()

    const isMounted = useIsMounted()

    const api = useAxiosPrivate()

    const { userId } = useParams()

    const handleSubmit = (values, { setFieldError }) => {
        api.post(`/internal/merchants/${userId}/bank-accounts/`, values)
            .then(() => {
                if (isMounted()) {
                    toast.success(t('Bank account has been created'))
                    navigate(`/merchants/${userId}/bank-accounts`)
                }
            })
            .catch(({ response }) => {
                if (isMounted()) {
                    const errors = parseErrors(response)

                    if (typeof errors === 'object') {
                        for (let i in errors) {
                            setFieldError(errors[i].field, t(errors[i].message), false)
                        }
                    } else if (typeof errors === 'string') {
                        toast.error(t(errors))
                    } else {
                        toast.error(t('Something went wrong'))
                    }
                }
            })
    }

    const handleCancel = () => {
        navigate(`/merchants/${userId}/bank-accounts`)
    }

    const initialFormState = {
        bank_name: '',
        bank_country: '',
        bank_address: '',
        account_owner: '',
        account_number: '',
        account_type: '',
        account_owner_personal_id: '',
        swift_bic: '',
        iban: '',
        is_default: true,
        status: 1,
        is_intermediary: false,
        notification_email: '',
    }

    const FORM_VALIDATION = Yup.object().shape({
        notification_email: Yup.string()
            .email(t('You must enter a valid email address')),
        bank_name: Yup.string()
            .required(t('This field is required')),
        bank_country: Yup.string()
            .required(t('This field is required')),
        bank_address: Yup.string()
            .max(128, t('The text exceeds the maximum length allowed ({{maxlen}})', { maxlen: 1024 })),
        account_owner: Yup.string()
            .max(128, t('The text exceeds the maximum length allowed ({{maxlen}})', { maxlen: 128 }))
            .required(t('This field is required')),
        account_number: Yup.string()
            .required(t('This field is required')),
        account_owner_personal_id: Yup.string()
            .required(t('This field is required')),
        status: Yup.number()
            .required(t('This field is required')),
    })

    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setIsLoading(false), 1000)
    }, [])

    return (
        <>
            <Card>
                <CardContent>
                    <Stack direction="row" justifyContent="space-between" spacing={2}>
                        <PageTitle title={t('Add new bank account')} />

                        <Hidden xlUp={true}>
                            {userId ? <MerchantMenu id={userId} /> : ''}
                        </Hidden>
                    </Stack>

                    <MerchantAlias />

                    <Grid container spacing={2}>
                        <Grid item xs={12} xl={2}>
                            <MerchantSubmenu current='bank-accounts' id={userId} />
                        </Grid>
                        <Grid item xs={12} xl={10}>
                            {
                                isLoading ? <FormSkeletonWrapper /> :
                                    <BankAccountsForm
                                        isNew={true}
                                        handleSubmit={handleSubmit}
                                        handleCancel={handleCancel}
                                        initialFormState={initialFormState}
                                        FORM_VALIDATION={FORM_VALIDATION}
                                        merchant_id={userId}
                                        hasPermissions={hasPermissions(BANK_ACCOUNTS__CREATE)}
                                    />
                            }
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}
